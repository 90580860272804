import React from 'react';
// import LoadingBar from '../Partials/LoadingBar';
import { parse } from 'querystringify';
import { Redirect } from 'react-router-dom';
import { Landing } from './Landing';
import Hero from '../Partials/Hero';
import DemoHero from '../Partials/demo/Hero';
// import { UserConsumer } from '../../User';

class Home extends React.Component {
  componentDidMount() {
    const { location, appContext } = this.props;
    const { confirmed } = parse(location.search);

    if (confirmed === '1')
      appContext.addError('Your account has been confirmed!'); // @todo refactor appcontext to support multipe types of messages
  }

  render() {
    const { initialQuestion, userContext, appContext } = this.props;
    // @todo: only redirect to the questions page IF there are questions
    // @todo: IF there are no questions, but there is a draft, redirect to that
    if (
      !initialQuestion &&
      userContext.state.isAuthenticated &&
      !userContext.state.initializing
    )
      return <Redirect to="/questions/list" />;

    // console.log('props', this.props);

    if (appContext.subdomain === 'demo') {
      return (
        <div className="landing-page-container">
          <DemoHero />
        </div>
      );
    }

    return (
      <div className="landing-page-container">
        <Hero />
        <Landing />
      </div>
    );
  }
}

export default Home;
