const apiVersion = 'v1';

export const environment = process.env.REACT_APP_ENV || 'production';

export const live = environment === 'production';

let devHost = `${window.location.protocol}//${window.location.hostname}`;
if (process.env.REACT_APP_BACKEND_HOST) {
  devHost = `http://${process.env.REACT_APP_BACKEND_HOST}`;
}

const backendHost = {
  development: devHost,
  staging: 'https://staging-api.answerbar.com',
  production: 'https://api.answerbar.com',
};

export const STRIPE_PK = live
  ? 'pk_live_5O3m5yH4uGxCvL5czG5LTFTN'
  : 'pk_test_B28vlZhrZSNFxKgAhmQWecxR';
// export const STRIPE_PK = 'pk_test_B28vlZhrZSNFxKgAhmQWecxR';
export const API_ROOT = `${backendHost[environment]}/${apiVersion}`;
