import React from 'react';
import Dropzone from 'react-dropzone';
import { Api } from '../Utils/Api';
import { FileSize } from '../Utils/FileSize';

// https://alligator.io/react/react-dropzone/

const ProgressBar = ({ started, progress, complete, error }) => {
  let classNames = '';
  classNames += started ? ' started ' : '';
  classNames += complete ? ' complete ' : '';
  classNames += error ? ' error ' : '';
  return (
    <div className={`progress-bar-wrapper ${classNames}`}>
      <div className="progress-bar" />
      <div className="progress-bar-progress" style={{ width: progress }} />
    </div>
  );
};

const SelectedFile = ({ file, index, handleDelete }) => (
  <div className="selected-file">
    <div className="d-flex">
      <div className="p-2 file-name">{file.name}</div>
      <div className="p-2 file-size">({FileSize(file.size)})</div>
      {file.progress === undefined && (
        <div className="p-2 ml-auto file-actions">
          <div className="file-action" onClick={() => handleDelete(index)}>
            <i className="answerbar-trash" />
          </div>
        </div>
      )}
      {file.error && (
        <div className="p-2 ml-auto">
          <div className="error-message">{file.message}</div>
        </div>
      )}
    </div>
    <ProgressBar
      started={file.started}
      progress={file.progress}
      complete={file.complete}
      error={file.error}
    />
  </div>
);

export default class AttachmentUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: [],
      submitted: false,
    };
  }

  componentDidMount() {
    const { appContext } = this.props;
    this._isMounted = true;
    appContext.clearErrors();
    appContext.clearMessages();
  }

  componentWillUnmount() {
    const { appContext } = this.props;
    this._isMounted = false;
    appContext.clearErrors();
    // don't clear regular messages, this should persist even after the modal closes
    // this.props.appContext.clearMessages();
  }

  // onDrop = (files) => {

  //   console.log('dropped files!', files);
  //   // files.forEach(file => {
  //   //   req.attach(file.name, file);
  //   // });

  //   // req.end();
  // }

  // eslint-disable-next-line no-unused-vars
  onDrop = (acceptedFiles, rejectedFiles) => {
    // Do something with files
    // console.log('acceptedFiles', acceptedFiles);
    // console.log('rejectedFiles', rejectedFiles);
    this.uploadFiles(acceptedFiles);
  };

  fileSelectedHandler = (e) => {
    const { files } = e.target;
    this.uploadFiles(files);
  };

  uploadFiles = (files) => {
    // loop through the selected files, add to state
    const selectedFiles = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      selectedFiles.push(files[i]);
    }

    if (selectedFiles.length > 0) this.setState({ submitted: false });

    // validate filesize (add error before upload)
    // max filesize = 10MB
    selectedFiles
      .filter((file) => file.size > 10 * 1024 * 1024)
      .map((file) => {
        // eslint-disable-next-line no-param-reassign
        file.complete = true;
        // eslint-disable-next-line no-param-reassign
        file.error = true;
        return file;
      });

    this.setState(
      (prevState) => {
        return {
          selectedFiles: prevState.selectedFiles
            .filter((selectedFile) => selectedFile.complete !== true)
            .concat(selectedFiles),
        };
      },
      () => {
        // after done setting state
        this.doUpload();
      }
    );
  };

  handleDelete = (index) => {
    this.setState((prevState) => {
      return {
        selectedFiles: prevState.selectedFiles.filter((file, i) => i !== index),
      };
    });
  };

  uploadFile = (file) => {
    const { question_id, appContext } = this.props;

    if (question_id && file) {
      const formData = new FormData();
      formData.append('attachment', file, file.name);

      Api.QuestionAttachment.upload(
        question_id,
        formData,
        file,
        this.handleUpdateProgress
      ).then((response) => {
        // If the modal is closed (ie. component is unmounted.. cannot set state)
        if (this._isMounted) {
          this.setState((prevState) => {
            return {
              selectedFiles: prevState.selectedFiles.map((selectedFile) => {
                if (selectedFile.name === file.name) {
                  // eslint-disable-next-line no-param-reassign
                  selectedFile.success = response.success;
                  // eslint-disable-next-line no-param-reassign
                  selectedFile.message = response.message;
                  // eslint-disable-next-line no-param-reassign
                  selectedFile.complete = true;
                  // eslint-disable-next-line no-param-reassign
                  selectedFile.error = !response.success;

                  if (!response.success) appContext.addError(response.message);
                  else this.doUploadComplete();
                }

                return selectedFile;
              }),
            };
          });
        }
        return response;
      });
    }
  };

  handleUpdateProgress = (file, progress) => {
    if (!this._isMounted) return;

    // eslint-disable-next-line no-param-reassign
    file.progress = progress || 0;
    this.setState((prevState) => {
      return {
        selectedFiles: prevState.selectedFiles.map((selectedFile) => {
          // eslint-disable-next-line no-param-reassign
          if (selectedFile.name === file.name) selectedFile.progress = progress;
          return selectedFile;
        }),
      };
    });
  };

  handleSubmit = () => {
    this.doUpload();
  };

  doUpload = () => {
    const { selectedFiles } = this.state;
    const { appContext } = this.props;
    this.setState({ submitted: true });
    appContext.clearErrors();

    selectedFiles
      .filter((file) => file.progress === undefined)
      .map((file) => {
        if (file.error) appContext.addError('File size cannot exceed 10MB');
        else this.uploadFile(file);

        return file;
      });
  };

  doUploadComplete = () => {
    const { selectedFiles } = this.state;
    const { appContext, history } = this.props;

    // if all the files uploaded successfully (file.progress is complete)
    const incompleteFiles = selectedFiles.filter(
      (file) => file.complete !== true || file.error === true
    );

    // if there are no incomplete files, go back to the question
    if (incompleteFiles.length === 0) {
      appContext.addMessage('Files have been added');
      history.goBack();
    }
  };

  render() {
    const { selectedFiles, submitted } = this.state;
    const { history } = this.props;

    return (
      <div className="attachment-uploader text-center">
        <h1>Attach Files</h1>
        <div className="subtitle">
          10 files max. 10mb size limit per file. Formats accepted .doc .pdf
          .excel .pages
        </div>

        <div className="divider" />

        <div className="radial-shadow top front" />

        {/* 
        <input ref="fileInput" type="file" onChange={this.fileSelectedHandler} style={{display: 'none'}} multiple />
        */}

        <div className="selected-files">
          {selectedFiles.map((selectedFile, i) => (
            <SelectedFile
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              index={i}
              file={selectedFile}
              handleDelete={this.handleDelete}
            />
          ))}
        </div>

        <div className="divider" />

        {selectedFiles.length === 0 && (
          <div>
            {/* 
            <div className="drag-drop-helptext">
              Drag and drop files here to upload
            </div>
            */}
            <div>
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={`dropzone d-flex flex-column align-items-center justify-content-center ${
                        isDragActive ? 'dropzone--isActive' : ''
                      }`}
                    >
                      <div>
                        <span className="upload-icon">
                          <i className="material-icons">forward</i>
                          <span className="upload-bar" />
                        </span>
                      </div>

                      <input {...getInputProps()} />

                      {isDragActive ? (
                        <div className="cta-text">Drop files here...</div>
                      ) : (
                        <div className="cta-text">
                          Drag and drop files here to upload
                        </div>
                      )}

                      <span className="text-bold text-small underlined">
                        Or select files to upload
                      </span>
                    </div>
                  );
                }}
              </Dropzone>
              {/* 
              <div onClick={() => this.refs.fileInput.click()}>
                <span className="text-bold text-small link underlined">Or select files to upload</span>
              </div>
              */}
            </div>
          </div>
        )}

        {selectedFiles.length > 0 && (
          <div>
            <span
              className="text-bold text-small link underlined"
              onClick={history.goBack}
            >
              Done
            </span>
            {/* 
            <span className="text-bold text-small link underlined" onClick={() => this.refs.fileInput.click()}>Select more files to upload</span>
            */}

            {!submitted && (
              <div>
                <div className="divider radial-shadow top front" />
                <div className="form-actions">
                  <div className="inner">
                    <button
                      onClick={this.handleSubmit}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Upload Files <i className="answerbar-angle-right" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
