import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { UserConsumer } from '../../../User';
import { UserAvatar } from '../../../User/Components/Avatar';
import Portal from '../../Core/Portal';
import { Brand } from '../../Partials';
import matchMedia from '../../Utils/MatchMedia';
import Menu from '../Menu';
import './Header.css';

const isDesktop = matchMedia('lg');

const Links = ({ context, organization }) => {
  const { displayName } = context;
  return (
    <div className="greeting-nav-wrapper d-flex align-items-center justify-content-between flex-grow-1">
      <div className="greeting">Hello {displayName}</div>
      <nav>
        <ul className="nav">
          {/* <li>
            <NavLink activeClassName="active" to="/newsletter">
              HR News &amp; Updates
            </NavLink>
          </li> */}
          {/* <li>
          <NavLink activeClassName="active" to="/faq">
            FAQ
          </NavLink>
        </li> */}
          {(!organization || organization.isAdmin === 1) && (
            <li>
              <NavLink activeClassName="active" to="/organization/manage-users">
                Manage Users
              </NavLink>
            </li>
          )}
          <li>
            <NavLink activeClassName="active" to="/account/edit-profile">
              Edit Profile
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/logout">
              Sign Out
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const MainMenu = ({ context, organization }) => {
  const { state, currentUser } = context;
  const { isAuthenticated } = state;
  return (
    <>
      <div
        id="user-menu"
        className={`d-flex flex-grow-1 ${
          state.isAuthenticated ? 'logged-in' : 'logged-out'
        }`}
      >
        {!state.isAuthenticated && (
          <nav className="d-flex flex-grow-1">
            <ul className="nav nav-primary mr-4 d-flex align-items-center">
              {/*
              <li><NavLink activeClassName="active" to="/faq">FAQ</NavLink></li>
              */}
              {/* <li>
                  <a
                    activeClassName="active"
                    href="http://eepurl.com/habz0f"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa fa-envelope-o" /> Stay in Touch
                  </a>
                </li> */}
              <li>
                <NavLink activeClassName="active" to="/company">
                  Company
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/blog">
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/pricing">
                  Pricing
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/newsletter">
                  Stay Connected
                </NavLink>
              </li>
            </ul>
            <ul className="nav nav-account ml-auto d-flex align-items-center">
              <li>
                <NavLink activeClassName="active" to="/login">
                  Login
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="btn btn-tertiary"
                  activeClassName="active"
                  to="/signup"
                >
                  Sign Up
                </NavLink>
              </li>
            </ul>
          </nav>
        )}
        {isAuthenticated && (
          <div className="d-flex flex-grow-1">
            {/* Desktop Menu */}
            <div
              className={`${
                // isDesktop ? 'd-flex' : 'd-none'
                isDesktop ? 'd-flex' : 'd-block'
              } flex-grow-1 align-items-center`}
            >
              <Link to="/account/edit-profile" className="order-3">
                <UserAvatar user={currentUser} theme="light" />
              </Link>
              <Links context={context} organization={organization} />
            </div>

            {/* Mobile Menu */}
            {/* <div className={`${isDesktop ? 'd-none' : ''} ml-auto`}>
              <div onClick={openMenu} className="">
                <UserAvatar user={currentUser} theme="light" />
              </div>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default (props) => {
  const [menu, setMenu] = useState(false);

  const { handleMenu } = props;

  const openMenu = () => {
    handleMenu(true);
    setMenu(true);
  };

  const closeMenu = () => {
    handleMenu(false);
    setMenu(false);
  };

  const toggleMenu = () => (menu ? closeMenu() : openMenu());

  return (
    <UserConsumer>
      {(context) => (
        <header
          id="header"
          className={`container d-flex align-items-center ${
            context.state.isAuthenticated ? 'logged-in' : 'logged-out'
          }`}
        >
          <div
            className={`menu-toggle ${isDesktop ? 'd-none' : ''}`}
            onClick={toggleMenu}
          >
            <div className="menu-bars">
              <div className="menu-bar" />
              <div className="menu-bar" />
              <div className="menu-bar" />
            </div>
          </div>

          <Brand />

          {isDesktop ? (
            <MainMenu context={context} organization={props.organization} />
          ) : (
            <Portal id="app-menu">
              <Menu openMenu={openMenu} open={menu} onDidClose={closeMenu}>
                <MainMenu context={context} organization={props.organization} />
              </Menu>
            </Portal>
          )}
        </header>
      )}
    </UserConsumer>
  );
};
