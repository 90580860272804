/* eslint-disable react/no-danger */
import { sanitize } from 'dompurify';
import React, { useMemo } from 'react';

const ALLOWED_TAGS = [
  'b',
  'i',
  'u',
  'em',
  'strong',
  'ul',
  'ol',
  'li',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'a',
  'iframe',
  'div',
];

const RichText = ({ html }) => {
  const sanitized = useMemo(() => sanitize(html || '', { ALLOWED_TAGS }), [
    html,
  ]);

  return (
    <div
      className="richtext"
      dangerouslySetInnerHTML={{ __html: sanitized || '' }}
    />
  );
};

export default RichText;
