import React from 'react';
import PressReleases from '../Partials/PressReleases';

const Company = () => {
  return (
    <>
      <div className="company-view">
        <div className="content-section bg-blurred-dark-alt">
          <div className="container company-hero">
            <h2 className="text-center">Our Story</h2>
            <div className="story-text text-center">
              <p>
                In 2016, Allison &amp; Steph co-founded Amui Inc, a company
                dedicated to changing the way organizations interact with HR. In
                2019 they successfully launched AnswerBar, an application that
                provides on-demand access to HR knowledge.
              </p>
              <div className="divider" />
              <h2 style={{ maxWidth: 550, marginBottom: 0 }}>
                It&apos;s like having an HR expert in your back pocket at a
                fraction of the cost.{' '}
              </h2>
            </div>
            {/* <h2 className="text-center">
              Co-Founders <span className="underlined">Stephanie Lyster</span>{' '}
              and <span className="underlined">Allison Guld</span> are changing
              the way organizations interact with HR.
            </h2> */}
          </div>
        </div>
        <div className="content-section bg-dark-tan">
          <div className="container company-intro">
            <h2>An HR Knowledge Market Gap</h2>
            <p>
              Currently there is no affordable and reliable alternative to
              traditional HR; there’s a a gap in the market where small and
              medium sized business are not supported.
            </p>
            <p>
              The reality is companies are exposed to both the legal and
              financial risks of making poor decisions caused by a lack of HR
              knowledge. Not everyone can afford or has a full-time need to hire
              an HR Generalist and it takes time to find answers. While most
              answers can be found online, wading through the information is
              time consuming and the user often doesn&apos;t know how to apply
              the knowledge once they have it or even if they have the right
              answer.
            </p>
            <p>
              AnswerBar fills this market gap. Now, HR knowledge is now
              centralized into one human to human driven platform that
              cross-references provincial employment standards, recent case law
              and industry standards.
            </p>
          </div>
        </div>
        <div className="content-section bg-dark-green">
          <div className="container company-intro">
            <h2>Peer-Reviewed &amp; Best Practices</h2>
            <p>
              The AnswerBar Team is made up of experts who have dedicated their
              careers to developing a deep understanding of the intricacies and
              nuances within the HR field. They use their real world experience
              and intimate knowledge of best practises to answer your unique
              questions. The answer is then peer reviewed within the team then
              confidentially returned to you.
            </p>
          </div>
        </div>
        <div className="content-section bg-tan">
          <div className="container company-bios">
            <h2 className="mb-5">Meet the Founders</h2>
            <div className="row">
              <div className="col mb-5 mb-sm-0">
                <div className="info">
                  <div>
                    <div className="avatar">
                      <img src="/images/steph-lyster.png" alt="Steph Lyster" />
                    </div>
                  </div>
                  <div className="details">
                    <h3>Stephanie Lyster</h3>
                    <div className="social">
                      <a
                        href="https://ca.linkedin.com/in/stephanielyster"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundColor: '#0a66c2' }}
                      >
                        <span className="fa fa-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="bio">
                  Stephanie has experience identifying and implementing
                  strategic HR solutions including workforce planning,
                  performance management, recruitment and change management. Her
                  background in building people infrastructure for high growth
                  companies cemented her belief that HR should not be a cost
                  function but a strategic partner for performance, cultural
                  influence and retention. After establishing herself in
                  corporate HR roles, Stephanie pursued her entrepreneurial
                  interests as a consultant starting 2009 until she co-founded
                  amui.
                </div>
              </div>
              <div className="col mb-5 mb-sm-0">
                <div className="info">
                  <div>
                    <div className="avatar">
                      <img src="/images/allison-guld.png" alt="Allison Guld" />
                    </div>
                  </div>
                  <div className="details">
                    <h3>Allison Guld</h3>
                    <div className="social">
                      <a
                        href="https://ca.linkedin.com/in/allisonguld"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundColor: '#0a66c2' }}
                      >
                        <span className="fa fa-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="bio">
                  Allison&apos;s strategic, operational and management
                  experience in recruitment and HR was honed in global high tech
                  organizations such as Blast Radius, Ernst &amp; Young, and
                  MDA. No stranger to entrepreneurial adventures, Allison&apos;s
                  first efforts funded her university education. She went on to
                  be a top performing high tech recruiter which segued into her
                  own recruitment agency. She spent the 10 years prior to
                  co-founding Amui working for high growth companies in a
                  variety of HR leadership roles.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section bg-green">
          <div className="container">
            <PressReleases />
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
