import React, { useEffect, useState } from 'react';
import { Api } from '../Utils/Api';
import { formatDay } from '../Utils/Datetime';

const { PressRelease } = Api;

const ListItem = ({ item }) => {
  const { thumb, publish_date, title, url, summary } = item;

  return (
    <div className="list-item">
      <div className="d-flex">
        <div>
          <div className="thumb">
            <a href={url} target="_blank" rel="noreferrer">
              <img src={thumb} alt={title} />
            </a>
          </div>
        </div>
        <div>
          <div className="publish-date">{formatDay(publish_date)}</div>
          <div className="title">
            <h3>
              <a href={url} target="_blank" rel="noreferrer">
                {title}
              </a>
            </h3>
          </div>
          <div className="summary">
            <p>{summary}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const List = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    PressRelease.list().then((response) => {
      setItems(response);
    });
  }, []);

  return (
    <div className="list">
      {items?.map((item) => (
        <ListItem key={item.id} item={item} />
      ))}
    </div>
  );
};

const PressReleases = () => {
  return (
    <div className="press-releases">
      <div className="text-center">
        <h2>In the Press</h2>
      </div>
      <List />
    </div>
  );
};

export default PressReleases;
