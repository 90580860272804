import React from 'react';
import Textarea from 'react-textarea-autosize';

const AskInitialQuestion = ({
  initialQuestion,
  setInitialQuestion,
  initialQuestionIsValid,
  history,
  userContext,
}) => {
  const handleTextChange = (e) => {
    e.preventDefault();
    setInitialQuestion(e.target.value);

    // update the size
    // console.log(e.target.style.height)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // If the user is already logged in, skip the signup process
    if (userContext.state.isAuthenticated) {
      history.push('/question/confirm');
      return;
    }

    history.push('/signup');
  };

  return (
    <div className="home-view d-flex align-items-center">
      <div
        className={`simple-question-form flex-fill ${
          initialQuestionIsValid && 'valid'
        }`}
      >
        <div className="inner">
          <form action="/signup" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="question">Answers to your HR questions:</label>

              <div className="textarea-container">
                <Textarea
                  id="question"
                  value={initialQuestion}
                  onChange={handleTextChange}
                  className="form-control"
                  placeholder="Ask a question"
                />
              </div>
            </div>

            <div className="form-actions d-flex align-items-end flex-column">
              <button type="submit" className="btn btn-primary">
                Ask <i className="answerbar-angle-right" />
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* 
          <LoadingBar />
           */}
    </div>
  );
};

export default AskInitialQuestion;
