/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import imageVideoPreview from '../../images/video-preview.jpg';

export default () => {
  const videoEl = useRef(null);
  const videoWrapperEl = useRef(null);

  function playVideo() {
    videoWrapperEl.current.classList.add('is-playing');
    videoEl.current.play();
  }

  function stopVideo() {
    videoWrapperEl.current.classList.remove('is-playing');
    videoEl.current.pause();
    videoEl.current.currentTime = 0;
  }

  useEffect(() => {
    videoEl.current.onended = stopVideo;
    return () => {
      videoEl.current.onended = null;
    };
  }, []);

  return (
    <section className="landing-section section-how-it-works">
      <div className="inner">
        <div className="container">
          <div className="row">
            <div className="col col-sm col-left">
              <div className="photo-col">
                <div className="photo-wrapper">
                  <div
                    className="play-button"
                    onClick={(e) => e.preventDefault() || playVideo()}
                  >
                    <i className="material-icons">play_arrow</i>
                  </div>
                  <img src={imageVideoPreview} alt="" className="img-fluid" />
                </div>
                <div className="radial-shadow top front" />
              </div>
            </div>
            <div className="col col-sm col-right">
              <div className="copy">
                <h2>See how it works</h2>
                <p>
                  AnswerBar allows users to offload time consuming research and
                  access HR templates compliant with localized regulations.
                  Users can:
                </p>
                {/* 
                <p className="mb-2"><em>Users can:</em></p>
                */}
                <ul className="tag-list d-flex flex-wrap">
                  <li>
                    <i className="answerbar-checkmark" /> ask unlimited
                    questions anytime
                  </li>
                  <li>
                    <i className="answerbar-checkmark" /> receive customized and
                    confidential answers{' '}
                  </li>
                  <li>
                    <i className="answerbar-checkmark" /> sign in to a personal
                    account{' '}
                  </li>
                  <li>
                    <i className="answerbar-checkmark" /> ask follow up
                    questions
                  </li>
                  <li>
                    <i className="answerbar-checkmark" /> share questions and
                    answers with teams
                  </li>
                  <li>
                    <i className="answerbar-checkmark" /> monthly/yearly
                    subscription
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={videoWrapperEl}
        className="promo-video-wrapper"
        onClick={(e) => e.preventDefault() || stopVideo()}
      >
        <PromoVideo
          videoEl={videoEl}
          source="https://d2a16afatekdpf.cloudfront.net/walkthrough_4.mp4"
        />
      </div>
    </section>
  );
};

const PromoVideo = ({ source, videoEl }) => (
  <video
    controls
    ref={videoEl}
    id="promo-video"
    className="promo-video"
    disablePictureInPicture
    controlsList="nodownload"
  >
    <source src={source} type="video/mp4" />
  </video>
);
