import React from 'react';
import { Link } from 'react-router-dom';
import { CardElement, injectStripe } from 'react-stripe-elements';
import Api from '../Api';
import { LoadingBar } from './Partials';

const styles = {
  base: {
    iconColor: '#fff',
    color: '#fff',
    fontWeight: 500,
    fontFamily: "'Playfair Display', serif",
    fontSize: '20px',
    textTransform: 'none',
    fontSmoothing: 'antialiased',

    ':-webkit-autofill': {
      color: '#6c757d',
    },
    '::placeholder': {
      color: '#6c757d',
    },
  },
  invalid: {
    iconColor: '#ff0000',
    color: '#ff0000',
  },
};

const BillingForm = ({
  plan,
  company,
  name,
  email,
  handleChangeField,
  handleValidateField,
  hasError,
  errorMessage,
  terms,
  handleChangeTerms,
}) => (
  <>
    <div className="form-group">
      <label htmlFor="plan">Selected Plan</label>
      <input
        id="plan"
        type="text"
        className="form-control"
        value={`${plan.title} $${plan.price}/${
          plan.frequncy === 'y' ? 'year' : 'month'
        }`}
        disabled
      />
    </div>
    <div className="form-group">
      <label htmlFor="name" className="control-label">
        Billing Name
      </label>
      <input
        type="text"
        name="name"
        className={`form-control ${hasError('name') ? 'is-invalid' : ''}`}
        value={name}
        onChange={handleChangeField}
        onBlur={handleValidateField}
        placeholder="Your Name"
      />
      <div className="invalid-feedback">{errorMessage('name')}</div>
    </div>
    <div className="form-group">
      <label htmlFor="name" className="control-label">
        Company
      </label>
      <input
        type="text"
        name="company"
        className={`form-control ${hasError('company') ? 'is-invalid' : ''}`}
        value={company}
        onChange={handleChangeField}
        onBlur={handleValidateField}
        placeholder="Your Company Name"
      />
      <div className="invalid-feedback">{errorMessage('company')}</div>
    </div>
    <div className="form-group">
      <label htmlFor="email" className="control-label">
        Email
      </label>
      <input
        type="text"
        name="email"
        className={`form-control ${hasError('email') ? 'is-invalid' : ''}`}
        value={email}
        onChange={handleChangeField}
        onBlur={handleValidateField}
        placeholder="Your Email"
      />
      <div className="invalid-feedback">{errorMessage('email')}</div>
    </div>

    <div className="form-group">
      <label htmlFor="stripe" className="control-label">
        Card
      </label>
      <CardElement style={styles} />
    </div>

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          value="yes"
          defaultChecked={terms}
          onClick={handleChangeTerms}
          className={`custom-control-input ${
            hasError('terms') ? 'is-invalid' : ''
          }`}
          id="terms"
        />
        <label className="custom-control-label" htmlFor="terms">
          Agree to{' '}
          <Link
            to={`/subscribe/${plan.id}/terms`}
            className="text-bold underlined"
            onClick={(e) => e.stopPropagation()}
          >
            Terms of Service
          </Link>
        </label>
        <div className="invalid-feedback">{errorMessage('terms')}</div>
      </div>
    </div>
  </>
);

const BillingInfo = ({ subscription, handleUpdateCard }) => (
  <div className="d-flex">
    {subscription && (
      <div>
        {subscription.billing_name} <br />
        {subscription.billing_email} <br />
        <br />
        {subscription.card_brand} <br />
        **** **** **** {subscription.last4} ({subscription.exp_month}/
        {subscription.exp_year})
      </div>
    )}
    <p className="ml-auto small">
      <span className="bold underlined" onClick={() => handleUpdateCard(true)}>
        {' '}
        update
      </span>
    </p>
  </div>
);

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      company: props.organization ? props.organization.name || '' : '',
      name: props.userContext.getName() || '',
      email: props.userContext.getEmail() || '',
      terms: 0,
      errors: {
        company: '',
        name: '',
        email: '',
        stripe: '',
        terms: '',
      },
      processing: false,
      stripeToken: '',
    };
    this.submit = this.submit.bind(this);
  }

  handleChangeTerms = (e) => {
    this.setState({ terms: e.target.checked }, () => {
      this.validateTerms();
    });
  };

  setFormError = (name, message) => {
    this.setState((prevState) => {
      const errors = { ...prevState.errors }; // same as Object.assign(), makes a copy
      errors[name] = message || '';
      return { errors };
    });
  };

  clearFormError = (name) => {
    this.setFormError(name, '');
  };

  validateTerms = () => {
    const { terms } = this.state;

    if (!terms) {
      this.setFormError('terms', 'Terms must be accepted before proceeding');
      return false;
    }

    this.clearFormError('terms');
    return true;
  };

  validate = () => {
    const { subscription } = this.props;
    const valid = subscription || this.validateTerms();
    return valid;
  };

  handleChangeField = (e) => this.setState({ [e.target.name]: e.target.value });

  async submit(e) {
    const { company, name, email, terms, processing, errors } = this.state;
    const { plan, subscription, stripe } = this.props;

    e.preventDefault();

    if (!this.validate()) return;

    // prevent multiple submissions
    if (processing) return;

    this.setState({ processing: true });

    if (subscription && subscription.hasCard === true) {
      this.processSubscription(plan.id, company, name, email, '');
    } else {
      stripe
        .createToken({ name, email })
        .then(({ token, error }) => {
          if (token) {
            // console.log('Received Stripe token:', token);
            this.setState({ stripeToken: token });
            this.processSubscription(
              plan.id,
              company,
              name,
              email,
              token.id,
              terms
            );
          }

          if (error) {
            this.setState({
              errors: { ...errors, stripe: error.message },
              processing: false,
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('stripe error', error);
          this.setState({ processing: false });
        });
    }
  }

  processSubscription(plan_id, company, name, email, token_id, terms) {
    const { appContext, history, plan } = this.props;

    Api.subscribe(plan_id, company, name, email, token_id, terms).then(
      (response) => {
        this.setState({ processing: false });

        // redirect if successful
        if (response && response.success) {
          appContext.addMessage(
            `You have successfully subscribed to plan: ${plan.title}`
          );
          history.push('/'); // just redirect once to remove modal history
          // history.push('/myplan');
          history.push('/questions/drafts');

          // otherwise display alert/errors
        } else {
          appContext.addError(response.message);
        }
      }
    );
  }

  render() {
    const { company, name, email, terms, processing } = this.state;
    const { plan, subscription, handleUpdateCard } = this.props;

    const hasError = (inputName) => {
      const { errors } = this.state;
      return errors[inputName];
    };

    const errorMessage = (inputName) => {
      const { errors } = this.state;
      return errors[inputName];
    };

    return (
      <div className="subscribe-form">
        {!subscription || subscription.hasCard !== true ? (
          <BillingForm
            {...this.props}
            subscription={subscription}
            plan={plan}
            company={company}
            name={name}
            email={email}
            handleChangeField={this.handleChangeField}
            handleValidateField={this.handleValidateField}
            hasError={hasError}
            errorMessage={errorMessage}
            terms={terms}
            handleChangeTerms={this.handleChangeTerms}
          />
        ) : (
          <BillingInfo
            {...this.props}
            subscription={subscription}
            plan={plan}
            handleUpdateCard={handleUpdateCard}
          />
        )}

        <div className="divider" />

        {processing && <LoadingBar type="status-bar" />}

        <div className="form-info">
          Your card will be charged{' '}
          <span className="text-bold">
            ${plan.price}/{plan.frequency === 'y' ? 'year' : 'month'}
          </span>{' '}
          (plus applicable tax).
        </div>

        {(!subscription ||
          subscription.hasCard !== true ||
          subscription.plan_id !== plan.id) && (
          <div className="form-actions">
            <div className="radial-shadow top front" />
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.submit}
              >
                {subscription && subscription.hasPlan === true
                  ? 'Save Changes'
                  : 'Pay Now'}
                <i className="answerbar-angle-right" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectStripe(SubscribeForm);
