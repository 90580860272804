import { Api, setAuthorizationToken } from '../Api';

export default {
  authenticate(email, password, inviteCode) {
    const config = {
      headers: { Authorization: null },
    };
    return Api.post(
      '/auth/login',
      {
        email,
        password,
        invite_code: inviteCode,
      },
      config
    )
      .then((response) => response.data)
      .then((response) => {
        const { accessToken } = response.data;
        if (accessToken) setAuthorizationToken(accessToken);
        return response;
      });
    // .catch( error => error )
  },

  register(email, password, referralCode, terms, inviteCode, recaptchaValue) {
    return Api.post('/auth/register', {
      email,
      password,
      referral_code: referralCode,
      terms,
      invite_code: inviteCode,
      recaptchaValue,
    })
      .then((response) => response.data)
      .then((response) => {
        const { accessToken } = response.data;
        if (accessToken) setAuthorizationToken(accessToken);
        return response;
      });
    // .catch( error => error )
  },

  refreshToken(refreshToken) {
    if (!refreshToken) return new Promise((resolve) => resolve());

    // override the authorization header since the token
    // is the refresh token, instead of the access token.
    const config = { headers: { Authorization: `Bearer ${refreshToken}` } };
    return Api.post('/auth/refresh', {}, config)
      .then(({ data }) => {
        setAuthorizationToken(data.accessToken);
        return data;
      })
      .catch(() => {
        setAuthorizationToken(null);
        return null;
      });
  },

  logout() {
    setAuthorizationToken(null);
    return Api.get('/auth/logout');
    // .then( response => response.data )
    // .catch( error => {} )
  },

  me() {
    return Api.get('/users/me');
    // .then( response => response.data )
    // .catch( error => error )
  },

  update(data) {
    return Api.put('/users/update', data).then((response) => response.data);
  },

  setPhoto(data) {
    return Api.post('/users/photo', data, {
      // eslint-disable-next-line no-unused-vars
      onUploadProgress: (progressEvent) => {
        // console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%');
      },
    }).then((response) => response.data);
  },

  forgotPassword(email) {
    return Api.post('/auth/forgot', { email }).then(
      (response) => response.data
    );
    // .catch( error => console.log(error) )
  },

  resetPassword(email, password, password2, token) {
    return Api.post('/auth/reset', {
      email,
      password,
      password2,
      token,
    })
      .then((response) => response.data)
      .then((response) => {
        const { accessToken } = response.data;
        if (accessToken) setAuthorizationToken(accessToken);
        return response;
      });
    // .catch( error => console.log(error) )
  },

  registerPushToken(token) {
    return Api.post('/users/registerPushToken', { token })
      .then((response) => response.data)
      .catch(() => {});
  },
};
