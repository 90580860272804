import React, { useEffect } from 'react';
import { Plugins } from '@capacitor/core';
import { withRouter } from 'react-router-dom';

const { App } = Plugins;

const AppUrlOpener = React.memo(({ push }) => {
  useEffect(() => {
    App.addListener('appUrlOpen', (data) => {
      const slug = data.url.split('.com').pop();
      if (slug) push(`${slug}`);
    });
  }, [push]);

  return null;
});

export default withRouter(({ history }) => (
  <AppUrlOpener push={history.push} />
));
