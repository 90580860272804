import axios from 'axios';
import { API_ROOT } from './config';

// set the default base url
// const authAxios = axios.create();
// authAxios.defaults.baseURL = API_ROOT;
axios.defaults.baseURL = API_ROOT;

export const setAuthorizationToken = (token) => {
  if (token) axios.defaults.headers.Authorization = `Bearer ${token}`;
  else delete axios.defaults.headers.Authorization;
};

// Generic API callbacks
export const Api = {
  get: (url, data = {}) => axios.get(url, { params: data }),
  put: (url, data = {}, config = {}) => axios.put(url, data, config),
  patch: (url, data = {}, config = {}) => axios.patch(url, data, config),
  post: (url, data = {}, config = {}) => axios.post(url, data, config),
  delete: (url) => axios.delete(url),
};

export default {
  plans(data) {
    return Api.get(`/plan/list`, { params: data })
      .then((response) => response.data.data)
      .catch((error) => error);
  },
  plan(id) {
    return Api.get(`/plan/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  },
  subscription(data) {
    return Api.get(`/subscription/me`, {
      params: data,
    })
      .then((response) => response.data)
      .catch((error) => error);
  },
  subscribe(planId, company, name, email, stripeToken, terms) {
    const data = {
      company,
      name,
      email,
      stripeToken,
      terms,
    };
    return Api.post(`/plan/${planId}/subscribe`, data)
      .then((response) => response.data)
      .catch((error) => error);
  },
  cancelSubscription() {
    const data = {
      delete: true,
    };
    return Api.post(`/subscription/cancel`, data)
      .then((response) => response.data)
      .catch((error) => error);
  },
  getProvinces() {
    return Api.get(`/location/provinces`).then((response) => response.data);
  },
};
