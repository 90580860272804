import React from 'react';

const NewsletterForm = () => {
  return (
    <>
      {/* <style type="text/css">
        #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
        Add your own Mailchimp form style overrides in your site stylesheet or
        in this style block. We recommend moving this block and the preceding
        CSS link to the HEAD of your HTML file.
      </style> */}
      {/* <link
        href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
        rel="stylesheet"
        type="text/css"
      /> */}
      <div id="mc_embed_signup">
        <form
          action="https://answerbar.us5.list-manage.com/subscribe/post?u=5dc9e7ca5bb16bd0321463ede&amp;id=516660b547"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group form-group">
              {/* <label for="mce-EMAIL">Email Address</label> */}
              <input
                type="email"
                name="EMAIL"
                className="required email form-control"
                id="mce-EMAIL"
                placeholder="Your email address"
              />
            </div>
            {/*
            <div className="mc-field-group">
              <label for="mce-FNAME">First Name </label>
              <input
                type="text"
                value=""
                name="FNAME"
                className=""
                id="mce-FNAME"
              />
            </div>
            <div className="mc-field-group">
              <label for="mce-LNAME">Last Name </label>
              <input
                type="text"
                value=""
                name="LNAME"
                className=""
                id="mce-LNAME"
              />
            </div>
            */}
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              />
            </div>
            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
            <div
              style={{ position: 'absolute', left: -5000 }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_5dc9e7ca5bb16bd0321463ede_516660b547"
                tabIndex="-1"
                value=""
              />
            </div>
            <div className="clear">
              <div className="form-actions text-right">
                <button
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button btn btn-primary"
                >
                  Subscribe
                  <i className="answerbar-angle-right" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewsletterForm;
