import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserConsumer } from './Context';

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <UserConsumer>
    {(context) => (
      <Route
        {...rest}
        render={(props) => (
          <>
            {context.state.isAuthenticated === true ? (
              <Component {...props} {...rest} userContext={context} />
            ) : (
              <Redirect
                to={{
                  pathname: rest.redirect ? rest.redirect : '/login',
                  state: { from: props.location },
                }}
              />
            )}
          </>
        )}
      />
    )}
  </UserConsumer>
);
