import React, { useState } from 'react';
import { Link, Route } from 'react-router-dom';
import Api from '../Api';
import { LoadingBar } from './Partials';
import { currency } from './Utils/Number';

export const CancelSubscriptionLink = (props) => {
  const { appContext, history, subscription } = props;
  const [showConfirm, setShowConfirm] = useState(false);

  const onHandleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();

    Api.cancelSubscription().then((response) => {
      if (!response.hasPlan) {
        appContext.addMessage(
          'Your subscription has been cancelled.',
          'success'
        );
      }

      history.push('/account/edit-profile');
      return response;
    });
  };

  return (
    <div>
      {subscription.hasPlan && (
        <span
          className="link underlined"
          onClick={() => setShowConfirm(!showConfirm)}
        >
          Cancel Subscription
        </span>
      )}

      {showConfirm && (
        <p className="small">
          Are you sure you&apos;d like to cancel?{' '}
          <span className="link underlined" onClick={onHandleCancel}>
            Yes, Cancel
          </span>
        </p>
      )}
    </div>
  );
};

export const Upgrade = () => <h2>Upgrade to continue...</h2>;

export const Subscription = ({ subscription }) => (
  <div className="subscription">
    {subscription.id && (
      <div>
        Current Plan: {subscription.title} - ${subscription.price}
      </div>
    )}
    {!subscription.id && <div>Current Plan: Free</div>}
  </div>
);

export const Plan = ({ plan, subscription }) => (
  <Link
    className={`plan ${
      subscription && subscription.plan_id === plan.id
        ? 'current-plan'
        : 'not-current-plan'
    }`}
    to={`/subscribe/${plan.id}`}
  >
    <div className="inner">
      <div className="header d-flex justify-content-between">
        <div className="title">{plan.title}</div>
        <div className="price">
          {currency(plan.price)}
          <span className="small">/{plan.frequency === 'y' ? 'yr' : 'mo'}</span>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <ul>
          <li>
            {plan.max_users === '1' && <span>1 User</span>}
            {plan.max_users !== '1' && (
              <span>Up to {plan.max_users} Users</span>
            )}
          </li>
        </ul>
        <div className="actions">
          {subscription && plan.id === subscription.plan_id && (
            <div>Update billing info</div>
          )}
        </div>
      </div>

      {/* 
      <div className="actions">
        {(subscription && plan.id === subscription.plan_id) && <div>Update billing info</div>}
        {(!subscription || plan.id !== subscription.plan_id) && <div>Select this plan</div>}
      </div>
      */}
    </div>
  </Link>
);

export default class Plans extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      plans: [],
      selected: false,
      isCurrentSubscription: false,
      subscription: null,
    };
  }

  componentDidMount() {
    this.refreshPlans();
    this.refreshSubscription();
  }

  refreshPlans() {
    Api.plans().then(
      (response) =>
        this.setState({ plans: response, isLoading: false }) || response
    );
  }

  refreshSubscription() {
    Api.subscription().then(
      (response) => this.setState({ subscription: response }) || response
    );
  }

  // subscribeToPlan(plan_id) {
  //   console.log('plan props', this.props);

  //   // Api.subscribe(plan_id)
  //   //   // .then(response => console.log('subscribe response', response) || response)
  //   //   .then(response => {
  //   //     this.setState({
  //   //       subscription: response,
  //   //       isLoading: false
  //   //     });

  //   //     if (response.success)
  //   //       this.props.history.push('/myplan');
  //   //     else {
  //   //       // display errors;
  //   //     }

  //   //     return response;
  //   //   })
  // }

  render() {
    const { isLoading, plans, subscription } = this.state;
    const curPlan =
      subscription && subscription.id && plans
        ? plans.find((plan) => plan.id === subscription.plan_id)
        : null;

    return (
      <div className="plans-view">
        <Route path="/plans/upgrade" component={Upgrade} />

        {isLoading && <LoadingBar type="status-bar" />}

        {!isLoading && (
          <div className="animated fadeIn">
            {(!subscription || !subscription.id) && (
              <>
                <h1 className="mb-2">Choose the right plan for you</h1>
                {/* 
                <p className="subtitle">Bigger plans for any size organization.</p>
                */}
                <p className="description mb-4">
                  All plans include <em className="underlined">unlimited</em>{' '}
                  questions
                </p>
              </>
            )}
            {subscription && subscription.id && (
              <>
                <h1>Your current plan</h1>
                <div className="plans d-flex flex-wrap">
                  {curPlan ? (
                    <Plan plan={curPlan} subscription={subscription} />
                  ) : (
                    '-'
                  )}
                </div>
                <div className="divider" />
                <h2>Select a different plan</h2>
              </>
            )}

            <div className="plans d-flex flex-wrap">
              {plans
                .filter(
                  (plan) => !subscription || plan.id !== subscription.plan_id
                )
                .map((plan) => (
                  <Plan key={plan.id} plan={plan} subscription={subscription} />
                ))}
            </div>

            {/* 
            {subscription && <Subscription subscription={subscription} />}
            */}

            {subscription && (
              <CancelSubscriptionLink
                {...this.props}
                subscription={subscription}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
