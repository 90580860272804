import React, { useState, useEffect } from 'react';
import { Api } from '../Utils/Api';
import LoadingBar from '../Partials/LoadingBar';
import HowItWorks from './HowItWorks';

const Page = () => {
  return (
    <div className="page-view faqs-view">
      <div className="inner">
        <div className="page-header">
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className="landing-view">
          <HowItWorks />
        </div>
        <Faqs />
      </div>
    </div>
  );
};

const Faqs = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  let isMounted = true;

  useEffect(() => {
    isMounted = true;

    Api.Faq.list().then((response) => {
      if (isMounted) {
        setItems(response);
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <div className="faqs">
      {loading && <LoadingBar type="status-bar" />}
      {items.map((item) => (
        <FaqCategory key={item.id} item={item} />
      ))}
    </div>
  );
};

// Separated by Categories
// const FaqCategory = ({item}) =>
//   <div className="faq-category">
//     <div className="inner container">
//       <div className="faq-category-title">
//         <span>{item.category}</span>
//       </div>
//       <div className="faqs">
//         {item.faqs && item.faqs.map(faq => <Faq key={faq.id} faq={faq} />)}
//       </div>
//     </div>
//   </div>

// Listed Individually
const FaqCategory = ({ item }) => (
  <>
    {item.faqs &&
      item.faqs.map((faq) => (
        <div key={faq.id} className="faq-category">
          <div className="inner container">
            <div className="faq-category-title">{item.category}</div>
            <Faq faq={faq} />
          </div>
        </div>
      ))}
  </>
);

const Faq = ({ faq }) => (
  <div className="faq">
    <div className="faq-question">{faq.question}</div>
    <div className="faq-answer">{faq.answer}</div>
  </div>
);

export default Page;
