import React from 'react';
import defaultPhoto from '../images/defaultPhoto.png';
import defaultPhotoLight from '../images/defaultPhotoLight.png';
import AnswerBarPhoto from '../images/AnswerBar.jpg';
import BenchHRPhoto from '../images/BenchHR.png';
import { subdomain } from '../../Whitelabel';

export const Avatar = ({ image_url }) => (
  <div className="avatar">
    <div className="photo">
      <img
        src={image_url || defaultPhoto}
        alt=""
        className="img-fluid"
        style={{ borderRadius: 50 }}
      />
    </div>
  </div>
);

export const UserAvatar = ({ user, theme }) => (
  <Avatar
    image_url={
      (user && user.profile && user.profile.thumbs.small) ||
      (theme && theme === 'light' ? defaultPhotoLight : defaultPhoto)
    }
  />
);

export const EmployeeAvatar = () => (
  <Avatar image_url={subdomain === 'demo' ? BenchHRPhoto : AnswerBarPhoto} />
);

// export const UploadAvatar = props =>
//   <div className="upload-avatar">
//     <Avatar />
//   </div>
