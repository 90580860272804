import moment from 'moment';

export const formatDate = (dateString) => {
  const day = moment(dateString);
  return day.format('MMM D YYYY, h:mm a');
};

export const formatDay = (dateString) => {
  const day = moment(dateString);
  return day.format('MMM D YYYY');
};
