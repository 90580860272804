import React, { Component } from 'react';
import validator from 'validator';
import Api from '../Api';
import { LoadingBar } from '../../Components/Partials';

const ForgotPasswordForm = ({
  handleSubmit,
  errorMessage,
  errors,
  email,
  handleChangeEmail,
}) => (
  <form className="password-forgot-form" onSubmit={handleSubmit}>
    {/* 
    {props.successMessage && <div className="alert alert-success">{ props.successMessage }</div>}
    */}
    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

    <div className="form-group">
      <label htmlFor="email">Email address</label>
      <input
        id="email"
        value={email}
        onChange={(e) => handleChangeEmail(e.target.value)}
        type="email"
        placeholder="your@email.com"
        autoComplete="off"
        className={`form-control ${errors.email.length > 0 && 'is-invalid'}`}
      />
      <div className="invalid-feedback">{errors.email[0]}</div>
    </div>

    <div className="form-actions">
      <div className="d-flex bd-highlight mb-3">
        <div className="ml-auto">
          <button type="submit" className="btn btn-primary">
            Reset Password <i className="answerbar-angle-right" />
          </button>
        </div>
      </div>
    </div>
  </form>
);

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // email address is stored in user context
      successMessage: '',
      errorMessage: '',
      errors: { email: [] },
      loading: false,
    };
  }

  validate = () => {
    const { userContext } = this.props;
    const { authEmail } = userContext.state;
    const errors = { email: [] };

    if (validator.isEmpty(authEmail))
      errors.email.push('Email address is required');
    if (!validator.isEmail(authEmail))
      errors.email.push('Please enter a valid email address');
    this.setState({ errors });
    return !(errors.email.length > 0);
  };

  handleSubmit = (e) => {
    const { loading } = this.state;
    const { appContext, userContext, history } = this.props;

    e.preventDefault();
    if (!this.validate()) return false;

    if (loading) return false;

    this.setState({ loading: true });

    // Since this is an unauthenticated API call sending an email
    // to valid email addresses, calls should be limited (eg max per IP)
    Api.forgotPassword(userContext.state.authEmail).then(
      ({ success, error }) => {
        if (success) appContext.addMessage(success);

        this.setState({
          successMessage: success,
          errorMessage: error,
          loading: false,
        });

        history.push('/login');
      }
    );

    return true;
  };

  render() {
    const { loading, successMessage, errorMessage, errors } = this.state;
    const { userContext } = this.props;

    return (
      <div>
        <h2>Forgot Password</h2>

        {loading && <LoadingBar type="status-bar" />}

        <ForgotPasswordForm
          {...this.props}
          handleSubmit={this.handleSubmit}
          handleChangeEmail={userContext.handleChangeAuthEmail}
          email={userContext.state.authEmail}
          successMessage={successMessage}
          errorMessage={errorMessage}
          errors={errors}
        />
      </div>
    );
  }
}
