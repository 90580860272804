import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg';
import useApp from '../../hooks/useApp';

// import demologo from '../../images/demo/logo.svg';
import benchlogo from '../../images/demo/bench.svg';

export default () => {
  const { subdomain } = useApp();

  return (
    <div className="brand">
      <div className="logo">
        <Link to="/">
          {subdomain === 'demo' ? (
            <img src={benchlogo} className="app-logo" alt="DemoBar" />
          ) : (
            <img src={logo} className="app-logo" alt="AnswerBar" />
          )}
        </Link>
      </div>
    </div>
  );
};
