import React, { Component } from 'react';
import { Api } from './Utils/Api';

const AnswerBarContext = React.createContext();
const { Consumer, Provider } = AnswerBarContext;

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const AnswerBarConsumer = ({ children }) => (
  <Consumer>{children}</Consumer>
);

export class AnswerBarProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initializing: true,
      loading: true,
      unread: [],
      questions: [],
      drafts: [],
      trash: [],
      counts: {
        unread: 0,
        questions: 0,
        drafts: 0,
        trash: 0,
      },
      searchQuery: '',
      organization: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    // the api call is duplicated for the current route
    // @todo remove duplicate call.
    // @todo fix syncing - currently some functionality like delete
    // attachment stop working after refreshing..
    // ...spread doesn't seem to fix the issue. lookup syncing/updating
    // objects in react

    this.refreshAll();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshAll = () => {
    const { userContext } = this.props;
    const { isAuthenticated } = userContext.state;
    if (isAuthenticated) {
      this.refreshUnread();
      this.refreshQuestions();
      this.refreshDrafts();
      this.refreshTrash();
      this.refreshOrganization();
    }
  };

  refreshUnreadCount = () => {
    const { unread } = this.state;
    const count = unread.filter((question) => question.isUnread !== false)
      .length;

    if (this._isMounted)
      this.setState((prevState) => ({
        counts: { ...prevState.counts, unread: count },
      }));
  };

  refreshUnread = () => {
    const { searchQuery } = this.props;

    if (this._isMounted) this.setState({ loading: true });

    return Api.Questions.list({ is_trashed: 0, is_unread: 1 }).then(
      (questions) => {
        if (questions && this._isMounted) {
          this.setState((prevState) => ({
            unread: questions,
            loading: false,
            counts: { ...prevState.counts, unread: questions.length },
          }));

          if (searchQuery) this.filterWithSearchQuery(searchQuery);
        }
        return questions;
      }
    );
  };

  refreshQuestions = () => {
    const { searchQuery } = this.props;

    if (this._isMounted) this.setState({ loading: true });

    return Api.Questions.list({ is_trashed: 0 }).then((questions) => {
      if (questions && this._isMounted) {
        this.setState((prevState) => ({
          initializing: false,
          questions,
          loading: false,
          counts: { ...prevState.counts, questions: questions.length },
        }));

        if (searchQuery) this.filterWithSearchQuery(searchQuery);
      }
      return questions;
    });
  };

  refreshDrafts = () => {
    if (this._isMounted) this.setState({ loading: true });

    return Api.Questions.drafts().then((questions) => {
      // console.log('api drafts', questions);
      if (questions && this._isMounted) {
        this.setState((prevState) => ({
          drafts: questions,
          loading: false,
          counts: { ...prevState.counts, drafts: questions.length },
        }));
      }
      return questions;
    });
  };

  refreshTrash = () => {
    const { searchQuery } = this.props;

    if (this._isMounted) this.setState({ loading: true });

    return Api.Questions.list({ is_trashed: 1 }).then((questions) => {
      // console.log('api trash', questions);
      if (questions && this._isMounted) {
        this.setState((prevState) => ({
          trash: questions,
          loading: false,
          counts: { ...prevState.counts, trash: questions.length },
        }));

        if (searchQuery) this.filterWithSearchQuery(searchQuery);
      }
      return questions;
    });
  };

  createDraft = () => {
    const { history, location } = this.props;
    Api.Questions.create().then((response) => {
      if (location.pathname === '/questions/drafts') this.refreshDrafts();
      else history.push('/questions/drafts');
      return response;
    });
  };

  emptyTrash = () => {
    const { history } = this.props;
    Api.Questions.emptyTrash().then(() => {
      this.refreshTrash();
      history.push('/questions/list');
    });
  };

  handleUpdateSearchQuery = (e) => {
    e.preventDefault();
    this.updateSearchQuery(e.target.value);
  };

  updateSearchQuery = (value) => {
    this.setState({ searchQuery: value });
  };

  refreshOrganization = () => {
    return Api.Organization.retrieve().then((organization) => {
      // console.log('api organization', organization);
      if (this._isMounted) {
        this.setState({
          organization,
        });
      }
      return organization;
    });
  };

  setQuestion = (updatedQuestion) => {
    this.setState((prevState) => {
      return {
        // Iterate over all questions, filter out the matching question
        questions: prevState.questions.map((question) => {
          // eslint-disable-next-line no-param-reassign
          if (question.id === updatedQuestion.id) question = updatedQuestion;
          return question;
        }),
      };
    });
  };

  setUnreadQuestion = (updatedQuestion) => {
    this.setState((prevState) => {
      const unread = prevState.unread.map((question) => {
        // eslint-disable-next-line no-param-reassign
        if (question.id === updatedQuestion.id) question = updatedQuestion;
        return question;
      });

      return {
        // Iterate over all questions, filter out the matching question
        unread,
        counts: {
          ...prevState.counts,
          unread: unread.filter((question) => question.isUnread !== false)
            .length,
        },
      };
    });
  };

  filterWithSearchQuery = (unfilteredQuestions, q) => {
    // create a deep copy...
    let questions = JSON.parse(JSON.stringify(unfilteredQuestions));

    if (!q) return questions;

    // If there are no questions, then nothing to search
    if (!unfilteredQuestions) return questions;

    // excludes html tags, case-insensitive
    // eslint-disable-next-line no-param-reassign
    q = escapeRegExp(q);
    const regex = new RegExp(`(?!([^<])*?>)${q}`, 'gi');

    // filter out unmatching questions / answers
    questions = questions.filter((question) => {
      if (question.plainText.match(regex)) return true;
      if (question.answer && question.answer.plainText.match(regex))
        return true;
      if (
        question.questions &&
        question.questions.filter(
          (filteredQuestion) =>
            filteredQuestion.plainText.match(regex) ||
            (filteredQuestion.answer &&
              filteredQuestion.answer.plainText.match(regex))
        ).length > 0
      )
        return true;
      if (question.draft.id && question.draft.plainText.match(regex))
        return true;
      return false;
    });

    // add a highlight to matching search string
    if (q.length > 0) {
      questions.map((question) => {
        // eslint-disable-next-line no-param-reassign
        question.text = question.text.replace(regex, '<match>$&</match>');
        // eslint-disable-next-line no-param-reassign
        question.plainText = question.plainText.replace(
          regex,
          '<match>$&</match>'
        );
        if (question.answer) {
          // eslint-disable-next-line no-param-reassign
          question.answer.text = question.answer.text.replace(
            regex,
            '<match>$&</match>'
          );
        }
        if (question.questions) {
          // eslint-disable-next-line no-shadow
          question.questions.map((question) => {
            // eslint-disable-next-line no-param-reassign
            question.text = question.text.replace(regex, '<match>$&</match>');
            if (question.answer) {
              // eslint-disable-next-line no-param-reassign
              question.answer.text = question.answer.text.replace(
                regex,
                '<match>$&</match>'
              );
            }
            return question;
          });
        }
        return question;
      });
    }

    return questions;
  };

  render() {
    const { children } = this.props;
    const { organization } = this.state;

    return (
      <Provider
        value={{
          state: this.state,
          filterWithSearchQuery: this.filterWithSearchQuery,
          handleUpdateSearchQuery: this.handleUpdateSearchQuery,
          updateSearchQuery: this.updateSearchQuery,
          refreshUnreadCount: this.refreshUnreadCount,
          refreshUnread: this.refreshUnread,
          refreshQuestions: this.refreshQuestions,
          refreshDrafts: this.refreshDrafts,
          refreshTrash: this.refreshTrash,
          refreshAll: this.refreshAll,
          createDraft: this.createDraft,
          emptyTrash: this.emptyTrash,
          refreshOrganization: this.refreshOrganization,
          setQuestion: this.setQuestion,
          setUnreadQuestion: this.setUnreadQuestion,
          organization: organization || null,
        }}
      >
        {children}
      </Provider>
    );
  }
}

export default AnswerBarContext;
