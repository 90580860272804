/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../Utils/Api';
import Editor, { EditorToolbar } from '../Editor/Editor';
import { Attachments } from './Attachments';
import { LoadingBar } from '../Partials';
import { UserConsumer } from '../../User/Context';

const LocationSelect = ({
  province,
  provinces,
  errorMessage,
  handleChangeProvince,
}) => (
  <div className="form-group location-select">
    <label htmlFor="province" className="control-label">
      Location:
    </label>
    <div className="select-wrapper">
      <select
        type="text"
        name="province"
        className={`custom-select form-control ${
          errorMessage ? 'is-invalid' : ''
        }`}
        value={province}
        onChange={handleChangeProvince}
        id="province"
      >
        <option value="" disabled>
          Select a location
        </option>
        {provinces.map((i) => (
          <option key={i.key} value={i.key}>
            {i.value}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">{errorMessage}</div>
    </div>
  </div>
);

export const Draft = (props) => (
  <UserConsumer>
    {(userContext) => (
      <UserDraft
        {...props}
        province={userContext.getProvince()}
        provinces={userContext.getProvinces()}
      />
    )}
  </UserConsumer>
);

class UserDraft extends Component {
  constructor(props) {
    super(props);

    this.innerRef = React.createRef();
    this.outerRef = React.createRef();

    this.state = {
      editorHtml: props.question.text,
      autoSaved: false,
      iframeSrc: '', // used to download files
      province: props.question.province || props.province,
      errors: {
        editorHtml: '',
        province: '',
      },
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // Api.getProvinces().then(response => this.setState({provinces: response}));
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.doAutoSave();
    // clear the draft timer.
    // @todo warn user if draft is not autosaved, and/or
    // automatically save the last changes
    clearTimeout(this.autoSaveTimer);
  }

  handleChange = (html) => {
    this.setState({ editorHtml: html });
    this.autoSave();
  };

  handleChangeProvince = (e) => {
    this.setState({ province: e.target.value }, () => this.doAutoSave(true));
  };

  handleDelete = (e) => {
    const { question, answerBar, handleDeletedDraft } = this.props;
    e.preventDefault();
    this.outerRef.current.style.height = `${this.innerRef.current.clientHeight}px`;
    Api.Questions.delete(question.id).then((response) => {
      if (response.success) {
        this.outerRef.current.style.height = '0px';
        answerBar.refreshDrafts();

        if (handleDeletedDraft) handleDeletedDraft(question);
      }
      return response;
    });
  };

  handleBlur = () => {
    this.doAutoSave();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { editorHtml, province } = this.state;
    const { question, answerBar, history } = this.props;
    Api.Questions.update(question.id, {
      text: editorHtml,
      submitted: true,
      province,
    }).then((response) => {
      // @todo clear the draft?
      if (response.success) {
        history.push('/questions/list');
        // this.props.userContext.refresh();
        answerBar.refreshDrafts();

        // in the case we're already on the questions/list path, refresh the questions
        // since the path will not change and cause a refresh automatically.
        if (history.location.pathname === '/questions/list')
          answerBar.refreshQuestions();
      } else {
        if (response.errors.subscription) {
          // response.errors.subscription.map(error => appContext.addError(error) || error);
          history.push('/plans/upgrade');
        }

        const errors = {
          editorHtml: response.errors.editorHtml || '',
          province: response.errors.province || '',
        };

        if (this._isMounted) this.setState({ errors });
      }
    });
  };

  autoSave = () => {
    this.setState({ autoSaved: false });

    // set a 10 second timeout before autosaving so not every
    // key stroke is not sending a request to the server.
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      if (this._isMounted) this.doAutoSave();
    }, 2000);
  };

  doAutoSave = (forceUpdate = false) => {
    const { editorHtml, province } = this.state;
    const { question } = this.props;

    // only have to save if the text has changed
    if (question.text !== editorHtml || forceUpdate) {
      this.setState({ autoSaving: true });
      // Note: this is not updating the current quesiton because
      // it's possible the user has continued to edit after the draft
      // has been auto saved and this would remove edits since the update call
      Api.Questions.update(question.id, {
        text: editorHtml,
        province,
      }).then((response) => {
        if (this._isMounted) {
          const errors = {
            editorHtml: response.errors.editorHtml || '',
            province: response.errors.province || '',
          };
          this.setState({
            autoSaving: false,
            errors,
          });
        }

        return response;
      });
    }
    this.setState({ autoSaved: true });
  };

  deleteAttachment = (attachment) => {
    const { deleteAttachment } = this.props;
    deleteAttachment(attachment);
  };

  openAttachmentDialog = (e) => {
    e.preventDefault();
    const { question, history } = this.props;
    history.push(`/questions/drafts/${question.id}/attachments`);
  };

  render() {
    const { autoSaved, autoSaving, province, editorHtml, errors } = this.state;
    const { question, provinces } = this.props;

    return (
      <div ref={this.outerRef} className="thread draft">
        <div ref={this.innerRef} className="inner">
          <div className="toolbar d-flex flex-row flex-wrap justify-content-between">
            <div
              className={`auto-save-message ${
                autoSaved ? 'visible' : 'hidden'
              }`}
            >
              Draft Auto {autoSaving ? 'Saving...' : 'Saved'}
            </div>
            <div className="editor-actions">
              <EditorToolbar index={question.id}>
                <button
                  type="button"
                  className="btn"
                  onClick={this.openAttachmentDialog}
                >
                  <i className="material-icons">attach_file</i>
                </button>
              </EditorToolbar>
            </div>
            <div className="draft-actions">
              <span className="action" onClick={this.handleDelete}>
                Delete Draft <i className="answerbar-trash" />
              </span>
            </div>
          </div>

          <div className="editor-wrapper">
            <div className="radial-shadow top front" />
            <Editor
              index={question.id}
              placeholder="Your question..."
              editorHtml={editorHtml}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
            />
          </div>

          {question.attachments.length > 0 && (
            <Attachments
              attachments={question.attachments}
              deleteAttachment={this.deleteAttachment}
            />
          )}

          <div className="form-actions">
            {!question.parent_question_id && (
              <LocationSelect
                province={province}
                provinces={provinces}
                errorMessage={errors.province}
                handleChangeProvince={this.handleChangeProvince}
              />
            )}
            <div className="radial-shadow top front" />
            <button
              onClick={this.handleSubmit}
              type="submit"
              className="btn btn-primary"
            >
              Ask Question <i className="answerbar-angle-right" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default class Drafts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      initializing: true,
    };
  }

  componentDidMount() {
    const { answerBar } = this.props;
    this._isMounted = true;
    answerBar.refreshDrafts().then((questions) => {
      this.setState({
        initializing: false,
        questions,
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deleteAttachment = (attachment) => {
    Api.QuestionAttachment.delete(attachment.question_id, attachment.id).then(
      (response) => {
        if (this._isMounted)
          this.removeAttachment(attachment.question_id, attachment.id);
        return response;
      }
    );
  };

  removeAttachment = (question_id, attachment_id) => {
    const { questions } = this.state;
    // Iterate over all questions, filter out the matching attachment
    const filteredQuestions = questions.map((question) => {
      if (question.id === question_id) {
        // eslint-disable-next-line no-param-reassign
        question.attachments = question.attachments.filter(
          (attachment) => attachment.id !== attachment_id
        );
      }
      return question;
    });

    // this.setState(prevState => {
    //   return {...this.prevState, questions: questions}
    // });

    this.setState({ questions: filteredQuestions });
  };

  render() {
    const { initializing } = this.state;
    const { answerBar, questions } = this.props;

    return (
      <div className="drafts-list thread-list">
        {initializing === false &&
          questions.map((question) => {
            return (
              <Draft
                {...this.props}
                key={question.id}
                question={question}
                deleteAttachment={this.deleteAttachment}
              />
            );
          })}

        {initializing && (
          <div className="thread draft">
            <div className="inner">
              <div className="thread-wrapper">
                <div className="inner" style={{ height: 46 }}>
                  {/* Loading Placeholder */}
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        )}

        {!answerBar.state.loading && !initializing && questions.length <= 0 && (
          <div className="empty-text">
            You have no drafts available. <br />{' '}
            <Link
              className="link underlined"
              to="/drafts"
              onClick={(e) => e.preventDefault() || answerBar.createDraft()}
            >
              Ask a Question
            </Link>
          </div>
        )}

        {answerBar.state.loading && (
          <div style={{ marginTop: 40, marginBottom: 40 }}>
            <LoadingBar type="status-bar" />
          </div>
        )}
      </div>
    );
  }
}
