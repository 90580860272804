import React, { Component } from 'react';
import validator from 'validator';
import { LoadingBar } from '../../Components/Partials';

const ResetPasswordForm = ({
  handleSubmit,
  errors,
  password,
  password2,
  handleChangePassword,
  handleChangePassword2,
}) => (
  <form className="password-reset-form" onSubmit={handleSubmit}>
    {/* 
    {props.successMessage && <div className="alert alert-success">{ props.successMessage }</div>}
    {props.errorMessage && <div className="alert alert-danger">{ props.errorMessage }</div>}
    */}
    {errors.email.length > 0 && (
      <div className="alert alert-danger">{errors.email[0]}</div>
    )}
    {errors.token.length > 0 && (
      <div className="alert alert-danger">{errors.token[0]}</div>
    )}

    <div className="form-group">
      <label htmlFor="password">New Password</label>
      <input
        id="password"
        value={password}
        onChange={handleChangePassword}
        type="password"
        className={`form-control ${errors.password.length > 0 && 'is-invalid'}`}
        placeholder="New Password"
      />
      <div className="invalid-feedback">{errors.password[0]}</div>
    </div>

    <div className="form-group">
      <label htmlFor="password2">Confirm Password</label>
      <input
        id="password2"
        value={password2}
        onChange={handleChangePassword2}
        type="password"
        className={`form-control ${
          errors.password2.length > 0 && 'is-invalid'
        }`}
        placeholder="Repeat New Password"
      />
      <div className="invalid-feedback">{errors.password2[0]}</div>
    </div>

    <div className="form-actions">
      <div className="d-flex bd-highlight mb-3">
        <div className="ml-auto">
          <button type="submit" className="btn btn-primary">
            Update Password <i className="answerbar-angle-right" />
          </button>
        </div>
      </div>
    </div>
  </form>
);

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: decodeURIComponent(props.match.params.email),
      password: '',
      password2: '',
      token: props.match.params.token,
      successMessage: '',
      errorMessage: '',
      errors: {
        email: [],
        password: [],
        password2: [],
        token: [],
      },
      loading: false,
    };
  }

  handleChangePassword = (e) => this.setState({ password: e.target.value });

  handleChangePassword2 = (e) => this.setState({ password2: e.target.value });

  validate = () => {
    const { email, password, password2, token } = this.state;
    const errors = {
      email: [],
      password: [],
      password2: [],
      token: [],
    };

    if (validator.isEmpty(email))
      errors.email.push('Email address is required');
    if (validator.isEmpty(token)) errors.token.push('Missing token');
    if (validator.isEmpty(password))
      errors.password.push('Password is required');
    if (validator.isEmpty(password2))
      errors.password2.push('Confirm password is required');
    if (!validator.isEmail(email)) errors.email.push('Invalid email address');
    if (!validator.isLength(password, { min: 8 }))
      errors.password.push('Password must be a minimum of 8 characters');
    if (password !== password2) errors.password2.push('Passwords must match');

    this.setState({ errors });
    return !(
      errors.email.length > 0 ||
      errors.token.length > 0 ||
      errors.password.length > 0 ||
      errors.password2.length > 0
    );
  };

  handleSubmit = (e) => {
    const { loading } = this.state;
    const { userContext, appContext, history } = this.props;

    e.preventDefault();
    if (!this.validate()) return false;

    if (loading) return false;

    this.setState({ loading: true });

    const { email, password, password2, token } = this.state;
    userContext
      .resetPassword(email, password, password2, token)
      .then(({ success, error, errors }) => {
        this.setState({
          loading: false,
          successMessage: success,
          errorMessage: error,
          errors: {
            email: errors && errors.email ? errors.email : [],
            token: errors && errors.token ? errors.token : [],
            password: errors && errors.password ? errors.password : [],
            password2: errors && errors.password2 ? errors.password2 : [],
          },
        });

        if (success) {
          appContext.addMessage(success);
          history.push('/'); // trick to clear the modal history
          history.push(`/login?email=${email}`);
        } else {
          appContext.addError(error);
        }
      });
    return true;
  };

  render() {
    const {
      loading,
      password,
      password2,
      successMessage,
      errorMessage,
      errors,
    } = this.state;

    return (
      <div>
        <h2>Update your password</h2>

        {loading && <LoadingBar type="status-bar" />}

        <ResetPasswordForm
          {...this.props}
          handleSubmit={this.handleSubmit}
          handleChangePassword={this.handleChangePassword}
          handleChangePassword2={this.handleChangePassword2}
          password={password}
          password2={password2}
          successMessage={successMessage}
          errorMessage={errorMessage}
          errors={errors}
        />
      </div>
    );
  }
}
