import React from 'react';

export default () => (
  <div className="footer">
    <div className="inner container">
      <div className="d-flex justify-content-center flex-wrap">
        <div className="mr-auto mb-4 text-links">
          <a
            className="p-1 nowrap text-lg"
            // onClick={window.scrollTo(0, 0)}
            href="https://www.bench.co/"
          >
            © Bench 2011-2024
          </a>
          <a className="p-1 nowrap text-lg" href="https://www.bench.co/terms">
            Terms of Service
          </a>
          <a className="p-1 nowrap text-lg" href="https://www.bench.co/privacy">
            Privacy Policy
          </a>
          <a
            className="p-1 nowrap text-lg"
            href="https://www.bench.co/cookie-policy"
          >
            Cookie Policy
          </a>
          <a
            className="p-1 nowrap text-lg"
            href="https://www.bench.co/security"
          >
            Security
          </a>
          <a className="p-1 nowrap text-lg" href="/privacy">
            Privacy Policy
          </a>
        </div>
        <div className="mr-auto mr-lg-0 social-links">
          <a
            className="p-1 nowrap text-lg"
            href="https://www.youtube.com/channel/UCKY3_ojf5qQt0VjvML3p4ng"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-youtube-play" />
          </a>
          <a
            className="p-1 nowrap text-lg"
            href="https://twitter.com/bench"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-twitter" />
          </a>
          <a
            className="p-1 nowrap text-lg"
            href="https://web.facebook.com/BenchAccounting/?_rdc=1&_rdr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-facebook" />
          </a>
          <a
            className="p-1 nowrap text-lg"
            href="https://www.instagram.com/benchaccounting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-instagram" />
          </a>
        </div>
      </div>
    </div>
  </div>
);
