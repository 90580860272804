import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProtectedRoute, PublicRoute, User } from '../User';
import { UserConsumer } from '../User/Context';
import { AnswerBarConsumer, AnswerBarProvider } from './AnswerBarContext';
import { AppConsumer } from './AppContext';
import { Header, Footer, Modal } from './Layouts';
import DemoFooter from './Partials/demo/Footer';
import DemoHeader from './Partials/demo/Header';
import Loading from './Loading';
import Organization from './Organization/index';
import Plans from './Plans';
import Subscribe from './Subscribe';
import Subscription from './Subscription';
import Threads from './Threads';
import AttachmentUploader from './Threads/AttachmentUploader';
import { QuestionConfirm } from './Threads/Questions';
import Sharing from './Threads/Sharing';
import { Content, NotFound, Pricing } from './Views';
import Home from './Views/Home';
import Company from './Views/Company';
import Blog from './Views/Blog';
import Faq from './Views/Faq';
import Newsletter from './Views/Newsletter';
import Notifications from './Views/Notifications';
import AskInitialQuestion from './Views/AskInitialQuestion';
import { subdomain } from '../Whitelabel';

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      inModalView: false,
      inMenuView: false,
      modalCloseUrl: '/',
      isLoading: false,
      displayWelcome: false,
      initialQuestion: '',
      initialQuestionIsValid: false,
      provinces: [],
    };
  }

  componentDidMount() {
    const { history } = this.props;
    this._isMounted = true;
    this.unlisten = history.listen(this.onRouteChange);
    setTimeout(() => {
      if (this._isMounted) this.setState({ loading: false });
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.unlisten(); // unbinds history.listen
  }

  setInitialQuestion = (text) => {
    this.setState({
      initialQuestion: text,
      initialQuestionIsValid: text.length > 2,
    });
  };

  handleModal = (bool) => {
    this.setState({ inModalView: bool });
  };

  handleMenu = (bool) => {
    this.setState({ inMenuView: bool });
  };

  onRouteChange = () => {
    const { inModalView, modalCloseUrl } = this.state;
    const { location } = this.props;
    // unless already in a modal, track every change to the url
    // so when a modal is displayed, the last non-modal route is
    // stored in the AnswerBar state and passed as a render prop
    // "closeUrl".
    if (!inModalView && !modalCloseUrl)
      this.setState({ modalCloseUrl: location.pathname });
  };

  render() {
    const {
      provinces,
      inModalView,
      inMenuView,
      loading,
      modalCloseUrl,
      initialQuestion,
      initialQuestionIsValid,
    } = this.state;
    return (
      <Route
        render={(routeProps) => (
          <AppConsumer>
            {(appContext) => (
              <UserConsumer provinces={provinces}>
                {(userContext) => (
                  <AnswerBarProvider {...routeProps} userContext={userContext}>
                    {/* move the answerbar provider "inside" the app, ie only displays once a user is logged in */}
                    <AnswerBarConsumer>
                      {(answerBarContext) => (
                        <div
                          className={`app-container ${
                            inModalView ? 'in-modal-view' : ''
                          } ${inMenuView ? 'in-menu-view' : ''}`}
                        >
                          <div id="app-menu" />
                          <div className="app-background-wrapper">
                            <div className="app-background" />
                          </div>
                          <div className="app-viewport d-flex align-content-stretch flex-column">
                            <div className="app-header pt-safe">
                              {subdomain === 'demo' ? (
                                <DemoHeader />
                              ) : (
                                <Header
                                  handleMenu={this.handleMenu}
                                  organization={
                                    answerBarContext.state.organization
                                  }
                                />
                              )}
                            </div>
                            <div
                              className={`app-view d-flex flex-fill ${
                                userContext?.state?.isAuthenticated
                                  ? 'logged-in'
                                  : 'logged-out'
                              }`}
                            >
                              <div className="main-view w-100">
                                <div className="inner">
                                  <div
                                    className={`loading-view ${
                                      loading || userContext.state.loading
                                        ? 'inactive' // 'active'
                                        : 'inactive'
                                    }`}
                                  >
                                    <Loading />
                                  </div>
                                  <Switch>
                                    <Route
                                      path="/login"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <PublicRoute
                                            path="/login"
                                            initialQuestion={initialQuestion}
                                            answerBar={answerBarContext}
                                            appContext={appContext}
                                            component={User.Login}
                                          />
                                        </Modal>
                                      )}
                                    />
                                    <Route
                                      path="/signup"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                          printable={
                                            props.location.pathname ===
                                            '/signup/terms'
                                          }
                                        >
                                          <PublicRoute
                                            path="/signup"
                                            initialQuestion={initialQuestion}
                                            answerBar={answerBarContext}
                                            appContext={appContext}
                                            component={User.Signup}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <PublicRoute
                                      path="/logout"
                                      component={User.Logout}
                                    />

                                    {/* 
                            <Route path="/signup/terms" component={Terms} />
                            <Route path="/terms" render={props => (
                              <Modal {...props} doModal={this.handleModal} closeUrl={this.state.modalCloseUrl}>
                                <Terms {...props} />
                              </Modal>
                            )} />
                            */}
                                    <PublicRoute
                                      exact
                                      path="/"
                                      {...this.props}
                                      component={Home}
                                      answerBar={answerBarContext}
                                      appContext={appContext}
                                      initialQuestion={initialQuestion}
                                      setInitialQuestion={
                                        this.setInitialQuestion
                                      }
                                      initialQuestionIsValid={
                                        initialQuestionIsValid
                                      }
                                    />

                                    <Route
                                      path="/ask"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <PublicRoute
                                            exact
                                            path="/ask"
                                            {...this.props}
                                            component={AskInitialQuestion}
                                            answerBar={answerBarContext}
                                            appContext={appContext}
                                            initialQuestion={initialQuestion}
                                            setInitialQuestion={
                                              this.setInitialQuestion
                                            }
                                            initialQuestionIsValid={
                                              initialQuestionIsValid
                                            }
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <PublicRoute
                                      path="/company"
                                      component={Company}
                                    />
                                    <PublicRoute
                                      path="/blog/:slug?"
                                      component={Blog}
                                      exact={false}
                                    />

                                    <Route
                                      path="/newsletter"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <PublicRoute
                                            path="/newsletter"
                                            component={Newsletter}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/pricing"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <PublicRoute
                                            path="/pricing"
                                            initialQuestion={initialQuestion}
                                            answerBar={answerBarContext}
                                            appContext={appContext}
                                            component={Pricing}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/organization"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <>
                                            <ProtectedRoute
                                              {...props}
                                              answerBar={answerBarContext}
                                              appContext={appContext}
                                              path="/organization/manage-users"
                                              component={
                                                Organization.ManageUsers
                                              }
                                            />
                                          </>
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/account"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <ProtectedRoute
                                            path="/account/edit-profile"
                                            appContext={appContext}
                                            component={User.EditProfile}
                                          />

                                          <PublicRoute
                                            exact
                                            path="/account/forgot-password"
                                            appContext={appContext}
                                            component={User.ForgotPassword}
                                          />
                                          <PublicRoute
                                            exact
                                            path="/account/reset-password/:token/:email"
                                            appContext={appContext}
                                            component={User.ResetPassword}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/plans"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <ProtectedRoute
                                            path="/plans"
                                            appContext={appContext}
                                            component={Plans}
                                            redirect="/signup"
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/subscribe/:plan_id"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                          printable={
                                            !!props.location.pathname.endsWith(
                                              'terms'
                                            )
                                          }
                                        >
                                          <ProtectedRoute
                                            path="/subscribe/:plan_id"
                                            appContext={appContext}
                                            organization={
                                              answerBarContext.organization
                                            }
                                            component={Subscribe}
                                            redirect="/signup"
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/myplan"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <ProtectedRoute
                                            {...props}
                                            path="/myplan"
                                            appContext={appContext}
                                            component={Subscription}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/question/:id/sharing"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <ProtectedRoute
                                            {...props}
                                            answerBar={answerBarContext}
                                            path="/question/:id/sharing"
                                            component={Sharing}
                                          />
                                        </Modal>
                                      )}
                                    />
                                    <Route
                                      path="/question/confirm"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                        >
                                          <ProtectedRoute
                                            path="/question/confirm"
                                            initialQuestion={initialQuestion}
                                            setInitialQuestion={
                                              this.setInitialQuestion
                                            }
                                            component={() => (
                                              <QuestionConfirm
                                                {...props}
                                                userContext={userContext}
                                                initialQuestion={
                                                  initialQuestion
                                                }
                                                setInitialQuestion={
                                                  this.setInitialQuestion
                                                }
                                              />
                                            )}
                                          />
                                        </Modal>
                                      )}
                                    />
                                    <Route
                                      path="/questions/drafts/:id/attachments"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                          closeText="Close"
                                        >
                                          <ProtectedRoute
                                            {...props}
                                            appContext={appContext}
                                            question_id={props.match.params.id}
                                            path="/questions/drafts/:id/attachments"
                                            component={AttachmentUploader}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/terms"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                          closeText="Close"
                                          printable
                                        >
                                          <PublicRoute
                                            {...props}
                                            path="/terms"
                                            content_id="terms"
                                            component={Content}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/privacy"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                          closeText="Close"
                                        >
                                          <PublicRoute
                                            {...props}
                                            path="/privacy"
                                            content_id="privacy"
                                            component={Content}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route
                                      path="/contact"
                                      render={(props) => (
                                        <Modal
                                          {...props}
                                          doModal={this.handleModal}
                                          closeUrl={modalCloseUrl}
                                          closeText="Close"
                                        >
                                          <PublicRoute
                                            {...props}
                                            path="/contact"
                                            content_id="contact"
                                            component={Content}
                                          />
                                        </Modal>
                                      )}
                                    />

                                    <Route path="/faq" component={Faq} />
                                    <Route
                                      path="/notifications"
                                      component={Notifications}
                                    />

                                    <Route
                                      path="/questions"
                                      render={() => (
                                        <>
                                          <ProtectedRoute
                                            answerBar={answerBarContext}
                                            path="/questions/drafts"
                                            component={Threads}
                                          />
                                          <ProtectedRoute
                                            answerBar={answerBarContext}
                                            path="/questions/unread"
                                            component={Threads}
                                          />
                                          <ProtectedRoute
                                            answerBar={answerBarContext}
                                            path="/questions/list"
                                            component={Threads}
                                          />
                                          <ProtectedRoute
                                            answerBar={answerBarContext}
                                            path="/questions/trash"
                                            component={Threads}
                                          />
                                        </>
                                      )}
                                    />
                                    <Route component={NotFound} />
                                  </Switch>
                                </div>
                              </div>
                            </div>
                            <div className="app-footer pb-safe">
                              {subdomain === 'demo' ? (
                                <DemoFooter />
                              ) : (
                                <Footer />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </AnswerBarConsumer>
                  </AnswerBarProvider>
                )}
              </UserConsumer>
            )}
          </AppConsumer>
        )}
      />
    );
  }
}
