import React from 'react';
import { Api } from '../Utils/Api';
import LoadingBar from '../Partials/LoadingBar';

export default class Content extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { content_id } = this.props;

    Api.Content.retrieve(content_id).then((response) => {
      if (this._isMounted) {
        this.setState({
          content: response.value,
          loading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { loading, content } = this.state;
    return (
      <div>
        {!loading && (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
        {loading && <LoadingBar type="status-bar" />}
      </div>
    );
  }
}
