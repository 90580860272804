import numeral from 'numeral';

// https://stackoverflow.com/questions/16242449/regex-currency-validation
const currencyRegex = /(?=.*?\d)^-?\$(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/gm;
const numberRegex = /(?=.*?\d)^-?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/gm;

export const number = (n) => numeral(n).format('0,0[.]00');
export const currency = (n, optionalDecimals = true) =>
  `$${numeral(n).format(optionalDecimals ? '0,0[.]00' : '0,0.00')}`;
export const isCurrency = (str) =>
  typeof str === 'string' && Boolean(str.match(currencyRegex));
export const isNumber = (str) =>
  typeof str === 'string' && Boolean(str.match(numberRegex));
