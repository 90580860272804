import React from 'react';
import { NavLink, Link } from 'react-router-dom';

export default ({
  answerBar,
  // counts,
  location,
  searchQuery,
  handleUpdateSearchQuery,
  updateSearchQuery,
  createDraft,
  emptyTrash,
  // path,
}) => (
  <div className="header row">
    <div className="col-md">
      <div className="nav-scroll-container">
        <ul className="nav nav-categories">
          {answerBar.state.counts.unread > 0 && (
            <li className="notifications">
              <NavLink to="/questions/unread">
                {answerBar.state.counts.unread}
              </NavLink>
            </li>
          )}
          {answerBar.state.counts.questions > 0 && ( // || path === '/questions/list') &&
            <li>
              <NavLink to="/questions/list">
                <div className="nav-label">Questions</div>{' '}
                <div className="nav-label-count">
                  ({answerBar.state.counts.questions})
                </div>
              </NavLink>
            </li>
          )}
          {answerBar.state.counts.drafts > 0 && ( // || path === '/questions/drafts') &&
            <li>
              <NavLink to="/questions/drafts">
                <div className="nav-label">Drafts</div>{' '}
                <div className="nav-label-count">
                  ({answerBar.state.counts.drafts})
                </div>
              </NavLink>
            </li>
          )}
          {answerBar.state.counts.trash > 0 && ( // || path === '/questions/trash') &&
            <li>
              <NavLink to="/questions/trash">
                <div className="nav-label">Trash</div>{' '}
                <div className="nav-label-count">
                  ({answerBar.state.counts.trash})
                </div>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>

    {location.pathname === '/questions/unread' && (
      <>
        <div className="col-md d-flex justify-content-center">
          <ul className="nav nav-main">
            <li>
              <Link
                to="/drafts"
                onClick={(e) => e.preventDefault() || createDraft()}
              >
                New Question <i className="answerbar-plus" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md">
          <ul className="nav nav-secondary float-right" />
        </div>
      </>
    )}

    {location.pathname === '/questions/list' && (
      <>
        <div className="col-md d-flex justify-content-center">
          <ul className="nav nav-main">
            <li>
              <Link
                to="/drafts"
                onClick={(e) => e.preventDefault() || createDraft()}
              >
                New Question <i className="answerbar-plus" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md">
          {answerBar.state.counts.questions > 0 && (
            <div
              className={`search-wrapper float-right ${
                searchQuery !== '' ? 'with-query' : ''
              }`}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="form-group">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleUpdateSearchQuery}
                    className="form-control"
                    id="search-input"
                    aria-describedby="search"
                    placeholder="Search Questions"
                    autoComplete="off"
                  />
                </div>

                <div
                  className="close-wrapper"
                  onClick={() => updateSearchQuery('')}
                >
                  <div className="close-bars">
                    <div className="close-bar" />
                    <div className="close-bar" />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </>
    )}

    {location.pathname === '/questions/drafts' && (
      <>
        <div className="col-md d-flex justify-content-center">
          <ul className="nav nav-main">
            <li>
              <Link
                to="/drafts"
                onClick={(e) => e.preventDefault() || createDraft()}
              >
                New Question <i className="answerbar-plus" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md">
          {/* 
          <ul className="nav nav-secondary float-right">
          </ul>
          */}
        </div>
      </>
    )}

    {location.pathname === '/questions/trash' && (
      <>
        <div className="col-md d-flex justify-content-center">
          <ul className="nav nav-main">
            <li>
              <div onClick={(e) => e.preventDefault() || createDraft()}>
                New Question <i className="answerbar-plus" />
              </div>
            </li>
          </ul>
        </div>
        <div className="col-md">
          {answerBar.state.counts.trash > 0 && (
            <ul className="nav nav-secondary float-right">
              <li>
                <span
                  className="underlined danger"
                  onClick={(e) => e.preventDefault() || emptyTrash()}
                >
                  Empty Trash
                </span>
              </li>
            </ul>
          )}
        </div>
      </>
    )}

    {/* 
    <ul className="nav nav-secondary">
      <li><a href="">New Draft</a></li>
    </ul>
     */}
  </div>
);
