import React, { Component, useContext } from 'react';
import UserContext from '../../User/Context';
import { Api } from '../Utils/Api';
import { FileSize } from '../Utils/FileSize';

// const IFrame = props =>
//   <div className="attachment-frame d-flex justify-content-center align-content-center" onClick={props.closeAttachment}>
//     {props.iframeSrc && <iframe src={props.iframeSrc} frameBorder="0"></iframe>}
//   </div>

const AttachmentItem = ({ attachment, showDelete, handleDelete }) => {
  const { accessToken } = useContext(UserContext).state;
  const url = `${attachment.url}?accessToken=${accessToken}`;

  return (
    <a
      className="file-attachment-item"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <span
        className="inner"
        title={`${attachment.filename} (${attachment.filesize})`}
      >
        {showDelete && (
          <i
            className="answerbar-trash push-item"
            onClick={(e) => handleDelete(e, attachment)}
          />
        )}
        <span className="push-item truncate">
          <span className="filename">
            {attachment.title || attachment.filename}
          </span>
          <span className="filesize">({FileSize(attachment.filesize)})</span>
        </span>
      </span>
    </a>
  );
};

export class Attachments extends Component {
  handleDelete = (e, attachment) => {
    const { deleteAttachment } = this.props;
    e.preventDefault();
    e.stopPropagation();
    deleteAttachment(attachment);
  };

  openFileAttachment = (e, attachment) => {
    e.preventDefault();

    Api.QuestionAttachment.retrieve(attachment.question_id, attachment.id).then(
      (response) => {
        const { absoluteUrl } = response.attachmentF;

        if (absoluteUrl) {
          this.setState({
            iframeSrc: absoluteUrl,
          });
        }
        return response;
      }
    );

    // alert("@todo open file attachment: request secure file name from server, then download.")
  };

  render() {
    const { attachments, deleteAttachment } = this.props;
    return (
      <div className="attachments-wrapper">
        {attachments.map((attachment) => (
          <AttachmentItem
            key={attachment.id}
            attachment={attachment}
            handleClick={this.openFileAttachment}
            showDelete={typeof deleteAttachment === 'function'}
            handleDelete={this.handleDelete}
          />
        ))}

        {/* 
        {this.state.iframeSrc && <IFrame iframeSrc={this.state.iframeSrc} closeAttachment={() => this.setState({iframeSrc: null})} />}
        */}
      </div>
    );
  }
}
