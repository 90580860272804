import React, { Component } from 'react';
import { UserAvatar } from '../Components/Avatar';

export class EditPhotoForm extends Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.state = {
      selectedFile: null,
      hasError: false,
    };
  }

  fileSelectedHandler = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      this.setState({ selectedFile });
      this.uploadFile(selectedFile);
    }
  };

  fileUploadHandler = () => {
    const { selectedFile } = this.state;
    if (selectedFile) this.uploadFile(selectedFile);
  };

  uploadFile = (selectedFile) => {
    const { userContext, appContext } = this.props;
    // clear errors
    this.setState({ hasError: false });

    if (selectedFile)
      userContext.uploadPhoto(selectedFile).then((response) => {
        if (response.success) appContext.addMessage(response.message);
        else appContext.addError(response.message);

        this.setState({
          hasError: !response.success,
        });
        return response;
      });
  };

  render() {
    const { hasError } = this.state;
    const { userContext } = this.props;

    return (
      <div
        className={`profile-photo-form text-center ${
          hasError ? 'with-error' : ''
        }`}
      >
        <div className="photo-wrapper">
          <input
            ref={this.fileInputRef}
            type="file"
            onChange={this.fileSelectedHandler}
            style={{ display: 'none' }}
          />
          <button
            type="button"
            onClick={this.fileUploadHandler}
            style={{ display: 'none' }}
          >
            Upload
          </button>
          <div
            onClick={() => this.fileInputRef.current.click()}
            style={{ cursor: 'pointer' }}
          >
            <UserAvatar user={userContext.currentUser} theme="light" />
          </div>
          <div className="upload-icon">
            <i className="material-icons">forward</i>
          </div>
        </div>
      </div>
    );
  }
}
