import React from 'react';

export default () => {
  const { pathname } = window.location;
  return (
    <div className="page-view not-found-view">
      <div className="inner">
        <div className="page-header">
          <h1>Hmm.. Looks like this page cannot be found.</h1>
        </div>
        <div>Path: {pathname}</div>
        <p>
          <a href="/">Take me home</a>
        </p>
      </div>
    </div>
  );
};
