const whitelabels = ['demo'];

const getSubdomain = () => {
  const { hostname } = window.location;
  // This assumes your domain structure is `subdomain.domain.com`
  const parts = hostname.split('.');
  // Adjust the condition based on your expected domain structure, especially for localhost or when using ports
  if (parts.length >= 3 && parts[0] !== 'www') {
    return whitelabels.includes(parts[0]) ? parts[0] : null;
  }
  return null; // No subdomain or not applicable
};

const subdomain = getSubdomain();

if (subdomain) {
  import(`./styles/${subdomain}/theme.css`)
    .then(() => {
      // eslint-disable-next-line no-console
      // console.log(`${subdomain}.css loaded successfully`);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`Failed to load ${subdomain}.css`, err);
    });
}

export { subdomain };
