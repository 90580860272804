/* eslint-disable react/no-array-index-key */
import React from 'react';
// import { AppConsumer } from './AppContext';

const AlertError = ({ error, index, removeError }) => (
  <div className="alert-wrapper">
    <div className="alert alert-error animated slideInUp">
      <div className="message" onClick={() => removeError(index)}>
        {error}
        {/* 
        <i className="answerbar-close"></i>
        */}
      </div>
    </div>
  </div>
);

const AlertMessage = ({ message, index, removeMessage }) => (
  <div className="alert-wrapper">
    <div className="alert alert-success animated slideInUp">
      <div className="message" onClick={() => removeMessage(index)}>
        {message}
        {/* 
        <i className="answerbar-close"></i>
        */}
      </div>
    </div>
  </div>
);

export const Alerts = (props) => {
  const { errors, messages } = props;
  return (
    <div id="app-alerts" className="alerts d-flex flex-column">
      {errors.map((error, i) => (
        <AlertError {...props} key={i} index={i} error={error} />
      ))}
      {messages.map((message, i) => (
        <AlertMessage {...props} key={i} index={i} message={message} />
      ))}
    </div>
  );
};
