import React, { Component } from 'react';

const { Consumer, Provider } = React.createContext();

export const ModalConsumer = ({ children }) => <Consumer>{children}</Consumer>;

export class ModalProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  resetHistory = () => {};

  render() {
    const { children } = this.props;
    return (
      <Provider
        value={{
          state: this.state,
          resetHistory: this.resetHistory,
        }}
      >
        {children}
      </Provider>
    );
  }
}
