import React from 'react';
import { Capacitor, Plugins } from '@capacitor/core';
import store from 'store';
import Api from '../../User/Api';

const { PushNotifications } = Plugins;
const { isNative } = Capacitor;

if (isNative) {
  PushNotifications.removeAllListeners();

  PushNotifications.addListener('registration', (token) => {
    Api.registerPushToken(token.value);
  });

  PushNotifications.addListener('registrationError', (error) => {
    // eslint-disable-next-line no-console
    console.log(`Error on registration: ${JSON.stringify(error)}`);
  });

  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    // eslint-disable-next-line no-console
    console.log(`Push received: ${JSON.stringify(notification)}`);
  });

  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification) => {
      // eslint-disable-next-line no-console
      console.log(`Push action performed: ${JSON.stringify(notification)}`);
    }
  );
}

const Prompt = ({ history }) => {
  const handleNoThanks = () => {
    store.set('pushNotificationsPrompt', 'noThanks');
    history.push('/questions/list');
  };

  const handleEnableNotifications = () => {
    // Nothing to do if it's not native
    if (!isNative) return;

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then((result) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        history.push('/questions/list');
      } else {
        // Show some error
      }
    });
  };

  return (
    <>
      <div className="mt-auto">
        <h1 className="ml-0">
          Turn on
          <br className="br-sm" /> push notifications
        </h1>
        <p className="large">
          Get notified as soon as we&apos;ve answered your questions.
        </p>
        {/* <p className="mb-4">
          <span className="underlined">You have unanswered questions!</span>
        </p> */}
      </div>

      <div className="mt-auto pb-4">
        <button
          type="button"
          className="btn btn-lg btn-white btn-block"
          onClick={handleEnableNotifications}
        >
          Enable Push Notifications
        </button>
        <div className="mt-4 text-center font-base">
          <span onClick={handleNoThanks}>No thanks</span>
        </div>
      </div>
    </>
  );
};

const Notifications = ({ history }) => {
  return (
    <div className="page-view d-flex flex-fill notifications-view container">
      <div className="inner px-4 d-flex flex-column">
        <Prompt history={history} />
      </div>
    </div>
  );
};

export default Notifications;
