import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import validator from 'validator';
import { parse } from 'querystringify';
import { LoadingBar } from '../../Components/Partials';

const LoginForm = ({
  handleSubmit,
  email,
  errors,
  userContext,
  validateEmail,
  handleChangeEmail,
  password,
  validatePassword,
  handleChangePassword,
  canSubmit,
  loading,
}) => (
  <div>
    <form className="login-form" onSubmit={handleSubmit} noValidate>
      {/* 
      {props.errorMessage &&
        <div className="alert alert-danger">{ props.errorMessage }</div>
      }
      */}

      <div className="form-group">
        <label htmlFor="email">Email address</label>
        <input
          value={email}
          onBlur={() => validateEmail()}
          onChange={(e) => handleChangeEmail(e.target.value)}
          type="email"
          autoComplete="username"
          id="email"
          placeholder="your@email.com"
          className={`form-control ${errors.email.length > 0 && 'is-invalid'}`}
          readOnly={userContext.state.inviteCode ? 'readonly' : false}
        />
        <div className="invalid-feedback inline-error">{errors.email[0]}</div>
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          value={password}
          onBlur={() => validatePassword()}
          onChange={handleChangePassword}
          type="password"
          autoComplete="current-password"
          id="password"
          className={`form-control ${
            errors.password.length > 0 && 'is-invalid'
          }`}
        />
        <div className="invalid-feedback inline-error">
          {errors.password[0]}
        </div>
      </div>

      <div className="form-actions d-flex bd-highlight mb-3">
        <div className="">
          <div className="form-cta">
            <Link to="/account/forgot-password" className="underlined">
              Having trouble logging in?
            </Link>
            <Link to="/signup" className="underlined">
              Not a member? Sign Up
            </Link>
          </div>
        </div>
        <div className="ml-auto">
          <button
            type="submit"
            className={`btn btn-primary ${canSubmit ? '' : 'btn-hidden'}`}
          >
            Login <i className="answerbar-angle-right" />
          </button>
        </div>
      </div>
    </form>

    {loading && <LoadingBar type="status-bar" />}
  </div>
);

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      // email address is stored in user context
      password: '',
      errorMessage: '',
      loading: false,
      errors: {
        email: [],
        password: [],
      },
    };
  }

  componentDidMount() {
    const { location, userContext, history } = this.props;
    // if email is set in the url, set is as the default value in the form
    const { email } = parse(location.search);

    if (email) userContext.handleChangeAuthEmail(email);

    // if logged in, redirect to homepage
    if (userContext.isAuthenticated) {
      // unless there is an invite_code in the url, then reauthenticate
      const { invite_code } = parse(location.search);
      if (!invite_code) history.push('/');
    }
  }

  handleChangePassword = (e) => this.setState({ password: e.target.value });

  validate = () => this.validateEmail() && this.validatePassword();

  validateEmail = () => {
    const { userContext } = this.props;
    const { authEmail } = userContext.state;

    if (validator.isEmpty(authEmail)) {
      this.setFormError('email', 'Email address is required');
      return false;
    }

    if (!validator.isEmail(authEmail)) {
      this.setFormError('email', 'Please enter a valid email address');
      return false;
    }

    this.clearFormError('email');
    return true;
  };

  validatePassword = () => {
    const { password } = this.state;

    if (validator.isEmpty(password)) {
      this.setFormError('password', 'Password is required');
      return false;
    }

    this.clearFormError('password');
    return true;
  };

  setFormError = (name, message) => {
    this.setState((prevState) => {
      const errors = { ...prevState.errors }; // same as Object.assign(), makes a copy
      errors[name] = message ? [message] : [];
      return { errors };
    });
  };

  clearFormError = (name) => {
    this.setFormError(name, '');
  };

  canSubmit = () => {
    const { userContext } = this.props;
    const { authEmail } = userContext.state;
    const { password, errors } = this.state;

    if (validator.isEmpty(authEmail) || validator.isEmpty(password))
      return false;

    if (errors.email.length > 0 || errors.password.length > 0) return false;

    return true;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { userContext, answerBar, appContext } = this.props;
    const { authEmail, loading } = userContext.state;
    const { password } = this.state;
    const valid = this.validate();

    // display error messages
    if (!valid) return;

    if (loading) return;

    this.setState({ loading: true });

    userContext.authenticate(authEmail, password).then((response) => {
      const timeout = response.success ? 500 : 0;

      // delay a few seconds (if successful) for welcome animation
      setTimeout(() => {
        answerBar.refreshAll();
        this.setState({
          loading: false,
          redirectToReferrer: response.success === 1, //! (response.errorCode),
          errorMessage: response.errorMessage,
        });
      }, timeout);

      // otherwise display errors
      if (response.errorMessage) appContext.addError(response.errorMessage);
    });
  };

  render() {
    const {
      redirectToReferrer,
      password,
      errorMessage,
      errors,
      loading,
    } = this.state;
    const { location, initialQuestion, userContext } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };

    if (redirectToReferrer) {
      return <Redirect to={initialQuestion ? 'question/confirm' : from} />;
    }

    return (
      <div>
        <h2>Login</h2>

        <LoginForm
          {...this.props}
          canSubmit={this.canSubmit()}
          handleSubmit={this.handleSubmit}
          handleChangeEmail={userContext.handleChangeAuthEmail}
          handleChangePassword={this.handleChangePassword}
          validateEmail={this.validateEmail}
          validatePassword={this.validatePassword}
          email={userContext.state.authEmail}
          password={password}
          errorMessage={errorMessage}
          errors={errors}
          loading={loading}
        />
      </div>
    );
  }
}
