import React from 'react';
import NewsletterForm from '../Forms/NewsletterForm';

const Newsletter = () => {
  return (
    <div>
      <h1>Subscribe to AnswerBar.</h1>
      <p>
        Stay informed through our monthly newsletter featuring common questions
        &amp; answers plus relevant HR updates. We help you and your team make
        better decisions.
      </p>
      <p className="mt-4">Spam-free. Unsubscribe anytime.</p>

      <div style={{ marginTop: 60 }}>
        <NewsletterForm />
      </div>
    </div>
  );
};

export default Newsletter;
