import React from 'react';

export default ({ type }) => (
  <div className={`loading-bar-wrapper ${type}`}>
    <div className="loading-bar">
      <div className="loading-bar-progress" />
      <div className="loading-bar-progress" />
    </div>
    <div className="radial-shadow" />
  </div>
);
