import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  function learnMore(e) {
    e.preventDefault();
    e.stopPropagation();
    // const top =
    //   e.target.getBoundingClientRect().top +
    //   document.documentElement.scrollTop -
    //   10;
    const top = document.getElementById('learnMore').offsetTop + 30;
    window.scrollTo({ top, left: 0, behavior: 'smooth' });
  }

  return (
    <div className="landing-view hero-section">
      <section className="landing-section pt-5 pb-5">
        <div className="inner pt-0 pb-4 mx-auto">
          <div className="container">
            <div className="row">
              <div className="col col-sm col-left">
                <div className="copy">
                  <h5 className="text-sm mb-0">
                    {/* Hello, we&apos;re Human Resources. How can we help? */}
                    {/* Get answers to your HR questions. */}
                  </h5>
                  <h1 className="mb-4">
                    Accurate <span className="br" /> &amp; personalized
                    {/* Accurate &amp; <span className="br" /> customized{' '} */}
                    <span className="br" />
                    HR knowledge.
                  </h1>
                  <div className="font-sans text-md mb-4">
                    {/* We&apos;re{' '}
                    <span className="underlined">Human Resources</span> when you
                    need it.  */}
                    Expert <span className="underlined">Human Resource</span>{' '}
                    advice, templates and policies when you need them.
                    {/* Get expert HR advice, templates and policies when you need
                    them. */}
                  </div>
                  <p className="font-sans text-xs mb-5">
                    No topics are off-limits, too simple or too complex. Offload
                    time consuming research while minimizing risk and
                    eliminating the need to guess. Ask us questions anytime – we
                    answer them all.
                  </p>
                  <div className="hero-cta mb-3">
                    <Link
                      to="/ask"
                      className="btn btn-tertiary mb-md-2 mb-lg-0 mr-2"
                    >
                      Get Started for FREE
                    </Link>
                    <div className="btn btn-outline" onClick={learnMore}>
                      Learn More
                    </div>
                  </div>
                  <p className="opacity-60 font-sans text-xxs mb-0">
                    First two questions FREE. No credit card required.
                  </p>
                </div>
              </div>
              <div className="col col-sm col-right pl-0">
                <div className="position-relative">
                  <div className="hero-image">
                    <img
                      src="/images/hero-full.png"
                      alt="Ask AnswerBar"
                      className="img-fluid"
                    />
                    <div className="shadow-box">
                      <div className="shadow" />
                      <div className="shadow" />
                    </div>
                  </div>
                  <div className="quote text-center text-sm mb-4">
                    &ldquo;It&apos;s like having an HR expert{' '}
                    <span className="br-sm" /> in your backpocket.&rdquo;
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cta-wrapper d-flex justify-content-center mt-5">
              <div
                id="cta-more"
                className="cta-more d-flex align-items-end"
                onClick={learnMore}
              >
                <div className="cta-label">What is AnswerBar?</div>
                <div className="cta-icon">
                  <div className="answerbar-angle-down" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <div id="learnMore" />
    </div>
  );
};

export default Hero;
