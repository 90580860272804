import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  <div className="footer">
    <div className="inner container">
      <div className="d-flex justify-content-center flex-wrap">
        <div className="mr-auto mb-4 text-links">
          <Link
            className="p-2 nowrap"
            to="/company"
            onClick={window.scrollTo(0, 0)}
          >
            Company
          </Link>
          <Link className="p-2 nowrap" to="/newsletter">
            Stay Connected
          </Link>
          <Link className="p-2 nowrap" to="/contact">
            Get in Touch
          </Link>
          <Link className="p-2 nowrap" to="/pricing">
            Pricing
          </Link>
          <Link className="p-2 nowrap" to="/terms">
            Terms of Use
          </Link>
          <Link className="p-2 nowrap" to="/privacy">
            Privacy Policy
          </Link>
        </div>
        <div className="mr-auto mr-lg-0 social-links">
          <a
            className="p-2 nowrap"
            href="https://www.facebook.com/Answerbar/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-facebook" />
          </a>
          <a
            className="p-2 nowrap"
            href="https://twitter.com/AskAnswerBar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-twitter" />
          </a>
          <a
            className="p-2 nowrap"
            href="https://www.instagram.com/askanswerbar/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-instagram" />
          </a>
          <a
            className="p-2 nowrap"
            href="https://www.youtube.com/channel/UCnx1QS2_fG-y_2EfWUEcXWw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-youtube" />
          </a>
          <a
            className="p-2 nowrap"
            href="https://www.linkedin.com/company/amui-inc."
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-linkedin" />
          </a>
        </div>
      </div>
    </div>
  </div>
);
