/* eslint-disable prefer-destructuring */
import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import Api from '../../Api';
import AnswerBarContext from '../../Components/AnswerBarContext';

const SubscriptionInfo = ({ subscription }) => {
  const { organization } = useContext(AnswerBarContext);
  return (
    <>
      {(!subscription || !subscription.hasPlan) && (
        <div>
          Free User -{' '}
          <Link to="/plans" className="underlined">
            Upgrade Membership
          </Link>
        </div>
      )}
      {subscription && subscription.hasPlan && (
        <div>
          <span>Plan: {subscription.title}</span>
          {!!organization && !!organization.isAdmin && (
            <span>
              {' '}
              -{' '}
              <Link to="/plans" className="underlined">
                Change Plan / Update Billing Info
              </Link>
            </span>
          )}
        </div>
      )}
    </>
  );
};

export class EditProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      firstName: props.userContext.getFirstName(),
      lastName: props.userContext.getLastName(),
      email: props.userContext.getEmail(),
      password: '',
      password2: '',
      notifications: props.userContext.getNotifications() === 1,
      province: props.userContext.getProvince(),
      industry: props.userContext.getIndustry(),
      message: '',
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        province: '',
      },
      subscription: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    Api.subscription().then(
      (response) => this.setState({ subscription: response }) || response
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { appContext } = this.props;
    appContext.clearErrors();
    appContext.clearMessages();
  }

  handleChangeField = (e) => this.setState({ [e.target.name]: e.target.value });

  handleChangeCheckbox = (e) =>
    this.setState({ [e.target.name]: e.target.checked });

  handleValidateField = (e) => {
    const { name, value } = e.target;
    const { errors } = this.state;

    // reset current error message
    errors[name] = '';

    if (name === 'firstName') this.validateFirstName(name, value);
    if (name === 'lastName') this.validateLastName(name, value);
    if (name === 'email') this.validateEmail(name, value);
    if (name === 'industry') this.validateIndustry(name, value);
    if (name === 'password') this.validatePassword(name, value);
    if (name === 'password2') this.validatePassword2(name, value);

    this.setState({ errors });
  };

  validateFirstName = (name, value) =>
    !validator.isEmpty(value) ||
    this.setError(name, 'First Name cannot be blank');

  validateLastName = (name, value) =>
    !validator.isEmpty(value) ||
    this.setError(name, 'Last Name cannot be blank');

  validateEmail = (name, value) => {
    if (validator.isEmpty(value))
      return this.setError(name, 'Email cannot be blank');
    if (!validator.isEmail(value))
      return this.setError(name, 'Email address is invalid');
    return true;
  };

  validatePassword = (name, value) => {
    // empty allowed (password won't change)
    if (validator.isEmpty(value)) return true;
    // minimum length
    if (!validator.isLength(value, { min: 8 }))
      return this.setError(
        name,
        'New Password is too short (minimum is 8 characters)'
      );
    return true;
  };

  validatePassword2 = (name, value) => {
    const { password } = this.state;
    // allow empty IF password is also empty
    if (validator.isEmpty(value) && !validator.isEmpty(password))
      return this.setError(name, 'Confirm new password cannot be blank');
    // passwords musts match
    if (password !== value)
      return this.setError(name, 'Passwords do not match');
    return true;
  };

  validateIndustry = () => true;

  setError = (name, message) => {
    const { errors } = this.state;
    const { appContext } = this.props;
    errors[name] = message;
    appContext.addError(message);
  };

  // use this function after the api call
  setResponseValidationErrors = (respErrors) => {
    const { errors } = this.state;
    if (respErrors.first_name) errors.firstName = respErrors.first_name[0];
    if (respErrors.last_name) errors.lastName = respErrors.last_name[0];
    if (respErrors.email) errors.email = respErrors.email[0];
    if (respErrors.new_password) errors.password = respErrors.new_password[0];
    if (respErrors.password2) errors.password2 = respErrors.password2[0];
    this.setState({ errors });
  };

  validate = () => {
    const { firstName, lastName, email, password, password2 } = this.state;
    const { errors } = this.state;
    this.validateFirstName('firstName', firstName);
    this.validateLastName('lastName', lastName);
    this.validateEmail('email', email);
    this.validatePassword('password', password);
    this.validatePassword2('password2', password2);
    this.setState({ errors });
    return this.isValid();
  };

  isValid = () => {
    const { errors } = this.state;
    return !(
      errors.firstName !== '' ||
      errors.lastName !== '' ||
      errors.email !== '' ||
      errors.password !== '' ||
      errors.password2 !== ''
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      password2,
      notifications,
      province,
      industry,
    } = this.state;
    const valid = this.validate();
    const { appContext, userContext } = this.props;

    if (!valid) return;

    userContext
      .updateProfile(
        firstName,
        lastName,
        email,
        password,
        password2,
        notifications,
        province,
        industry
      )
      .then((data) => {
        if (data.success) {
          // reset form
          appContext.addMessage(data.message);
          this.setState({
            success: true,
            message: data.message,
            firstName: userContext.getFirstName(),
            lastName: userContext.getLastName(),
            email: userContext.getEmail(),
            password: '',
            password2: '',
            notifications: userContext.getNotifications() === 1,
            province: userContext.getProvince(),
            industry: userContext.getIndustry(),
          });
        } else {
          appContext.addError(data.message);
          this.setState({
            success: false,
            // message: data.message,
          });
          this.setResponseValidationErrors(data.errors);
        }
      });
  };

  render() {
    const hasError = (name) => {
      const { errors } = this.state;
      return errors[name];
    };

    const errorMessage = (name) => {
      const { errors } = this.state;
      return errors[name];
    };

    const {
      subscription,
      province,
      firstName,
      lastName,
      email,
      password,
      password2,
      industry,
      notifications,
    } = this.state;
    const { userContext } = this.props;

    return (
      <form className="edit-profile-form" onSubmit={this.handleSubmit}>
        {/* 
        {this.state.message &&
          <div className={"alert "+(this.state.success ? "alert-success" : "alert-danger")}>{ this.state.message }</div>
        }
        */}

        <div className="row">
          <div className="col form-group">
            <label htmlFor="firstName" className="control-label">
              Your First Name
            </label>
            <input
              type="text"
              name="firstName"
              className={`form-control ${
                hasError('firstName') ? 'is-invalid' : ''
              }`}
              value={firstName}
              onChange={this.handleChangeField}
              onBlur={this.handleValidateField}
              placeholder="First Name"
            />
            {/* 
            <div className="invalid-feedback">{ errorMessage('firstName') }</div>
              */}
          </div>
          <div className="col form-group">
            <label htmlFor="lastName" className="control-label">
              Your Last Name
            </label>
            <input
              type="text"
              name="lastName"
              className={`form-control ${
                hasError('lastName') ? 'is-invalid' : ''
              }`}
              value={lastName}
              onChange={this.handleChangeField}
              onBlur={this.handleValidateField}
              placeholder="Last Name"
            />
            {/* 
            <div className="invalid-feedback">{ errorMessage('lastName') }</div>
              */}
          </div>
        </div>

        <div className="row">
          <div className="col form-group">
            <label htmlFor="email" className="control-label">
              Your Email
            </label>
            <input
              type="text"
              name="email"
              className={`form-control ${
                hasError('email') ? 'is-invalid' : ''
              }`}
              value={email}
              onChange={this.handleChangeField}
              onBlur={this.handleValidateField}
              placeholder="Email Address"
            />
            {/* 
            <div className="invalid-feedback">{ errorMessage('firstName') }</div>
              */}
          </div>
          <div className="col">
            <div className="form-group" style={{ marginBottom: '8px' }}>
              <label htmlFor="password" className="control-label">
                Change your password
              </label>
              <input
                type="password"
                name="password"
                className={`form-control ${
                  hasError('password') ? 'is-invalid' : ''
                }`}
                value={password}
                onChange={this.handleChangeField}
                onBlur={this.handleValidateField}
              />
              {/* 
              <div className="invalid-feedback">{ errorMessage('password') }</div>
              */}
            </div>
            <div className="form-group">
              {password && (
                <>
                  {/* 
                  <label htmlFor="password2" className="control-label">Confirm new password</label>
                  */}
                  <input
                    type="password"
                    name="password2"
                    className={`form-control ${
                      hasError('password2') ? 'is-invalid' : ''
                    }`}
                    value={password2}
                    placeholder="Confirm new password"
                    onChange={this.handleChangeField}
                    onBlur={this.handleValidateField}
                  />
                  {/* 
                  <div className="invalid-feedback">{ errorMessage('password2') }</div>
                  */}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col form-group">
            <label htmlFor="province" className="control-label">
              Default Location
            </label>
            <select
              type="text"
              name="province"
              className={`form-control ${
                hasError('province') ? 'is-invalid' : ''
              }`}
              value={province}
              onChange={this.handleChangeField}
              onBlur={this.handleValidateField}
              placeholder="Province/State"
            >
              <option value=""> </option>
              {userContext.getProvinces().map((i) => (
                <option key={i.key} value={i.key}>
                  {i.value}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">{errorMessage('province')}</div>
          </div>
          <div className="col form-group">
            <label htmlFor="industry" className="control-label">
              Your Industry
            </label>
            <input
              type="text"
              name="industry"
              className={`form-control ${
                hasError('industry') ? 'is-invalid' : ''
              }`}
              value={industry}
              onChange={this.handleChangeField}
              onBlur={this.handleValidateField}
              placeholder=""
            />
          </div>
        </div>

        <div className="form-group text-center">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              name="notifications"
              className="custom-control-input"
              checked={notifications}
              onChange={this.handleChangeCheckbox}
              id="notifications"
            />
            <label className="custom-control-label" htmlFor="notifications">
              Notify me via email when I receive a new answer
            </label>
          </div>
        </div>

        <div className="form-cta text-center">
          <SubscriptionInfo subscription={subscription} />
        </div>

        <div className="form-actions">
          <div className="radial-shadow top front" />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Save changes <i className="answerbar-angle-right" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}
