import { Api as Axios } from '../../Api';

const Questions = {
  create(data) {
    return Axios.post('/question/create', data)
      .then((response) => response.data)
      .catch((error) => error);
  },

  update(id, data) {
    return Axios.put(`/question/${id}`, data)
      .then((response) => response.data)
      .catch((error) => error);
  },

  drafts() {
    return Axios.get('/question/drafts')
      .then((response) => response.data.data)
      .catch((error) => error);
  },

  list(data) {
    return Axios.get('/question/list', data)
      .then((response) => response.data.data)
      .catch((error) => error);
  },

  delete(id) {
    return Axios.delete(`/question/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  },

  emptyTrash() {
    return Axios.delete('/question/trash')
      .then((response) => response.data)
      .catch((error) => error);
  },

  shareList(id) {
    return Axios.get(`/question/${id}/share`)
      .then((response) => response.data)
      .catch((error) => error);
  },

  share(id, user_ids) {
    return Axios.put(`/question/${id}/share`, { user_ids })
      .then((response) => response.data)
      .catch((error) => error);
  },

  flag(id, name) {
    return Axios.put(`/question/${id}/flag`, { name })
      .then((response) => response.data)
      .catch((error) => error);
  },
};

const QuestionAttachment = {
  // takes a 'file'
  // upload: function(question_id, data) {
  //   return Axios.post(`/question/${question_id}/file`, data)
  //     .then(response => console.log('upload attachment', response) || response.data)
  //     .catch(error => console.log('create error',error))
  // },

  upload(question_id, data, file, handleUpdateProgress) {
    return Axios.post(`/question/${question_id}/file`, data, {
      onUploadProgress: (progressEvent) => {
        const progress = `${Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )}%`;
        handleUpdateProgress(file, progress);
        return progress;
      },
    }).then((response) => response.data);
  },

  retrieve(question_id, id) {
    return Axios.get(`/question/${question_id}/file/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  },

  redirect(question_id, id) {
    return Axios.get(`/question/${question_id}/file/${id}/redirect`, {
      responseType: 'blob',
    })
      .then((response) => response)
      .catch((error) => error);
  },

  delete(question_id, id) {
    return Axios.delete(`/question/${question_id}/file/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  },
};

const Organization = {
  retrieve() {
    return Axios.get('/organization/retrieve')
      .then((response) => response.data)
      .catch((error) => error);
  },

  invite(email) {
    const data = { email };
    return Axios.post('/organization/invite', data)
      .then((response) => response.data)
      .catch((error) => error);
  },

  sharing(organization_id, member_id, bool) {
    const data = {
      member_id,
      sharing: bool ? 1 : 0,
    };
    return Axios.put(`/organization/${organization_id}/sharing`, data)
      .then((response) => response.data)
      .catch((error) => error);
  },

  admin(organization_id, member_id, bool) {
    const data = {
      member_id,
      admin: bool ? 1 : 0,
    };
    return Axios.put(`/organization/${organization_id}/admin`, data)
      .then((response) => response.data)
      .catch((error) => error);
  },

  revoke(organization_id, member_id) {
    const data = { member_id };
    return Axios.put(`/organization/${organization_id}/revoke`, data)
      .then((response) => response.data)
      .catch((error) => error);
  },
};

const Content = {
  retrieve(key) {
    return Axios.get(`/content/${key}`)
      .then((response) => response.data)
      .catch((error) => error);
  },
};

const Faq = {
  list() {
    return Axios.get('/faq/list')
      .then((response) => response.data.data)
      .catch((error) => error);
  },
};

const Testimonial = {
  list() {
    return Axios.get('/testimonial/list')
      .then((response) => response.data.data)
      .catch((error) => error);
  },
};

const PressRelease = {
  list() {
    return Axios.get('/pressRelease/list')
      .then((response) => response.data.data)
      .catch((error) => error);
  },
};

const Blog = {
  list() {
    return Axios.get('/blog/list')
      .then((response) => response.data.data)
      .catch((error) => error);
  },
};

export const Api = {
  Questions,
  QuestionAttachment,
  Organization,
  Content,
  Faq,
  Testimonial,
  PressRelease,
  Blog,
};

export default {
  fetchOrganization() {
    return Axios.get('/api/customer/organization/mine')
      .then((response) => response.data)
      .catch((error) => error);
  },
};
