import React from 'react';
import Api from '../Api';

export default class Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: null,
    };
  }

  componentDidMount() {
    // load current subscription
    this.refreshSubscription();
  }

  componentWillUnmount() {}

  refreshSubscription() {
    Api.subscription().then(
      (response) => this.setState({ subscription: response }) || response
    );
  }

  render() {
    const { subscription } = this.state;

    return (
      <div className="text-center">
        <h1>Your Subscription</h1>
        <div className="divider" />

        {subscription && subscription.id && (
          <div>
            <p>You are currently subscribed to plan: {subscription.title}</p>
            <p>
              {subscription.frequncy === 'y' ? 'Yearly' : 'Monthly'} Fee:{' '}
              {subscription.price}
            </p>
            <p>{subscription.description}</p>
            {/* 
            <Link to="/plans" className="underlined">Change Plan</Link>
            */}
            {/* 
              <Link to="">Cancel Plan</Link>
            */}
          </div>
        )}
      </div>
    );
  }
}
