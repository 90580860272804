import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Menu = ({ open = false, onDidClose, children }) => {
  const { pathname } = useLocation();
  const [curPath] = useState(pathname);
  const handleClose = () => {
    if (onDidClose) onDidClose();
  };

  useEffect(() => {
    if (pathname !== curPath) handleClose();
  }, [pathname]);

  return (
    <div
      className={`menu-wrapper ${open ? 'active d-block' : 'in-active d-none'}`}
    >
      <div className="inner">
        <div className="menu-background" />
        <div className="menu-view">
          <div className="inner d-flex align-items-stretch">
            <div className="menu-header pt-safe d-flex">
              <div className="d-flex ml-auto">
                <div className="menu-close-button" onClick={handleClose}>
                  <div className="inner">
                    <div className="close-bars">
                      <div className="close-bar" />
                      <div className="close-bar" />
                    </div>
                    <div className="text-label">Close</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center flex-fill">
              <div className="menu-body">
                <>
                  {/* <div className="menu-background-close" onClick={handleClose} /> */}
                  {children}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
