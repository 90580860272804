import { Capacitor, Plugins } from '@capacitor/core';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { parse } from 'querystringify';
import React, { Component } from 'react';
import store from 'store';
import AppApi from '../Api';
import api from './Api';
import defaultPhoto from './images/defaultPhoto.png';

const { PushNotifications } = Plugins;
const { isNative } = Capacitor;

const UserContext = React.createContext();
const { Consumer, Provider } = UserContext;

const { App } = Plugins;

// Simple function to get nested properties
// Especially helpful for undefined nested objects.
const get = (path, object) =>
  path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);

// Helper functions
const getEmail = (object) => get(['currentUser', 'email'], object);
const getName = (object) => get(['currentUser', 'profile', 'name'], object);
const getFirstName = (object) =>
  get(['currentUser', 'profile', 'first_name'], object);
const getLastName = (object) =>
  get(['currentUser', 'profile', 'last_name'], object);
const getThumb = (object, size = 'large') =>
  get(['currentUser', 'profile', 'thumbs', size], object);
const getNotifications = (object) =>
  get(['currentUser', 'notifications'], object) || 0;
const getProvince = (object) =>
  get(['currentUser', 'profile', 'province'], object) || '';
const getIndustry = (object) =>
  get(['currentUser', 'profile', 'industry'], object) || '';

export const UserConsumer = ({ children }) => <Consumer>{children}</Consumer>;

export class UserProvider extends Component {
  tokenTimer;

  constructor(props) {
    super(props);
    // authEmail: Store the email in the context to
    // use it across pages (login, signup, forgot..)
    this.state = {
      initializing: true,
      loading: false,
      authEmail: '',
      currentUser: store.get('currentUser') || null,
      isAuthenticated: store.get('isAuthenticated') || false,
      provinces: [],
      inviteCode: '',
      // isLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // this.setLoading(true);

    this.addListeners();

    const { invite_email, invite_code } = parse(document.location.search);
    const inviteEmail = invite_email;
    const inviteCode = invite_code;

    if (inviteEmail && inviteCode) {
      this.setState({
        authEmail: inviteEmail,
        inviteCode,
      });
    }

    this.handleRefresh().then((response) => {
      this.setState({ initializing: false });
      return response;
    });

    AppApi.getProvinces().then((response) =>
      this.setState({ provinces: response })
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.removeListeners();
  }

  // Authenticate the user based on email and password.
  // Currently not using local storage...
  // Returns either the user data, empty data, or an error
  // Stores user data in the user context state.
  authenticate = (email, password) => {
    const { inviteCode } = this.state;
    return api
      .authenticate(email, password, inviteCode)
      .then((response) => {
        const timeout = response.success ? 400 : 0;

        if (response.success) this.displayWelcome(1500);

        // delay slightly for welcome animation to appear first
        setTimeout(() => {
          if (this._isMounted) this.setAuthUser(response.data);
        }, timeout);
        return response;
      })
      .catch((error) => error);
  };

  register = (email, password, referralCode, terms, recaptchaValue) => {
    const { inviteCode } = this.state;
    return api
      .register(
        email,
        password,
        referralCode,
        terms,
        inviteCode,
        recaptchaValue
      )
      .then((response) => {
        const timeout = response.success ? 400 : 0;

        if (response.success) this.displayWelcome(1500);

        // delay slightly for welcome animation to appear first
        setTimeout(() => {
          if (this._isMounted) this.setAuthUser(response.data);
        }, timeout);
        return response;
      })
      .catch((error) => error);
  };

  // Refresh the current user session
  handleRefresh = () => {
    const refreshToken = store.get('refreshToken');
    return api
      .refreshToken(refreshToken)
      .then((jwt) => {
        this.setAuthUser(jwt);
        return jwt;
      })
      .catch((err) => {
        this.setAuthUser(null);
        return err;
      });
  };

  // create an event handle to attach to onClick on links
  handleLogout = (e) => {
    e.preventDefault();
    this.logout();
  };

  // actual callback to log the user out
  logout = () => {
    store.clearAll();
    return api.logout().then(() => {
      this.setAuthUser(null);
      this.setState({
        isAuthenticated: false,
        currentUser: null,
      });
    });
  };

  resetPassword = (email, password, password2, token) => {
    return api
      .resetPassword(email, password, password2, token)
      .then((response) => {
        if (response?.data) this.setAuthUser(response.data);
        return response;
      });
  };

  // avoid the data flash when first loading
  // there are better ways to implement this...
  // eg from the user context / app root / component root, add a
  // conditional class based on setLoading...
  // setLoading = (bool) => {
  //   const bodyClassList = document.getElementsByTagName('body')[0].classList;
  //   bool ? bodyClassList.add('AccountIsLoading') : bodyClassList.remove('AccountIsLoading');
  //   this.setState({ isLoading: bool });
  // }

  updateProfile = (
    firstName,
    lastName,
    email,
    password,
    password2,
    notifications,
    province,
    industry
  ) => {
    // rename variables for server format
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password2,
      notifications,
      province,
      industry,
    };
    return api.update(data).then((response) => {
      if (response.success) this.setCurrentUser(response.data, true);
      return response;
    });
  };

  uploadPhoto = (file) => {
    const formData = new FormData();
    formData.append('image', file, file.name);

    if (!file) return false;

    // @todo: type checking

    return api.setPhoto(formData).then((response) => {
      this.setCurrentUser(response.data, true);
      return response;
    });
  };

  setAuthUser = (data = null) => {
    this.setState({
      accessToken: data?.accessToken || null,
      refreshToken: data?.refreshToken || null,
    });
    store.set('accessToken', data?.accessToken || null);
    store.set('refreshToken', data?.refreshToken || null);
    this.refreshTokenTimer();
    this.setCurrentUser(data?.user, !!data?.accessToken);
    this.registerPushToken();
  };

  setCurrentUser = (data = null, authenticated = false) => {
    this.setState({
      currentUser: data,
      isAuthenticated: !!authenticated, // !!(expression) returns bool
    });
    store.set('currentUser', data);
    store.set('isAuthenticated', !!authenticated);
    // this.setLoading(false);
    return data;
  };

  registerPushToken = () => {
    // Keep push registration token in sync with current user
    // TODO optimize so it is only called when user has changed
    if (isNative) PushNotifications.register();
  };

  getTimeUntilTokenRefresh = () => {
    const accessToken = store.get('accessToken');
    if (!accessToken) return 0;

    const { exp } = jwtDecode(accessToken);
    const now = moment().unix();
    const bufferTime = 60; // buffer (in seconds)
    const nextRefresh = exp - now - bufferTime;
    const time = Math.max(nextRefresh * 1000, 0);
    return time;
  };

  refreshTokenTimer = () => {
    // Avoid multiple timers, clear first if previously set
    if (this.tokenTimer) clearTimeout(this.tokenTimer);
    if (store.get('refreshToken')) {
      this.tokenTimer = setTimeout(() => {
        this.handleRefresh();
      }, this.getTimeUntilTokenRefresh());
    }
  };

  addListeners = () => {
    // focus fires when the page becomes active (eg clicking into browser)
    window.addEventListener('focus', this.onWindowFocus);

    // appStateChange fires when the tab becomes active, app is opened/brought to focus
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) this.onWindowFocus();
    });
  };

  removeListeners = () => {
    window.removeEventListener('focus', this.onWindowFocus);
  };

  onWindowFocus = () => {
    // when the user resumes focus of the window/tab
    // reset the refresh token timer
    this.refreshTokenTimer();
  };

  handleChangeAuthEmail = (value) => this.setState({ authEmail: value });

  getDisplayName = () => getFirstName(this.state) || getEmail(this.state);

  getCurrentUser = () => {
    const { currentUser } = this.state;
    return currentUser;
  };

  getFirstName = () => getFirstName(this.state) || '';

  getLastName = () => getLastName(this.state) || '';

  getName = () => getName(this.state);

  getEmail = () => getEmail(this.state);

  getThumb = (size = 'large') => getThumb(this.state, size) || defaultPhoto;

  getDefaultPhoto = () => defaultPhoto;

  getNotifications = () => getNotifications(this.state);

  getProvince = () => getProvince(this.state);

  getProvinces = () => {
    const { provinces } = this.state;
    return provinces;
  };

  getIndustry = () => getIndustry(this.state);

  displayWelcome = (timeout) => {
    if (!this._isMounted) return;

    this.setState({ loading: true });

    setTimeout(() => {
      if (this._isMounted) this.setState({ loading: false });
    }, timeout);
  };

  render() {
    const { initializing, isAuthenticated } = this.state;
    const { children } = this.props;
    return (
      <Provider
        value={{
          state: this.state,
          handleLogout: this.handleLogout,
          authenticate: this.authenticate,
          register: this.register,
          logout: this.logout,
          resetPassword: this.resetPassword,
          updateProfile: this.updateProfile,
          uploadPhoto: this.uploadPhoto,
          currentUser: this.getCurrentUser(),
          displayName: this.getDisplayName(),
          getThumb: this.getThumb,
          getDefaultPhoto: this.getDefaultPhoto,
          getFirstName: this.getFirstName,
          getLastName: this.getLastName,
          getName: this.getName,
          getEmail: this.getEmail,
          getNotifications: this.getNotifications,
          getProvince: this.getProvince,
          getProvinces: this.getProvinces,
          getIndustry: this.getIndustry,
          handleChangeAuthEmail: this.handleChangeAuthEmail,
          refresh: () => this.handleRefresh,
          isAuthenticated,
          initializing,
        }}
      >
        {!initializing && children}
      </Provider>
    );
  }
}

export default UserContext;
