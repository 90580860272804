import React from 'react';

export const Threads = ({ children }) => (
  <div className="threads">{children}</div>
);

export class Thread extends React.Component {
  constructor(props) {
    super(props);

    this.outerRef = React.createRef();
    this.innerRef = React.createRef();
  }

  componentDidUpdate() {
    // Set the container height (necessary for height animation)
    // The inner height might change, so update it when there are changes.
    this.outerRef.current.style.height = `${this.innerRef.current.clientHeight}px`;
  }

  render() {
    const { props } = this;
    return (
      <div
        ref={this.outerRef}
        className={`transition-item thread ${
          props.collapsed ? 'collapsed' : 'expanded'
        } ${props.removed ? 'removed' : ''}`}
      >
        <div ref={this.innerRef} className="inner">
          {props.children}
        </div>
      </div>
    );
  }
}

// export const Thread = props =>
//   <div className={"transition-item thread " + (props.collapsed ? 'collapsed' : 'expanded')}>
//       <div className="inner">
//         {props.children}
//       </div>
//   </div>
