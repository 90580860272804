import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { Api } from '../Utils/Api';
import AppApi from '../../Api';
import { LoadingBar } from '../Partials';

import UserContext from '../../User/Context';

const InviteForm = ({
  handleSubmit,
  hasError,
  inviteEmail,
  handleChangeField,
  handleValidateField,
  errorMessage,
}) => (
  <form className="invite-form" onSubmit={handleSubmit}>
    <div className="d-flex">
      <div className="flex-fill">
        <div className="form-group">
          <input
            type="text"
            name="inviteEmail"
            className={`form-control ${
              hasError('inviteEmail') ? 'is-invalid' : ''
            }`}
            value={inviteEmail}
            onChange={handleChangeField}
            onBlur={handleValidateField}
            placeholder="Email Address"
            autoComplete="off"
          />
          <div className="invalid-feedback">{errorMessage('inviteEmail')}</div>
        </div>
      </div>
      <div className="ml-auto">
        <button
          type="submit"
          className={`btn btn-primary ${inviteEmail ? 'visible' : 'hidden'}`}
        >
          Invite <i className="answerbar-angle-right" />
        </button>
      </div>
    </div>
  </form>
);

const TeamList = (props) => {
  const { subscription, answerBar } = props;
  const { currentUser } = useContext(UserContext);
  const { organization } = answerBar.state;
  const { isAdmin } = organization || {};

  if (!organization) return <div />;

  const maxUsers = subscription && subscription.id ? subscription.max_users : 0;

  return (
    <div className="team-list">
      <table className="table">
        <thead>
          <tr>
            <th>
              {organization.members.length} out of {maxUsers} seats used
            </th>
            <th className="text-center" data-toggle="tooltip" title="">
              Allow Sharing
            </th>
            <th className="text-center" data-toggle="tooltip" title="">
              Admin
            </th>
            <th className="text-center"> </th>
          </tr>
        </thead>
        <tbody>
          {organization.members.map((member) => (
            <tr key={member.id}>
              <td>
                <span
                  className={`account-status ${
                    member.isActive ? 'active' : 'inactive'
                  }`}
                />
                {member.isActive === 1 && (
                  <>
                    {member.user.profile?.name && (
                      <span className="account-name">
                        {member.user.profile.name}
                      </span>
                    )}
                  </>
                )}
                <span className="account-email">
                  {member.isActive === 1 && member.user.email}
                  {member.isActive === 0 && member.inviteEmail}
                  {member.isActive === 0 && (
                    <span className="pending">(pending...)</span>
                  )}
                </span>
              </td>
              <td className="text-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name={`share[${member.id}]`}
                    className="custom-control-input"
                    checked={member.allow_sharing === '1' ? 1 : 0}
                    onChange={(e) => props.handleChange(e, member)}
                    id={`share[${member.id}]`}
                    disabled={!isAdmin}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`share[${member.id}]`}
                  >
                    &nbsp;
                  </label>
                </div>
              </td>
              <td className="text-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name={`admin[${member.id}]`}
                    className="custom-control-input"
                    checked={member.role === 'admin' ? 1 : 0}
                    onChange={(e) => props.handleChangeAdmin(e, member)}
                    id={`admin[${member.id}]`}
                    disabled={!isAdmin || member.user.id === currentUser.id}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`admin[${member.id}]`}
                  >
                    &nbsp;
                  </label>
                </div>
              </td>
              <td className="text-center">
                {!!isAdmin && member.user.id !== currentUser.id && (
                  <div
                    className="push-item"
                    onClick={(e) => props.handleDelete(e, member)}
                  >
                    <span className="answerbar-trash" />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default class ManageUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inviteEmail: '',
      errors: {
        inviteEmail: '',
      },
      subscription: null,
      loading: false,
    };
  }

  componentDidMount() {
    const { answerBar } = this.props;
    answerBar.refreshOrganization();
    this.refreshSubscription();
  }

  componentWillUnmount() {
    const { appContext } = this.props;
    appContext.clearErrors();
  }

  handleChangeField = (e) => this.setState({ inviteEmail: e.target.value });

  handleValidateField = (e) =>
    this.validateField(e.target.name, e.target.value);

  errorMessage = (name) => {
    const { errors } = this.state;
    return errors[name];
  };

  hasError = (name) => {
    const { errors } = this.state;
    return errors[name];
  };

  validateField = (name, value, allowEmpty = true) => {
    const { appContext } = this.props;
    const { errors } = this.state;
    appContext.clearErrors();

    // reset current error message
    errors[name] = '';

    // validate email is not empty
    if (
      allowEmpty &&
      name === 'inviteEmail' &&
      !validator.isEmpty(value) &&
      !validator.isEmail(value)
    )
      this.setError(errors, name, 'Please enter a valid email address');

    // validate email
    if (!allowEmpty && name === 'inviteEmail' && !validator.isEmail(value))
      this.setError(errors, name, 'Please enter a valid email address');

    this.setState({ errors });
  };

  // helper function to make validation code simpler
  setError = (errors, name, message) => {
    // eslint-disable-next-line no-param-reassign
    errors[name] = message;
  };

  validate = () => {
    const { inviteEmail } = this.state;

    this.validateField('inviteEmail', inviteEmail, false);

    if (this.hasError('inviteEmail')) return false;

    return true;
  };

  handleSubmit = (e) => {
    const { appContext, answerBar } = this.props;
    const { errors } = this.state;
    e.preventDefault();
    appContext.clearErrors();

    if (!this.validate()) return false;

    if (this._submitted) return false;

    this.setState({ loading: true });
    this._submitted = true;
    const { inviteEmail } = this.state;

    return Api.Organization.invite(inviteEmail).then((response) => {
      this._submitted = false;

      if (response.success === 0) {
        this.setState({ loading: false });

        const organization_errors = response.errors.organization;
        const invite_email_errors = response.errors.invite_email;

        if (organization_errors && organization_errors.length > 0)
          appContext.addError(organization_errors[0]);
        else appContext.addError(response.message);

        if (invite_email_errors && invite_email_errors.length > 0)
          this.setState({
            errors: {
              ...errors,
              inviteEmail: invite_email_errors[0],
            },
          });
      }

      if (response.success === 1) {
        this.setState({ inviteEmail: '' });
        answerBar.refreshOrganization().then((resp) => {
          this.setState({ loading: false });
          return resp;
        });
      }

      return response;
    });
  };

  handleChangeSharing = (e, member) => {
    const { appContext, answerBar } = this.props;
    appContext.clearErrors();
    // @todo prevent multiple clicks / force waiting for a response
    // @todo change the value instantly (avoid response lag / multiple clicks)
    // e.preventDefault();

    this.setState({ loading: true });
    const { organization } = answerBar.state;
    Api.Organization.sharing(organization.id, member.id, e.target.checked).then(
      (response) => {
        if (!response.success) appContext.addError(response.error);

        answerBar.refreshOrganization().then((resp) => {
          this.setState({ loading: false });
          return resp;
        });
        return response;
      }
    );
  };

  handleChangeAdmin = (e, member) => {
    const { appContext, answerBar } = this.props;
    appContext.clearErrors();
    this.setState({ loading: true });
    const { organization } = answerBar.state;
    Api.Organization.admin(organization.id, member.id, e.target.checked).then(
      (response) => {
        if (!response.success) appContext.addError(response.error);

        answerBar.refreshOrganization().then((resp) => {
          this.setState({ loading: false });
          return resp;
        });
        return response;
      }
    );
  };

  handleDeleteMember = (e, member) => {
    const { appContext, answerBar } = this.props;
    const { organization } = answerBar.state;
    this.setState({ loading: true });
    Api.Organization.revoke(organization.id, member.id).then((response) => {
      this.setState({ loading: false });

      if (!response.success) appContext.addError(response.error);

      answerBar.refreshOrganization().then((resp) => {
        this.setState({ loading: false });
        return resp;
      });
      return response;
    });
  };

  refreshSubscription() {
    AppApi.subscription().then(
      (response) => this.setState({ subscription: response }) || response
    );
  }

  render() {
    const { answerBar } = this.props;
    const { organization } = answerBar.state;
    const { loading, subscription, inviteEmail } = this.state;

    return (
      <div className="manage-users">
        <div className="text-center">
          <h1>Manage Users</h1>
          <div className="subtitle">
            Invite or delete users and give permission to share answers within
            team
          </div>
        </div>

        {loading && <LoadingBar type="status-bar" />}

        {(!organization || organization.hasSeatsAvailable) && (
          <div>
            <div className="radial-shadow front top" />

            <InviteForm
              inviteEmail={inviteEmail}
              handleChangeField={this.handleChangeField}
              handleValidateField={this.handleValidateField}
              errorMessage={this.errorMessage}
              hasError={this.hasError}
              handleSubmit={this.handleSubmit}
            />
          </div>
        )}

        <div className="radial-shadow front top" />

        <TeamList
          answerBar={answerBar}
          handleChange={this.handleChangeSharing}
          handleChangeAdmin={this.handleChangeAdmin}
          handleDelete={this.handleDeleteMember}
          subscription={subscription}
        />

        {organization && organization.hasSeatsAvailable === false && (
          <div className="upgrade-cta">
            <Link to="/plans" className="underlined">
              Need more seats? Upgrade your subscription
            </Link>
          </div>
        )}
      </div>
    );
  }
}
