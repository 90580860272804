import Glide from '@glidejs/glide'; // carousel
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TypeIt from 'typeit';
import { EmployeeAvatar, UserAvatar } from '../../User/Components/Avatar';
import { Api as UtilApi } from '../Utils/Api';
import HowItWorks from './HowItWorks';

const shuffleArray = (array) => {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const sampleQuestions = [
  'Do we need to provide a specific amount of notice on scheduled OT shifts? What if an employee refuses to work a scheduled OT shift.',
  "A long-term employee of 17 years plus, has repeatedly insisted his experience does not commensurate with his salary. His daily tasks have shifted to ones which we could replace for $20K less, but he insists he's underpaid, says he's done his own homework to prove he's paid fairly and challenges us to repeatedly show him proof/evidence that we're right. His attitude is borderline belligerent, it's a classic work to rule situation.. Not sure how to proceed..",
  'What is the notice period required to change a work schedule?',
  "Do I need a release letter for an employee who's resigning?",
  "We have an employee whose working remotely from her home and  has been with us for 12 years. We currently don't have enough work to use her and would like to lay her off. Are we liable for severance if we lay off an employee?",
  'What should be included in an off boarding template?',
  'We believe that one of our managers is speaking poorly of others and spreading rumours about their teammates. It is becoming clear this employee is toxic to our culture.  What would be the best way to handle this situation and whether the employment standards have anything to say about it.',
  'Hi there! Question around compensation. For staff on salary, if they work more than 8 hours a day and/or 40 hours a week, do I need to track overtime',
  "My office has a 'no dog policy'. Can I bring my goldfish to work?",
  "I am curious what the standard “protocol” is when an employee gives 2 weeks' notice but is no longer committed and making their full shifts.",
  'Are there laws relating to expenses in Canada. Do I need an expense policy? Do you have one?',
  'Can we legally restrict our employees from working another job while being employed with us full-time? Is there a wording that can be added to an employment offer or policy manual?',
];

// reorder the array
shuffleArray(sampleQuestions);

export const TypedItems = ({ items }) => {
  const elem = useRef(null);

  const initTyping = () => {
    const typeIt = new TypeIt(elem.current, {
      speed: 40,
      startDelay: 1000,
      deleteSpeed: 6,
      loop: true,
    });

    // add items
    items.map((item) => {
      typeIt
        .type(item)
        .pause(2500)
        // .options({speed: 100, deleteSpeed: 10})
        // .delete(items[i].length)
        .empty()
        .pause(0);
      return item;
    });

    typeIt.go();
  };

  useEffect(() => {
    initTyping();
  }, []);

  return (
    <div className="typed-items">
      <div className="inner">
        <div className="typed-item" ref={elem} />
      </div>
    </div>
  );
};

export const SampleQuestion = () => {
  return (
    <div className="sample-wrapper">
      <div className="sample-nav">
        Questions <em>(1)</em>
      </div>

      <div className="sample with-arrow fadeout-right">
        <div className="question">
          <div className="inner">
            <UserAvatar />
            <p className="heading">
              Asked by Bob, {moment().format('MMM D, YYYY, h:mmA')}
            </p>
            <p>{sampleQuestions[sampleQuestions.length - 1]}</p>
            {/* 
            <TypedItems items={sampleQuestions} />
              */}
          </div>
        </div>
        <div
          className="radial-shadow top divider front"
          style={{ opacity: '.2' }}
        />
        <div className="answer d-flex flex-column text-center">
          <EmployeeAvatar />
          <div className="heading">Awaiting Answer...</div>
          <p>
            Want to know when it&apos;s ready?{' '}
            <span className="text-bold underlined">
              Edit your notifications settings
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

const SampleDraft = () => {
  useEffect(() => {
    new Glide('.glide.sample-draft-carousel').mount();
  }, []);

  return (
    <div className="sample-wrapper">
      <div className="sample-nav">
        Drafts <em>(1)</em>
      </div>

      <div className="sample with-arrow fadeout-right">
        <div className="draft">
          <div className="inner">
            <div className="toolbar d-flex flex-row flex-wrap justify-content-center">
              <div className="editor-actions">
                <div id="toolbar-387" className="ql-toolbar ql-snow">
                  <button className="btn ql-bold" type="button">
                    <span style={{ fontWeight: '600' }}>B</span>
                  </button>
                  <button className="btn ql-italic" type="button">
                    <span style={{ fontStyle: 'italic' }}>I</span>
                  </button>
                  <button className="btn ql-underline" type="button">
                    <span style={{ textDecoration: 'underline' }}>U</span>
                  </button>
                  <button className="btn ql-list" value="ordered" type="button">
                    <i className="material-icons">format_list_numbered</i>
                  </button>
                  <button className="btn ql-list" value="bullet" type="button">
                    <i className="material-icons">format_list_bulleted</i>
                  </button>
                  <button className="btn ql-indent" value="-1" type="button">
                    <i className="material-icons">format_indent_decrease</i>
                  </button>
                  <button className="btn ql-indent" value="+1" type="button">
                    <i className="material-icons">format_indent_increase</i>
                  </button>
                  <button type="button" className="btn">
                    <i className="material-icons">attach_file</i>
                  </button>
                </div>
              </div>
            </div>
            <div className="radial-shadow top front" />
            {/* 
            <UserAvatar />
            <p className="heading">Asked by john@example.com, {moment().format('MMM D, YYYY, h:mmA')}</p>
            <p>{sampleQuestions[0]}</p>
            */}
            <div className="pt-4">
              <div className="sample-draft-carousel d-flex flex-stretch glide glide-dark">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <div className="glide__slide">
                      <TypedItems items={sampleQuestions} />
                    </div>
                    {sampleQuestions &&
                      sampleQuestions.map((item, index) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          className="glide__slide"
                        >
                          {item}
                        </div>
                      ))}
                  </ul>
                </div>

                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    type="button"
                    className="glide__arrow glide__arrow--left"
                    data-glide-dir="<"
                  >
                    <span className="answerbar-angle-left" />
                  </button>
                  <button
                    type="button"
                    className="glide__arrow glide__arrow--right"
                    data-glide-dir=">"
                  >
                    <span className="answerbar-angle-right" />
                  </button>
                </div>

                <div className="glide__bullets" data-glide-el="controls[nav]">
                  {sampleQuestions &&
                    sampleQuestions.map((item, k) => (
                      <button
                        type="button"
                        // eslint-disable-next-line react/no-array-index-key
                        key={k}
                        className="glide__bullet"
                        data-glide-dir={`=${k}`}
                      >
                        {' '}
                      </button>
                    ))}
                </div>
              </div>
            </div>

            <div className="form-actions">
              <div className="radial-shadow top front" />
              <button type="submit" className="btn btn-primary">
                Ask Question <i className="answerbar-angle-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Testimonials = () => {
  const [items, setItems] = useState([]);
  let isMounted = false;

  useEffect(() => {
    isMounted = true;

    UtilApi.Testimonial.list().then((response) => {
      if (isMounted) {
        setItems(response);
        new Glide('.glide.testimonials').mount();
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="row">
      <div className="col col-sm col-left">
        <div className="testimonials-wrapper font-serif">
          <div className="testimonials d-flex flex-stretch glide">
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {items &&
                  items.map((item) => (
                    <li key={item.id} className="glide__slide">
                      <div className="testimonial d-flex align-items-center justify-content-center">
                        <div className="inner">
                          <div className="quote">&quot;{item.quote}&quot;</div>
                          <div className="name">– {item.name}</div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="glide__arrows" data-glide-el="controls">
              <button
                type="button"
                className="glide__arrow glide__arrow--left"
                data-glide-dir="<"
              >
                <span className="answerbar-angle-left" />
              </button>
              <button
                type="button"
                className="glide__arrow glide__arrow--right"
                data-glide-dir=">"
              >
                <span className="answerbar-angle-right" />
              </button>
            </div>

            <div className="glide__bullets" data-glide-el="controls[nav]">
              {items &&
                items.map((item, k) => (
                  <button
                    type="button"
                    // eslint-disable-next-line react/no-array-index-key
                    key={k}
                    className="glide__bullet"
                    data-glide-dir={`=${k}`}
                  >
                    {' '}
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col col-sm col-right">
        <div className="copy">
          <h2>See what others have to say</h2>
          <p>
            AnswerBar has helped fill the HR gap for individuals and companies
            by answering hundreds of questions. Here&apos;s what a few of them
            have to say.
          </p>
        </div>
      </div>
    </div>
  );
};

export const Landing = () => {
  function focusQuestion(e) {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      document.getElementById('question').focus();
    }, 300);
  }

  return (
    <div className="landing-view">
      <section className="landing-section section-sample-questions bg-dark-green">
        <div className="inner">
          <div className="container">
            <div className="row">
              <div className="col col-sm col-left">
                <div className="copy">
                  <h2>
                    What kinds of questions <span className="br-sm" />
                    could I ask?
                  </h2>
                  <p>
                    Ask any people-related questions or business questions that
                    impact your people. No topics are off-limits, too simple or
                    too complex. We answer them all.
                  </p>
                  <p className="mb-2">
                    <em className="">Popular topics</em>
                  </p>
                  <ul className="tag-list dark d-flex flex-wrap">
                    <li>Terminations</li>
                    <li>Legal liability</li>
                    <li>Performance Management</li>
                    <li>Employee Engagement</li>
                    <li>Policy Wording</li>
                    <li>Compensation</li>
                    <li>Vacation</li>
                    <li>Bullying & Harassment</li>
                    <li>Leaves & Accommodation</li>
                  </ul>
                </div>
              </div>
              <div className="col col-sm col-right">
                <SampleDraft />
              </div>
            </div>
          </div>
        </div>
      </section>

      <HowItWorks />

      <section className="landing-section bg-dark-green section-overview">
        <div className="inner">
          <div className="container">
            <div className="row">
              <div className="col col-sm col-left">
                <div className="intro">
                  <h2>Everyone needs relevant answers quickly</h2>

                  <p>
                    AnswerBar provides on-demand access to HR knowledge through
                    an easy to use online application so you and your team can
                    make better decisions faster. Companies are exposed to both
                    the legal and financial risks of making poor decisions
                    caused by a lack of HR knowledge. Not everyone can afford or
                    has a need to hire full-time HR.
                  </p>
                  {/* <p>
                    AnswerBar provides on-demand access to HR knowledge through
                    an easy to use online application. Ask questions anytime and
                    eliminate the need to guess. We minimize your risk by
                    providing an affordable way for you to access{' '}
                    <em>
                      <strong>accurate</strong>
                    </em>{' '}
                    and{' '}
                    <em>
                      <strong>relevant</strong>
                    </em>{' '}
                    information so you and your team can make better decisions
                    faster.
                  </p> */}

                  <p className="large">
                    <span
                      className="font-serif underlined"
                      onClick={(e) => focusQuestion(e)}
                    >
                      Ask your first question
                    </span>
                  </p>
                </div>
              </div>
              <div className="col col-sm col-right">
                <div className="mx-auto" style={{ maxWidth: 228 }}>
                  <img
                    src="/images/iphone-answerbar.png"
                    alt="AnswerBar mobile app"
                    className="img-fluid"
                  />
                </div>
                {/* <SampleQuestion question={sampleQuestions[0]} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landing-section section-testimonials">
        <div className="inner">
          <div className="container">
            <Testimonials />
          </div>
        </div>
      </section>

      {/* 
      <section className="landing-section section-pricing">
        <div className="inner">
          <div className="container">
            <Plans />
          </div>
        </div>
      </section>
      */}

      <section className="landing-section section-get-started">
        <div className="inner">
          <div className="container">
            <div className="row">
              <div className="col col-sm col-left">
                <h2>
                  Get started for free <span className="br-sm" />
                  with AnswerBar
                </h2>
                {/* 
                <p>Inaccurate information is expensive. <br/>
                How much does it cost if you do nothing?</p>
                */}
                <p className="font-serif large">
                  Simple{' '}
                  <Link to="/pricing" className="underlined italic">
                    <em>pricing</em>
                  </Link>{' '}
                  that scales as your business grows. <br />
                </p>
              </div>
              <div className="col col-sm col-right">
                <div className="ask-card-wrapper">
                  <div className="ask-card d-flex flex-column">
                    <div className="top d-flex align-items-center justify-content-center">
                      <div>
                        Go ahead, <br />
                        <span
                          className="underlined"
                          onClick={(e) => focusQuestion(e)}
                        >
                          Ask your first free question
                        </span>
                      </div>
                    </div>
                    <div className="bottom d-flex align-items-center justify-content-center">
                      <p>
                        1 User <br />2 Free Questions
                      </p>
                    </div>
                  </div>
                  <div className="ask-cta" onClick={(e) => focusQuestion(e)}>
                    Ask
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
