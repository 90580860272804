import React, { Component } from 'react';
// import { ModalProvider, ModalConsumer } from './ModalContext'

export default class Modal extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();

    this.state = {
      initialPath: '/',
      routeChangeCount: 1, // keep track of the number of times the route changes - go back x times when user hits close
    };
  }

  componentDidMount() {
    const { doModal, history } = this.props;
    this._isMounted = true;
    this._countRouteChange = true;
    doModal(true);
    this.unlisten = history.listen(this.onRouteChange);

    // set a timeout so animation fires
    setTimeout(() => {
      if (this.modalRef.current) this.modalRef.current.classList.add('active');
    }, 100);
  }

  componentWillUnmount() {
    const { doModal } = this.props;
    this._isMounted = false;
    doModal(false);
    this.unlisten(); // unbinds history.listen
  }

  onRouteChange = (e) => {
    if (this._isMounted && this._countRouteChange) {
      let { routeChangeCount } = this.state;
      this.setState({
        routeChangeCount: (routeChangeCount += 1),
      });
    }
    // reset count rount change
    this._countRouteChange = true;
    return e;
  };

  closeModal = (e) => {
    const { history, closeUrl } = this.props;
    e.preventDefault();
    // const { routeChangeCount } = this.state;
    history.push(closeUrl || '/');
  };

  goBack = () => {
    const { history } = this.props;
    this._countRouteChange = false;
    let { routeChangeCount } = this.state;
    this.setState({
      routeChangeCount: (routeChangeCount -= 1),
    });
    history.goBack();
  };

  render() {
    const { closeText, printable, children } = this.props;
    const { routeChangeCount } = this.state;

    return (
      <div ref={this.modalRef} className="modal-wrapper">
        {/* 
        <ModalProvider>
          <ModalConsumer>
          {modalContext => (
      */}
        <div className="inner">
          <div className="modal-view">
            <div className="inner d-flex align-items-stretch">
              <div className="modal-header pt-safe d-flex justify-content-between">
                <div>
                  {routeChangeCount > 1 && (
                    <div className="modal-back-button" onClick={this.goBack}>
                      <div className="inner">
                        <i className="answerbar-angle-left" />
                        <div className="text-label">Back</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex">
                  {printable && (
                    <div
                      className="modal-print-button"
                      onClick={() => {
                        window.print();
                        return false;
                      }}
                    >
                      <div className="inner">
                        <i className="material-icons">print</i>
                        <div className="text-label">Print</div>
                      </div>
                    </div>
                  )}

                  <div className="modal-close-button" onClick={this.closeModal}>
                    <div className="inner">
                      {/* 
                          <i className="answerbar-close"></i>
                          */}
                      <div className="close-bars">
                        <div className="close-bar" />
                        <div className="close-bar" />
                      </div>
                      <div className="text-label">{closeText || 'Close'}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center flex-fill">
                <div className="modal-body">{children}</div>
              </div>
            </div>
          </div>
        </div>
        {/* 
          )}
          </ModalConsumer>
        </ModalProvider>
      */}
      </div>
    );
  }
}
