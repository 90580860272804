/* eslint-disable max-classes-per-file */
import React from 'react';
import { Link } from 'react-router-dom';
import { UserAvatar } from '../../User/Components/Avatar';
import { Api } from '../Utils/Api';
import { LoadingBar } from '../Partials';

const ShareWithFormItem = ({ person, toggleSelected }) => (
  <div
    className={`w-50 flex-fill share-form-item ${person.shared && 'selected'}`}
    onClick={() => toggleSelected(person.user.id)}
  >
    <div className="inner">
      <div className="d-flex align-items-center">
        <div className="p-2">
          <UserAvatar user={person.user} />
        </div>
        <div className="p-2 truncate">
          <div className="name truncate">{person.name}</div>
        </div>
        <div className="p-2 ml-auto">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`share-item-${person.user.id}`}
              onChange={() => {}}
              checked={person.shared}
            />
            <label
              className="custom-control-label"
              htmlFor={`share-item-${person.user.id}`}
            >
              &nbsp;
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class ShareWithForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      team: [],
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { question_id } = this.props;
    Api.Questions.shareList(question_id).then((team) => {
      if (this._isMounted) {
        this.setState({
          team: team || [],
          loading: false,
        });
      }
      return team;
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  clearAll = () => {
    const { team } = this.state;
    team.map((person, i) => {
      team[i].shared = 0;
      return team;
    });
    this.setState({ team });
  };

  selectAll = () => {
    const { team } = this.state;
    team.map((person, i) => {
      team[i].shared = 1;
      return team;
    });
    this.setState({ team });
  };

  toggleSelected = (user_id) => {
    const { team } = this.state;
    team.map((person, i) => {
      if (person.user.id === user_id) team[i].shared = person.shared ? 0 : 1;
      return team;
    });
    this.setState({ team });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { team } = this.state;
    const { question_id, history } = this.props;

    this.setState({ loading: true });

    const selectedUserIds = team
      .filter((person) => person.shared === 1)
      .map((person) => person.user.id);

    Api.Questions.share(question_id, selectedUserIds).then((response) => {
      if (this._isMounted) {
        this.setState({ loading: false });
        if (response.success) history.push('/questions/list');
      }
      return response;
    });
  };

  render() {
    const { team, loading } = this.state;

    if (!team.length) {
      return (
        <div className="text-center">
          <Link
            to="/organization/manage-users"
            className="link underlined"
            onClick={this.clearAll}
          >
            Invite your first team member
          </Link>
        </div>
      );
    }

    return (
      <form className="share-form" onSubmit={this.handleSubmit}>
        <div className="d-flex justify-content-end">
          <div className="p-2 mr-auto">
            <h3>
              Shared with ({team.filter((person) => person.shared === 1).length}
              )
            </h3>
          </div>
          <div className="p-2">
            <div className="link underlined" onClick={this.clearAll}>
              Clear All
            </div>
          </div>
          <div className="p-2">
            <div className="link underlined" onClick={this.selectAll}>
              Select All
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {team &&
            team.map((member) => (
              <ShareWithFormItem
                key={member.user.id}
                person={member}
                toggleSelected={this.toggleSelected}
              />
            ))}

          {loading && <LoadingBar type="status-bar" />}
        </div>
        <div className="form-actions">
          <div className="radial-shadow top front" />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Save Changes <i className="answerbar-angle-right" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default class Sharing extends React.Component {
  // team = [
  //   {
  //     id: 111,
  //     name: 'Adam Horiuchi',
  //     shared: true
  //   },
  //   {
  //     id: 222,
  //     name: 'Adam Wilson',
  //     shared: false
  //   },
  //   {
  //     id: 333,
  //     name: 'Aura McKay',
  //     shared: false
  //   },
  //   {
  //     id: 444,
  //     name: 'Bill Higginson',
  //     shared: true
  //   }
  // ]

  render() {
    const { match } = this.props;
    const { id } = match.params;
    return (
      <div className="sharing-view">
        <div className="text-center">
          <h1>Share Question</h1>
          <div className="subtitle">
            Share this question and answer with members in your team.
          </div>
        </div>

        <ShareWithForm
          {...this.props}
          question_id={id}
          selectItem={this.handleItemSelect}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}
