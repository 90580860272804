import React, { Component } from 'react';
import { LoadingBar } from '../../Components/Partials';

export class Logout extends Component {
  componentDidMount() {
    const { userContext, history } = this.props;
    userContext.logout().then(() => history.push('/'));
  }

  render() {
    return (
      <div>
        {/* 
        Logging out...
      */}
        <LoadingBar type="status-bar" />
      </div>
    );
  }
}

// export const SignoutLink = withRouter(({ history }) => (
//   <Link to="/" onClick={(e) => {
//     e.preventDefault()
//     auth.signout()
//       .then(() => {
//         history.push('/')
//       });
//   }}>Sign Out</Link>
// ))
