import { UserConsumer, UserProvider } from './Context';
import { AnonymousRoute } from './AnonymousRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicRoute } from './PublicRoute';
import { EditProfile } from './Views/EditProfile';
import { ForgotPassword } from './Views/ForgotPassword';
import { Login } from './Views/Login';
import { Logout } from './Views/Logout';
import { ResetPassword } from './Views/ResetPassword';
import { Signup } from './Views/Signup';

export {
  UserConsumer,
  UserProvider,
  AnonymousRoute,
  ProtectedRoute,
  PublicRoute,
};

// Views
export const User = {
  EditProfile,
  ForgotPassword,
  Login,
  Logout,
  ResetPassword,
  Signup,
};
