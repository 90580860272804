import React from 'react';
import { Link } from 'react-router-dom';
import { currency } from '../Utils/Number';
import Api from '../../Api';

export const Plan = ({ plan, index }) => (
  <Link
    to={`/subscribe/${plan.id}`}
    className={`plan ${index === 1 ? 'current-plan' : ''}`}
  >
    <div className="inner">
      <div className="header d-flex justify-content-between">
        <h3 className="title">{plan.title}</h3>
        <div className="price">
          {currency(plan.price)}
          <span className="small">/{plan.frequency === 'y' ? 'yr' : 'mo'}</span>
        </div>
      </div>
      <div className="content d-flex justify-content-between">
        <ul>
          <li>
            {plan.max_users === '1' && <span>1 User</span>}
            {plan.max_users !== '1' && (
              <span>Up to {plan.max_users} Users</span>
            )}
          </li>
        </ul>
        {/* 
        <div className="actions">
          Select plan
        </div>
        */}
      </div>
    </div>
  </Link>
);

class Plans extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.refreshPlans();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshPlans() {
    Api.plans().then(
      (response) =>
        !this._isMounted || this.setState({ plans: response }) || response
    );
  }

  render() {
    const { plans } = this.state;

    return (
      <div className="plans">
        <div className="mb-4">
          <h2>Pricing</h2>
          <p>Simple pricing and plans that scale as your business grows. </p>
          <p>
            All plans include{' '}
            <Link to="/pricing">
              <em className="underlined">unlimited</em>
            </Link>{' '}
            questions.
          </p>
        </div>
        <div className="plans-view">
          <div className="plans d-flex flex-wrap justify-content-center">
            {plans &&
              plans.map((plan, index) => (
                <Plan key={plan.id} index={index} plan={plan} />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Plans;
