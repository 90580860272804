import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

export default ({ id, children }) => {
  const parentElem = document.getElementById(id);
  const { current: elem } = useRef(document.createElement('div'));

  useEffect(() => {
    if (parentElem) parentElem.appendChild(elem);
    return () => {
      if (parentElem) parentElem.removeChild(elem);
    };
  }, [elem, parentElem]);

  return createPortal(children, elem);
};
