/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import store from 'store';
import { Capacitor } from '@capacitor/core';
import { Threads, Thread } from './Thread';
import { LoadingBar } from '../Partials';
import { UserAvatar, EmployeeAvatar } from '../../User/Components/Avatar';
import { Api } from '../Utils/Api';
import { formatDate } from '../Utils/Datetime';
import { Draft } from './Drafts';
import { Attachments } from './Attachments';
import { AppConsumer } from '../AppContext';

const { isNative } = Capacitor;

const ChildQuestion = ({ question, deleteAttachment }) => (
  <div className="child-question">
    <QuestionBody question={question} deleteAttachment={deleteAttachment} />

    <Answer answer={question.answer} />
  </div>
);

const ChildQuestions = ({ questions }) => (
  <div className="child-questions">
    {questions.map((question) => (
      <ChildQuestion key={question.id} question={question} />
    ))}
  </div>
);

const SharedWith = ({ id, sharedWith }) => (
  <div className="shared-with">
    <div className="text-label">Shared with {sharedWith.length}</div>
    <div className="photos d-flex justify-content-center">
      {sharedWith.map((user) => (
        <UserAvatar key={user.id} user={user} />
      ))}
    </div>
    <Link to={`/question/${id}/sharing`} className="underlined">
      Edit Access
    </Link>
  </div>
);

const ThreadActions = ({ askAnotherQuestion }) => (
  <div className="thread-actions">
    <div className="inner">
      <div className="w-md-66 ml-auto">
        <div className="radial-shadow front" />

        <div className="divider" />

        <div className="answer-cta">
          <div className="inner">
            <div className="cta" onClick={askAnotherQuestion}>
              Do you have another question on this topic? If so, ask{' '}
              <i className="answerbar-angle-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ThreadAwaitingAnswer = ({ user }) => {
  const url = isNative ? '/notifications' : '/account/edit-profile';
  return (
    <div className="awaiting-answer">
      {/* 
      <div className="radial-shadow front"></div>
      */}
      <div className="inner w-50 ml-auto">
        <EmployeeAvatar user={user} />
        <div className="message">Awaiting Answer...</div>
        <div className="notify-cta">
          Want to know when it&apos;s ready?{' '}
          <Link className="nowrap" to={url}>
            Edit your notification settings
          </Link>
        </div>
        {/* 
        <div className="emergency-cta">
          Is this an <Link to="">emergency</Link>?
        </div>
        */}
      </div>
    </div>
  );
};

const ThreadAnswer = ({ user, answer }) => (
  <div className="answer">
    <div className="inner w-66 ml-auto">
      <EmployeeAvatar user={user} />
      <div className="heading">Answered on {formatDate(answer.updated)}</div>
      <div className="body">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: answer.text }} />
      </div>
      {answer.documents.length > 0 && (
        <Attachments attachments={answer.documents} />
      )}
    </div>
  </div>
);

const ThreadDraft = (props) => {
  const { history, question } = props;
  return (
    <div className="draft-wrapper">
      <div className="accordian-bar">
        <div className="inner" />
      </div>
      <Draft
        {...props}
        history={history}
        key={question.id}
        draft_id={question.id}
      />
    </div>
  );
};

const ThreadRestore = ({ handleRestore }) => (
  <div className="restore-wrapper d-flex align-items-center justify-content-center">
    <div>
      <div className="answerbar-trash" />
      <span className="text">
        Moved to trash.
        <span className="link underlined" onClick={handleRestore}>
          Undo
        </span>
      </span>
    </div>
  </div>
);

const ThreadDetails = ({ question }) => (
  <div className="thread-details">
    <div className="radial-shadow front" />
    <div className="inner">
      <div
        className={`shared-answer-wrapper d-flex justify-content-between ${
          question.sharedWith.length ? 'is-shared' : 'not-shared'
        }`}
      >
        <div className="answer-wrapper flex-fill">
          <Answer answer={question.answer} />
        </div>
        {question.isMine && question.sharedWith.length > 0 && (
          <div className="shared-with-wrapper mt-auto">
            <SharedWith id={question.id} sharedWith={question.sharedWith} />
          </div>
        )}
      </div>
      {question.questions && <ChildQuestions questions={question.questions} />}
    </div>
  </div>
);

const QuestionNav = ({
  question,
  handleTrash,
  handleRestore,
  handleDelete,
  handleShare,
  toggleCollapse,
  collapsed,
}) => (
  <ul className="nav">
    {question.isMine && question.isTrashed === '0' && (
      <li className="expanded-link" onClick={handleShare}>
        <span>
          <i className="answerbar-share-box" />
        </span>
      </li>
    )}
    {question.isTrashed === '0' && (
      <li
        className={`expanded-link ${question.isAnswered ? '' : 'disabled'}`}
        onClick={handleTrash}
      >
        <span>
          <i className="answerbar-trash" />
        </span>
      </li>
    )}
    {question.isTrashed === '1' && (
      <li className="expanded-link" onClick={handleRestore}>
        <span>
          <i className="material-icons">restore</i>
        </span>
      </li>
    )}
    {question.isTrashed === '1' && (
      <li className="expanded-link" onClick={handleDelete}>
        <span>
          <i className="answerbar-trash" />
        </span>
      </li>
    )}
    <li onClick={toggleCollapse}>
      <span>
        {collapsed && <i className="answerbar-expand" />}
        <i className="expanded-link answerbar-collapse" />
      </span>
    </li>
  </ul>
);

const QuestionBody = ({ question }) => (
  <div className="question">
    <div className="inner">
      <div className="question-avatar">
        <UserAvatar user={question.user} />
      </div>
      <div className="main-heading heading">
        Asked by {question.user.profile.displayName}
        <span className="separator">,</span>{' '}
        <span className="date">{formatDate(question.submitted)}</span>
        {question.provinceName && (
          <span className="location"> – {question.provinceName}</span>
        )}
        <div className="heading-shadow radial-shadow front" />
      </div>
      <div className="summary-wrapper truncate">
        {(question.attachments.length > 0 ||
          (question.answer && question.answer.documents.length > 0)) && (
          <div className="attachment-flag">
            <i className="material-icons">attach_file</i>
          </div>
        )}
        {question.isUnread && <div className="unread-flag">New Answer!</div>}
        {question.hasDraft && <div className="draft-flag">Draft</div>}
        <div className="summary">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: question.plainText }} />
        </div>
      </div>
      <div className="question-body">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: question.text }} />
      </div>

      {question.attachments.length > 0 && (
        <Attachments
          attachments={question.attachments}
          // deleteAttachment={deleteAttachment}
        />
      )}
    </div>
  </div>
);

const Answer = ({ answer }) => (
  <>
    {answer && <ThreadAnswer answer={answer} />}
    {!answer && <ThreadAwaitingAnswer />}
  </>
);

export class QuestionConfirm extends Component {
  constructor(props) {
    super(props);

    if (!props.initialQuestion) props.history.push('/');

    this.state = {
      province: props.userContext.getProvince(),
      errors: {
        province: '',
      },
    };
  }

  handleChangeField = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    const {
      initialQuestion,
      setInitialQuestion,
      history,
      userContext,
    } = this.props;
    const { province } = this.state;
    e.preventDefault();
    Api.Questions.create({
      text: initialQuestion,
      province,
      submitted: true,
    })
      .then((response) => {
        // clear the initial question once submitted
        const { id, error } = response;

        if (id) {
          setInitialQuestion('');
          history.push('/questions/list');
          userContext.refresh();
        }

        if (error) {
          this.setState({ errors: error });
        }
      })
      .catch(() => {});
  };

  handleEdit = (e) => {
    const { initialQuestion, setInitialQuestion, history } = this.props;
    e.preventDefault();
    // create the question then redirect to drafts
    Api.Questions.create({ text: initialQuestion })
      .then(() => {
        // clear the initial question once submitted
        setInitialQuestion('');
        history.push('/questions/drafts');
      })
      .catch((error) => error);
  };

  render() {
    const { province, errors } = this.state;
    const { initialQuestion, userContext } = this.props;
    const hasError = (name) => errors[name];
    const errorMessage = (name) => errors[name];

    return (
      <div className="question-confirm">
        <div className="inner">
          <h1>Your Question</h1>
          <div className="initial-question">{initialQuestion}</div>

          <div className="divider" />

          <div className="row">
            <div className="col d-none d-md-flex" />
            <div className="col form-group flex-grow-2">
              <label htmlFor="province" className="control-label">
                Location
              </label>
              <select
                type="text"
                name="province"
                className={`form-control ${
                  hasError('province') ? 'is-invalid' : ''
                }`}
                value={province}
                onChange={this.handleChangeField}
                onBlur={this.handleValidateField}
                placeholder="Province/State"
              >
                <option value="">Select a location</option>
                {userContext.getProvinces().map((i) => (
                  <option key={i.key} value={i.key}>
                    {i.value}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">{errorMessage('province')}</div>
            </div>
            <div className="col d-none d-md-flex" />
          </div>

          <div>{errorMessage('subscription')}</div>

          <div className="form-actions">
            <div className="radial-shadow top front" />
            <div className="inner pt-4">
              <button
                onClick={this.handleSubmit}
                type="submit"
                className="btn btn-primary"
              >
                Submit Question <i className="answerbar-angle-right" />
              </button>
            </div>
          </div>

          <div className="edit-cta">
            <p>Not ready to submit this?</p>
            <p>
              <span className="underlined" onClick={this.handleEdit}>
                Edit your question
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class Question extends Component {
  constructor(props) {
    super(props);

    this.outerRef = React.createRef();

    this.state = {
      collapsed: !!props.question.isAnswered,
      trashed: false,
      removed: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      collapsed: this.isFullyAnswered(),
    });
  }

  componentDidUpdate() {
    // Set the container height (necessary for height animation)
    // The inner height might change, so update it when there are changes.
    // this.refs.outerRef.style.height = this.refs.innerRef.clientHeight+'px';
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleCollapse = (e) => {
    const { collapsed } = this.state;
    e.stopPropagation();
    e.preventDefault();
    if (collapsed) this.doExpand();
    else this.doCollapse();
  };

  doCollapse = () => {
    const { collapsed } = this.state;
    if (collapsed) return;
    this.setState({ collapsed: true });
  };

  doExpand = () => {
    const { collapsed } = this.state;
    const { question, markAsRead } = this.props;
    // already expanded
    if (!collapsed) return;

    // mark as read if it's unread
    if (question.isUnread) {
      markAsRead(question);
    }

    // const target = this.refs.outerRef.current;
    this.setState({ collapsed: false });
  };

  onHandleTrash = (e) => {
    const { question, appContext, isAnswered } = this.props;
    const { trashed } = this.state;
    e.stopPropagation();
    e.preventDefault();
    const target = this.outerRef.current;

    if (!question.isAnswered) {
      appContext.addError('Cannot delete a unanswered question.');
      return;
    }

    this.setState({
      trashed: true,
    });

    target.classList.add('is-trashed');
    const collapseTimer = null;

    Api.Questions.update(question.id, { is_trashed: 1 }).then((response) => {
      // console.log('response', response);
      const { answerBar } = this.props;
      answerBar.refreshTrash();

      if (response.success) {
        setTimeout(() => {
          if (this._isMounted) {
            // check to see if it's still trashed (it might have been undone)
            if (trashed) this.setState({ removed: true });
            // answerBar.refreshQuestions();
          }
        }, 5000);
      } else if (this._isMounted) {
        clearTimeout(collapseTimer);
        target.classList.remove('is-trashed');
        this.setState({
          removed: false,
          trashed: false,
          collapsed: !!isAnswered,
        });
        appContext.addError(response.message);
      }
      return response;
    });

    // in case the thread is expanded, collapse after the transition is complete
    // disabled this functionality
    // collapseTimer = setTimeout(() => {
    //   if (this._isMounted)
    //     this.setState({ collapsed: true })
    // }, 1000);

    // target.style.left = "-100%";
  };

  onHandleRestore = (e) => {
    const { question } = this.props;
    e.stopPropagation();
    e.preventDefault();
    const target = this.outerRef.current;
    target.classList.remove('is-trashed');
    // target.style.left = 0;

    this.setState({
      trashed: false,
      removed: false,
    });

    Api.Questions.update(question.id, { is_trashed: 0 }).then((response) => {
      const { answerBar } = this.props;
      answerBar.refreshTrash();
      answerBar.refreshQuestions();
      return response;
    });
  };

  onHandleDelete = (e) => {
    const { question } = this.props;
    const { trashed } = this.state;
    e.stopPropagation();
    e.preventDefault();

    // this.setState({
    //   trashed: true,
    // });

    Api.Questions.delete(question.id).then((response) => {
      // console.log('response', response);
      const { answerBar } = this.props;
      answerBar.refreshTrash();
      if (this._isMounted) {
        this.setState({ removed: trashed });
        // answerBar.refreshQuestions();
      }
      return response;
    });

    // in case the thread is expanded, collapse after the transition is complete
    setTimeout(() => {
      if (this._isMounted) this.setState({ collapsed: true });
    }, 1000);
  };

  onHandleShare = (e) => {
    const { history, question } = this.props;
    e.stopPropagation();
    e.preventDefault();
    history.push(`/question/${question.id}/sharing`);
  };

  handleAskAnotherQuestion = (e) => {
    const { question, appContext, answerBar, addDraft } = this.props;

    e.preventDefault();
    Api.Questions.create({ parent_question_id: question.id })
      // .then(response => console.log('create', response) || response)
      .then((response) => {
        if (response.error) {
          if (response.error.parent_question_id)
            appContext.addError(response.error.parent_question_id);
        }

        answerBar.refreshDrafts();

        if (response.id) {
          addDraft(response);
        }
        return response;
      });
  };

  isFullyAnswered = () => {
    const { question } = this.props;
    if (!question.isAnswered) return false;
    return (
      question.questions.filter((childQuestion) => !childQuestion.isAnswered)
        .length <= 0
    );
  };

  render() {
    const { removed, collapsed, trashed } = this.state;
    const {
      question,
      history,
      deleteAttachment,
      answerBar,
      removeDraft,
    } = this.props;
    const { isMine } = question;

    return (
      <Thread collapsed={collapsed} removed={removed}>
        <div
          ref={this.outerRef}
          className="thread-wrapper"
          onClick={this.doExpand}
        >
          <div ref={this.innerRef} className="inner">
            {!isMine && (
              <div className="not-mine">
                <span className="answerbar-share" />
              </div>
            )}
            <QuestionNav
              history={history}
              question={question}
              collapsed={collapsed}
              handleShare={this.onHandleShare}
              handleTrash={this.onHandleTrash}
              handleRestore={this.onHandleRestore}
              handleDelete={this.onHandleDelete}
              toggleCollapse={this.toggleCollapse}
            />
            <div className="nav-shadow radial-shadow front" />
            <QuestionBody
              question={question}
              deleteAttachment={deleteAttachment}
            />
            <ThreadDetails question={question} />

            {this.isFullyAnswered() && question.hasDraft !== true && (
              <ThreadActions
                askAnotherQuestion={this.handleAskAnotherQuestion}
                question={question}
              />
            )}
          </div>
        </div>
        {question.hasDraft && (
          <ThreadDraft
            history={history}
            answerBar={answerBar}
            handleDeletedDraft={removeDraft}
            question={question.draft}
          />
        )}
        {trashed && <ThreadRestore handleRestore={this.onHandleRestore} />}
      </Thread>
    );
  }
}

export default class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
    };
  }

  componentDidMount() {
    const { appContext, answerBar, history } = this.props;
    const { pushNotificationsPermission } = appContext;
    this._isMounted = true;
    answerBar.refreshQuestions().then((questions) => {
      // For Native iOS users, display push notifications opt-in
      // if they have unanswered questions
      const pushNotificationsPrompt = store.get('pushNotificationsPrompt');

      if (
        questions &&
        pushNotificationsPrompt !== 'noThanks' &&
        pushNotificationsPermission === 'prompt' &&
        questions.find((question) => question.isAnswered === false)
      ) {
        history.push('/notifications');
      }

      return questions;
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props) {
    return {
      questions: props.answerBar.filterWithSearchQuery(
        props.questions,
        props.searchQuery
      ),
    };
  }

  deleteAttachment = (attachment) => {
    Api.QuestionAttachment.delete(attachment.question_id, attachment.id).then(
      (response) => {
        if (this._isMounted)
          this.removeAttachment(attachment.question_id, attachment.id);
        return response;
      }
    );
  };

  removeAttachment = (question_id, attachment_id) => {
    this.setState((prevState) => {
      return {
        // Iterate over all questions, filter out the matching attachment
        questions: prevState.questions.map((question) => {
          if (question.id === question_id) {
            // eslint-disable-next-line no-param-reassign
            question.attachments = question.attachments.filter(
              (attachment) => attachment.id !== attachment_id
            );
          }
          return question;
        }),
      };
    });
  };

  addDraft = (draft) => {
    const { questions } = this.state;
    const { answerBar } = this.props;

    // Iterate over all questions, filter out the matching question
    questions.map((question) => {
      if (question.id === draft.parent_question_id) {
        // eslint-disable-next-line no-param-reassign
        question.draft = draft;
        // eslint-disable-next-line no-param-reassign
        question.hasDraft = true;
        answerBar.setQuestion(question);
      }
      return question;
    });
  };

  removeDraft = (draft) => {
    const { questions } = this.state;
    const { answerBar } = this.props;

    // Iterate over all questions, filter out the matching question
    questions.map((question) => {
      if (question.id === draft.parent_question_id) {
        // eslint-disable-next-line no-param-reassign
        question.draft = [];
        // eslint-disable-next-line no-param-reassign
        question.hasDraft = false;
        answerBar.setQuestion(question);
      }
      return question;
    });
  };

  markAsRead = (question) => {
    const { answerBar } = this.props;
    if (question.isUnread === false) return; // nothing to do

    // send read receipt to the server
    Api.Questions.flag(question.id, 'read').then((response) => {
      // refresh unread list to update count (unless you're currently viewing that list)
      // if (this.props.location.pathname !== '/questions/unread')
      //   this.props.answerBar.refreshUnread();

      return response;
    });

    // also remove the isUnread status from the question state
    // eslint-disable-next-line no-param-reassign
    question.isUnread = false;
    answerBar.setUnreadQuestion(question);
  };

  render() {
    const { answerBar, match } = this.props;
    const { questions } = this.state;

    // if (questions.length <= 0)
    //   this.props.history.push('/questions/drafts');

    return (
      <AppConsumer>
        {(appContext) => (
          <Threads>
            {questions.length > 0 && (
              <div className="threads-list">
                {questions &&
                  questions.map((question) => (
                    <Question
                      {...this.props}
                      key={question.id}
                      appContext={appContext}
                      question={question}
                      addDraft={this.addDraft}
                      removeDraft={this.removeDraft}
                      deleteAttachment={this.deleteAttachment}
                      markAsRead={this.markAsRead}
                    />
                  ))}
              </div>
            )}

            {questions.length <= 0 && match.path === '/questions/trash' && (
              <div className="empty-text">Trash is empty :)</div>
            )}

            {questions.length <= 0 && match.path === '/questions/list' && (
              <>
                {answerBar.state.searchQuery === '' && (
                  <div className="empty-text">
                    You have no questions. <br />{' '}
                    <Link
                      className="link underlined"
                      to="/drafts"
                      onClick={(e) => {
                        return e.preventDefault() || answerBar.createDraft();
                      }}
                    >
                      Ask your first question
                    </Link>
                  </div>
                )}
                {answerBar.state.searchQuery !== '' && (
                  <div className="empty-text">No questions found.</div>
                )}
              </>
            )}

            {answerBar.state.loading && (
              <div style={{ marginTop: 40, marginBottom: 40 }}>
                <LoadingBar type="status-bar" />
              </div>
            )}
          </Threads>
        )}
      </AppConsumer>
    );
  }
}
