const screens = {
  xs: '375px',
  sm: '540px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const matchMedia = (x) => {
  return window.matchMedia(`(min-width: ${screens[x]})`).matches;
};

export const isDesktop = matchMedia('md');
export const isMobile = !isDesktop;

export default matchMedia;
