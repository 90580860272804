import React from 'react';
import { Route } from 'react-router-dom';
import { UserConsumer } from './Context';

export const PublicRoute = ({ component: Component, ...rest }) => (
  <UserConsumer>
    {(context) => (
      <Route
        {...rest}
        render={(props) => (
          <Component {...rest} {...props} userContext={context} />
        )}
      />
    )}
  </UserConsumer>
);
