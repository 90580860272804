import React from 'react';
import { Route } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Api from '../Api';
import { Content } from './Views';
import { STRIPE_PK } from '../config';
import { LoadingBar } from './Partials';
import SubscribeForm from './SubscribeForm';

export default class Subscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: null,
      plan_id: props.match.params.plan_id,
      plan: null,
      updateCard: false,
    };
  }

  componentDidMount() {
    const { plan_id } = this.state;
    // load current subscription
    this.refreshSubscription();
    this.refreshPlan(plan_id);
  }

  componentWillUnmount() {}

  handleUpdateCard = () => {
    const { subscription } = this.state;
    if (subscription)
      this.setState({
        subscription: { ...subscription, hasCard: false },
      });
  };

  refreshSubscription() {
    Api.subscription().then(
      (response) =>
        this.setState({ subscription: response?.id ? response : null }) ||
        response
    );
  }

  refreshPlan(plan_id) {
    Api.plan(plan_id).then(
      (response) => this.setState({ plan: response }) || response
    );
  }

  render() {
    const { location } = this.props;
    const { plan, subscription } = this.state;
    const displayTerms =
      plan && location.pathname === `/subscribe/${plan.id}/terms`;

    return (
      <StripeProvider apiKey={STRIPE_PK}>
        <Elements>
          <div className={`terms-${displayTerms ? 'active' : 'inactive'}`}>
            <div className="signup-form-wrapper">
              {plan && (
                <div className="animated fadeIn">
                  {subscription && subscription.plan_id === plan.id && (
                    <h1>Current Plan - {plan.title}</h1>
                  )}
                  {subscription && subscription.plan_id !== plan.id && (
                    <h1>Change Plan - {plan.title}</h1>
                  )}
                  {!subscription && <h1>Purchase Plan</h1>}

                  <div className="divider" />

                  <SubscribeForm
                    {...this.props}
                    subscription={subscription}
                    plan={plan}
                    handleUpdateCard={this.handleUpdateCard}
                  />
                </div>
              )}

              {!plan && <LoadingBar type="status-bar" />}
            </div>
            <div className="terms-wrapper">
              <Route
                exact
                path={`/subscribe/${plan ? plan.id : ''}/terms`}
                render={() => <Content content_id="terms_service" />}
              />
            </div>
          </div>
        </Elements>
      </StripeProvider>
    );
  }
}
