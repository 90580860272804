import React, { Component } from 'react';
import { Redirect, Route, Link } from 'react-router-dom';
import validator from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';
import { parse } from 'querystringify';
import { LoadingBar } from '../../Components/Partials';
import { Content } from '../../Components/Views';

const SignupForm = ({
  handleSubmit,
  errorMessage,
  email,
  errors,
  userContext,
  validateEmail,
  handleChangeEmail,
  password,
  validatePassword,
  handleChangePassword,
  referralCode,
  validateReferralCode,
  handleChangeReferralCode,
  terms,
  handleChangeTerms,
  recaptchaRef,
  handleChangeRecaptcha,
  loading,
  canSubmit,
}) => (
  <div>
    <form className="signup-form" onSubmit={handleSubmit} noValidate>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

      <div className="form-group">
        <label htmlFor="email">Email address</label>
        <input
          value={email}
          onBlur={() => validateEmail()}
          onChange={(e) => handleChangeEmail(e.target.value)}
          type="email"
          id="email"
          placeholder="your@email.com"
          className={`form-control ${errors.email.length > 0 && 'is-invalid'}`}
          autoComplete="off"
          readOnly={userContext.state.inviteCode ? 'readonly' : false}
        />
        <div className="invalid-feedback inline-error">{errors.email[0]}</div>
      </div>

      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          value={password}
          onBlur={() => validatePassword()}
          onChange={handleChangePassword}
          type="password"
          id="password"
          className={`form-control ${
            errors.password.length > 0 && 'is-invalid'
          }`}
        />
        <div className="invalid-feedback inline-error">
          {errors.password[0]}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="referralCode">Referral Code (optional)</label>
        <input
          value={referralCode}
          onBlur={() => validateReferralCode()}
          onChange={(e) => handleChangeReferralCode(e.target.value)}
          type="referralCode"
          id="referralCode"
          placeholder=""
          className={`form-control ${
            errors.referralCode.length > 0 && 'is-invalid'
          }`}
          autoComplete="off"
        />
        <div className="invalid-feedback inline-error">
          {errors.referralCode[0]}
        </div>
      </div>

      <div className="form-actions d-flex">
        <div className="mr-3">
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                value="yes"
                defaultChecked={terms}
                onClick={handleChangeTerms}
                className={`custom-control-input ${
                  errors.terms.length > 0 && 'is-invalid'
                }`}
                id="terms"
              />
              <label className="custom-control-label" htmlFor="terms">
                I agree to the{' '}
                <Link
                  to="/signup/terms"
                  className="text-bold underlined"
                  onClick={(e) => e.stopPropagation()}
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  to="/signup/privacy"
                  className="text-bold underlined"
                  onClick={(e) => e.stopPropagation()}
                >
                  Privacy Policy
                </Link>
              </label>
              <div className="invalid-feedback">{errors.terms[0]}</div>
            </div>
          </div>

          <div className="form-group">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfSxX8UAAAAAGmJJX4C3djC3B8GeLBZZ6lwm_it"
              onChange={(e) => handleChangeRecaptcha(e.target.value)}
              theme="dark"
              size="normal"
              className={`form-control ${
                errors.captcha.length > 0 && 'is-invalid'
              }`}
              style={{ height: 'auto', border: '0 none' }}
            />
            <div className="invalid-feedback">{errors.captcha[0]}</div>
          </div>
        </div>
        {/* 
        <div className="">
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input type="checkbox" onChange={this.handleRobot} defaultChecked={false} className="custom-control-input" id="recaptcha" />
              <label className="custom-control-label" htmlFor="recaptcha">I am not a robot</label>
            </div>
          </div>
        </div>
        */}
        <div className="ml-auto">
          <button
            type="submit"
            className={`btn btn-primary ${loading ? 'disabled' : ''}${
              canSubmit ? '' : 'btn-hidden'
            }`}
          >
            <span>
              Sign Up <i className="answerbar-angle-right" />
            </span>
          </button>
        </div>
      </div>
    </form>

    {loading && <LoadingBar type="status-bar" />}

    <div className="form-cta" style={{ marginTop: 100 }}>
      <Link to="/login" className="underlined">
        Already have an account? Login
      </Link>
    </div>
  </div>
);

export class Signup extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    const { referral } = parse(props.location.search);

    this.state = {
      redirectToReferrer: false,
      errorMessage: '',
      // email address is stored in user context
      password: '',
      referralCode: referral || '',
      terms: false,
      loading: false,
      errors: {
        email: [],
        password: [],
        referralCode: [],
        terms: [],
        captcha: [],
      },
    };
  }

  componentDidMount() {
    const { userContext, history } = this.props;
    // if logged in, redirect to homepage
    if (userContext.isAuthenticated) {
      history.push('/');
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  // UserContext will update AFTER this component is loaded, so check
  // here whether the user is authenticated (happens when user refreshes browser)
  // const { from } = this.props.location.state || { from: { pathname: "/" } };
  // if (this.props.userContext.state.isAuthenticated)
  //   this.props.history.push(from);
  // }

  handleChangePassword = (e) => this.setState({ password: e.target.value });

  handleChangeTerms = (e) => {
    this.setState({ terms: e.target.checked }, () => {
      this.validateTerms();
    });
  };

  handleChangeReferralCode = (value) => this.setState({ referralCode: value });

  handleChangeRecaptcha = () => {
    // console.log('captcha value', e);
  };

  validate = () => {
    return (
      this.validateEmail() &&
      this.validatePassword() &&
      this.validateTerms() &&
      this.validateReferralCode()
    );
  };

  validateEmail = () => {
    const { userContext } = this.props;
    const { authEmail } = userContext.state;

    if (validator.isEmpty(authEmail)) {
      this.setFormError('email', 'Email address is required');
      return false;
    }

    if (!validator.isEmail(authEmail)) {
      this.setFormError('email', 'Please enter a valid email address');
      return false;
    }

    this.clearFormError('email');
    return true;
  };

  validatePassword = () => {
    const { password } = this.state;

    if (validator.isEmpty(password)) {
      this.setFormError('password', 'Password is required');
      return false;
    }

    if (!validator.isLength(password, { min: 8 })) {
      this.setFormError(
        'password',
        'Password must be a minimum of 8 characters'
      );
      return false;
    }

    this.clearFormError('password');
    return true;
  };

  validateReferralCode = () => {
    const { referralCode } = this.state;

    if (!validator.isLength(referralCode, { max: 64 })) {
      this.setFormError(
        'referralCode',
        'Referral code must be less than 64 characters'
      );
      return false;
    }

    this.clearFormError('password');
    return true;
  };

  validateTerms = () => {
    const { terms } = this.state;

    if (!terms) {
      this.setFormError('terms', 'Terms must be accepted before proceeding');
      return false;
    }

    this.clearFormError('terms');
    return true;
  };

  setFormError = (name, message) => {
    this.setState((prevState) => {
      const errors = { ...prevState.errors }; // same as Object.assign(), makes a copy
      errors[name] = message ? [message] : [];
      return { errors };
    });
  };

  clearFormError = (name) => {
    this.setFormError(name, '');
  };

  canSubmit = () => {
    const { userContext } = this.props;
    const { authEmail } = userContext.state;
    const { password, errors } = this.state;

    if (validator.isEmpty(authEmail) || validator.isEmpty(password))
      // || !(terms))
      return false;

    if (
      errors.email.length > 0 ||
      errors.password.length > 0 ||
      errors.referralCode.length > 0 ||
      errors.terms.length > 0
    )
      return false;

    return true;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { userContext, answerBar } = this.props;
    const { authEmail } = userContext.state;
    const { password, referralCode, terms, loading } = this.state;
    const valid = this.validate();
    const recaptchaValue = this.recaptchaRef.current.getValue();

    // display error messages
    if (!valid) return;

    if (loading) return;

    this.setState({ loading: true });

    userContext
      .register(authEmail, password, referralCode, terms, recaptchaValue)
      .then((response) => {
        const timeout = response.success ? 500 : 0;

        // confirmation alert has been disabled since Feb 11, 2019
        // if (response.success)
        //   this.props.appContext.addMessage('Please confirm your email to complete your registration.');

        // delay a few seconds (if successful) for welcomem animation
        setTimeout(() => {
          answerBar.refreshAll();
          this.setState({
            redirectToReferrer: !!response.success,
            loading: false,
            errors: {
              email: response.errors.email ? response.errors.email : [],
              password: response.errors.password
                ? response.errors.password
                : [],
              referralCode: response.errors.referralCode
                ? response.errors.referralCode
                : [],
              terms: response.errors.terms ? response.errors.terms : [],
              captcha: response.errors.captcha ? response.errors.captcha : [],
            },
          });
        }, timeout);
      });
  };

  render() {
    const { location, initialQuestion, userContext } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };
    const {
      redirectToReferrer,
      password,
      referralCode,
      terms,
      errorMessage,
      errors,
      loading,
    } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={initialQuestion ? 'question/confirm' : from} />;
    }

    const displayTerms = location.pathname === '/signup/terms';
    const displayPrivacy = location.pathname === '/signup/privacy';

    return (
      <div
        className={`terms-${
          displayTerms || displayPrivacy ? 'active' : 'inactive'
        }`}
      >
        <div className="signup-form-wrapper">
          <h2>Sign Up</h2>
          <SignupForm
            {...this.props}
            canSubmit={this.canSubmit()}
            handleSubmit={this.handleSubmit}
            handleChangeEmail={userContext.handleChangeAuthEmail}
            handleChangePassword={this.handleChangePassword}
            handleChangeReferralCode={this.handleChangeReferralCode}
            handleChangeTerms={this.handleChangeTerms}
            validateEmail={this.validateEmail}
            validatePassword={this.validatePassword}
            validateReferralCode={this.validateReferralCode}
            email={userContext.state.authEmail}
            password={password}
            referralCode={referralCode}
            terms={terms}
            errorMessage={errorMessage}
            errors={errors}
            recaptchaRef={this.recaptchaRef}
            loading={loading}
          />
        </div>

        <div className="terms-wrapper">
          <Route
            exact
            path="/signup/terms"
            render={() => <Content content_id="terms" />}
          />
          <Route
            exact
            path="/signup/privacy"
            render={() => <Content content_id="privacy" />}
          />
        </div>
      </div>
    );
  }
}
