import React from 'react';
import { Route } from 'react-router-dom';
import Header from './Header';
import Questions from './Questions';
import Drafts from './Drafts';
import { AppConsumer } from '../AppContext';

// class Notifications extends Component {
//   render() {
//     return (
//       <div>
//         Notifications
//       </div>
//     )
//   }
// }

// class Trash extends Component {
//   render() {
//     return (
//       <div className="thread draft">
//         <div className="inner">
//           [thread]
//         </div>
//       </div>
//     )
//   }
// }

const Threads = (props) => {
  const { answerBar } = props;

  return (
    <AppConsumer>
      {(appContext) => (
        <>
          <div className="thread-background" />
          <div className="threads-container">
            <div className="radial-shadow back bottom" />
            <div className="inner">
              <div className="container">
                <Header
                  {...props}
                  searchQuery={answerBar.state.searchQuery}
                  handleUpdateSearchQuery={answerBar.handleUpdateSearchQuery}
                  updateSearchQuery={answerBar.updateSearchQuery}
                  createDraft={answerBar.createDraft}
                  emptyTrash={answerBar.emptyTrash}
                />

                <div className="content-wrapper">
                  <div className="inner">
                    <Route
                      path="/questions/unread"
                      render={(routeProps) => (
                        <Questions
                          {...routeProps}
                          appContext={appContext}
                          answerBar={answerBar}
                          questions={answerBar.state.unread}
                        />
                      )}
                    />
                    <Route
                      path="/questions/list"
                      render={(routeProps) => (
                        <Questions
                          {...routeProps}
                          appContext={appContext}
                          answerBar={answerBar}
                          questions={answerBar.state.questions}
                          searchQuery={answerBar.state.searchQuery}
                        />
                      )}
                    />
                    {/* 
                    <Route exact path="/questions/list" component={Questions} searchQuery={this.state.searchQuery} />
                   */}
                    <Route
                      path="/questions/drafts"
                      render={(routeProps) => {
                        return (
                          <Drafts
                            {...routeProps}
                            answerBar={answerBar}
                            appContext={appContext}
                            questions={answerBar.state.drafts}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/questions/trash"
                      render={(routeProps) => {
                        return (
                          <Questions
                            {...routeProps}
                            appContext={appContext}
                            answerBar={answerBar}
                            questions={answerBar.state.trash}
                            searchQuery=""
                            scenario="trash"
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AppConsumer>
  );
};

export default Threads;
