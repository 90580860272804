import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, Router } from 'react-router-dom';
import './App.css';
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import { setupIonicReact } from '@ionic/react';
import { Alerts } from './Components/Alerts';
// import { Header } from './Components/Layouts/Header';
import AnswerBar from './Components/AnswerBar';
import { AppConsumer, AppProvider } from './Components/AppContext';
import { live } from './config';
import { UserConsumer, UserProvider } from './User';
import AppUrlOpener from './AppUrlOpener';
import { subdomain } from './Whitelabel';

setupIonicReact({
  mode: 'md',
});

const { StatusBar } = Plugins;
const { isNative } = Capacitor;

if (isNative) {
  StatusBar.setStyle({
    style: StatusBarStyle.Dark,
  });
}

const history = createBrowserHistory();

if (live) {
  // Google Analytics
  ReactGA.initialize('UA-138429629-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  // Track url changes
  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  });
}

const App = () => {
  useEffect(() => {
    if (subdomain === 'demo') {
      document.title = 'Bench HR';
      // Add Google Fonts (for demo only, similar to CircularStd)
      const links = [
        { href: 'https://fonts.googleapis.com', rel: 'preconnect' },
        {
          href: 'https://fonts.gstatic.com',
          rel: 'preconnect',
          crossorigin: true,
        },
        {
          href:
            'https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap',
          rel: 'stylesheet',
        },
      ];

      links.forEach((linkInfo) => {
        const link = document.createElement('link');
        Object.keys(linkInfo).forEach((key) => {
          link[key] = linkInfo[key];
        });
        document.head.appendChild(link);
      });
    }
  }, []);

  return (
    <UserProvider>
      <UserConsumer>
        {/* eslint-disable-next-line no-unused-vars */}
        {(userContext) => (
          <AppProvider subdomain={subdomain}>
            <AppConsumer>
              {(appContext) => (
                <Router history={history}>
                  <AppUrlOpener />
                  <div>
                    {/* 
                    <UserTest userContext={userContext} />
                  */}
                    <Route render={(props) => <AnswerBar {...props} />} />
                    <Alerts
                      errors={appContext.state.errors}
                      removeError={appContext.removeError}
                      messages={appContext.state.messages}
                      removeMessage={appContext.removeMessage}
                    />
                  </div>
                </Router>
              )}
            </AppConsumer>
          </AppProvider>
        )}
      </UserConsumer>
    </UserProvider>
  );
};

export default App;
