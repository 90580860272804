import React from 'react';
import { EditProfileForm } from './EditProfileForm';
import { EditPhotoForm } from './EditPhotoForm';

export const EditProfile = (props) => (
  <div>
    <div className="text-center">
      <h2>Edit your profile</h2>
    </div>

    <EditPhotoForm {...props} />
    <EditProfileForm {...props} />
  </div>
);
