import React from 'react';
import ReactQuill from 'react-quill';
// import PropTypes from 'prop-types';
import 'react-quill/dist/quill.core.css';
// import 'react-quill/dist/quill.snow.css';
import './Editor.css';

export const EditorToolbar = ({ index, children }) => (
  <div id={`toolbar-${index}`}>
    <EditorToolbarButtons />
    {children}
  </div>
);

/*
 * Custom toolbar buttons
 */
export const EditorToolbarButtons = () => (
  <>
    <button type="button" className="btn ql-bold">
      <span style={{ fontWeight: 600 }}>B</span>
    </button>
    <button type="button" className="btn ql-italic">
      <span style={{ fontStyle: 'italic' }}>I</span>
    </button>
    <button type="button" className="btn ql-underline">
      <span style={{ textDecoration: 'underline' }}>U</span>
    </button>
    <button type="button" className="btn ql-list" value="ordered">
      <i className="material-icons">format_list_numbered</i>
    </button>
    <button type="button" className="btn ql-list" value="bullet">
      <i className="material-icons">format_list_bulleted</i>
    </button>
    <button type="button" className="btn ql-indent" value="-1">
      <i className="material-icons">format_indent_decrease</i>
    </button>
    <button type="button" className="btn ql-indent" value="+1">
      <i className="material-icons">format_indent_increase</i>
    </button>
  </>
);

/*
 * Simple editor component that takes placeholder text as a prop
 */
export default class Editor extends React.Component {
  modules = {
    toolbar: {
      // eslint-disable-next-line react/destructuring-assignment
      container: `#toolbar-${this.props.index}`,
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  constructor(props) {
    super(props);
    // this.state = { editorHtml: '', theme: false }
    this.state = { theme: false };
  }

  render() {
    const { handleChange, editorHtml, placeholder, handleBlur } = this.props;
    // Clear all the existing icons...
    const icons = ReactQuill.Quill.import('ui/icons');
    icons.bold = null;
    icons.italic = null;
    icons.underline = null;
    icons.list.ordered = null;
    icons.list.bullet = null;
    icons.indent['+1'] = null;
    icons.indent['-1'] = null;

    return (
      <div>
        <ReactQuill
          onChange={handleChange}
          value={editorHtml}
          modules={this.modules}
          formats={Editor.formats}
          placeholder={placeholder}
          onBlur={handleBlur}
          spellcheck
        />
      </div>
    );
  }
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent'];

/*
 * PropType validation
 */
// Editor.propTypes = {
//   index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   placeholder: PropTypes.string,
//   editorHtml: PropTypes.string,
//   onChange: PropTypes.func,
// };
