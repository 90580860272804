import React from 'react';
import { Brand, LoadingBar } from './Partials';

export default () => (
  <>
    <div className="heading">
      <span className="message">Welcome to</span> <Brand />
    </div>
    <LoadingBar />
  </>
);
